<script setup>

import Modal from "@/Components/Modal/Modal.vue";
import Button from "@/Components/Inputs/Button.vue";
import {computed, reactive, ref, watch} from "vue";
import { useRouter } from 'vue-router'
import Table from "@/Components/Table/Table.vue";
import TheadTh from "@/Components/Table/TheadTh.vue";
import Tbody from "@/Components/Table/Tbody.vue";
import TbodyTr from "@/Components/Table/TbodyTr.vue";
import Thead from "@/Components/Table/Thead.vue";
import TbodyTd from "@/Components/Table/TbodyTd.vue";
import TheadTr from "@/Components/Table/TheadTr.vue";

const props = defineProps({
    show: Boolean,
    table: Object
});

const emit = defineEmits(['close']);

const show = ref(props.show);

const close = () => {
    show.value = false;
    emit('close');
}

watch(() => props.show, (val) => {
    show.value = props.show;
});

const importData = computed(() => {
    return props.table.getImportPriceListData();
});

</script>

<template>
    <Modal :isVisible="show" @update:isVisible="close" size="large">
        <template #header>
            Import ceníku ({{importData.length}} z {{table.sheet.data.length}})
        </template>
        <template #body>
            <div>
                <i>* Importují se pouze vyfiltrované položky viz. seznam dole</i>
                <Table class="tw-max-h-[650px] tw-overflow-auto">
                    <Thead>
                        <TheadTr>
                            <TheadTh>ID</TheadTh>
                            <TheadTh>Název produktu</TheadTh>
                            <TheadTh>RMT kód</TheadTh>
                            <TheadTh>Nová cena ({{ table.configurator.Settings.currency }})</TheadTh>
                            <TheadTh>Nový deposit ({{ table.configurator.Settings.currency }})</TheadTh>
                        </TheadTr>
                    </Thead>
                    <Tbody>
                        <TbodyTr v-for="(row, index) in importData" :key="index">
                            <TbodyTd>
                                {{ row.ID }}
                            </TbodyTd>
                            <TbodyTd>
                                {{ row.ProductName }}
                            </TbodyTd>
                            <TbodyTd>
                                {{ row.RmtCode }}
                            </TbodyTd>
                            <TbodyTd>
                                {{ row.NewPrice === null ? '-' : row.NewPrice / 100 }}
                                <span v-if="row.NewPrice !== null && row.CurrentPrice !== null &&  row.NewPrice < row.CurrentPrice" class="tw-text-green-600">(▼ {{ (((row.CurrentPrice - row.NewPrice) / row.CurrentPrice)*100).toFixed(2) }}%)</span>
                                <span v-if="row.NewPrice !== null && row.CurrentPrice !== null && row.NewPrice > row.CurrentPrice" class="tw-text-red-600">(▲ {{ (((row.NewPrice - row.CurrentPrice) / row.CurrentPrice)*100).toFixed(2) }}%)</span>
                                <span v-if="row.NewPrice !== null && row.CurrentPrice !== null && row.NewPrice === row.CurrentPrice" class="tw-text-gray-600">(►)</span>
                            </TbodyTd>
                            <TbodyTd>
                                {{ row.NewDeposit === null ? '-' : row.NewDeposit / 100 }}
                                <span v-if="row.NewDeposit !== null && row.CurrentPriceDeposit !== null &&  row.NewDeposit < row.CurrentPriceDeposit" class="tw-text-green-600">(▼ {{ (((row.CurrentPriceDeposit - row.NewDeposit) / row.CurrentPriceDeposit)*100).toFixed(2) }}%)</span>
                                <span v-if="row.NewDeposit !== null && row.CurrentPriceDeposit !== null && row.NewDeposit > row.CurrentPriceDeposit" class="tw-text-red-600">(▲ {{ (((row.NewDeposit - row.CurrentPriceDeposit) / row.CurrentPriceDeposit)*100).toFixed(2) }}%)</span>
                                <span v-if="row.NewDeposit !== null && row.CurrentPriceDeposit !== null && row.NewDeposit === row.CurrentPriceDeposit" class="tw-text-gray-600">(►)</span>
                            </TbodyTd>
                        </TbodyTr>
                    </Tbody>
                </Table>
            </div>
        </template>
        <template #footer>
            <Button variant="red" @click="close">Zavřít</Button>
            <Button  @click="() => {table.importPriceList(); close();}" variant="green" icon="send" icon-color="white" icon-direction="left" tooltip="Naimportuje/aktualizuje ceník podle dat z tabulky">Naimportovat nový ceník</Button>
        </template>
    </Modal>
</template>

<style scoped>

</style>