<script setup>
import { computed } from "vue";
import SearchIcon from './SearchIcon.vue';
import DownloadIcon from './DownloadIcon.vue';
import DateIcon from './DateIcon.vue';
import DeleteIcon from './DeleteIcon.vue';
import EditIcon from './EditIcon.vue';
import ExportIcon from './ExportIcon.vue';
import FileIcon from './FileIcon.vue';
import FilterIcon from './FilterIcon.vue';
import FolderIcon from './FolderIcon.vue';
import PlusIcon from './PlusIcon.vue';
import UserIcon from './UserIcon.vue';
import CloudIcon from './CloudIcon.vue';
import CloseIcon from './CloseIcon.vue';
import SettingIcon from './SettingIcon.vue';
import SendIcon from './SendIcon.vue';
import MinusIcon from './MinusIcon.vue';
import ListIcon from './ListIcon.vue';
import TableIcon from './TableIcon.vue';
import CopyIcon from './CopyIcon.vue';
import SuccessIcon from './SuccessIcon.vue';
import RowHeightIcon from './RowHeightIcon.vue';
import FxIcon from './FxIcon.vue';

const iconMap = {
    date: DateIcon,
    delete: DeleteIcon,
    edit: EditIcon,
    export: ExportIcon,
    file: FileIcon,
    filter: FilterIcon,
    folder: FolderIcon,
    plus: PlusIcon,
    user: UserIcon,
    search: SearchIcon,
    download: DownloadIcon,
    cloud: CloudIcon,
    close: CloseIcon,
    setting: SettingIcon,
    send: SendIcon,
    minus: MinusIcon,
    list: ListIcon,
    table: TableIcon,
    copy: CopyIcon,
    success: SuccessIcon,
    rowHeight: RowHeightIcon,
    fx: FxIcon,
    // Další ikony zde...
};

const props = defineProps({
    icon: {
        type: [String, Object],
        required: true,
    },
    width: {
        type: Number,
    },
    height: {
        type: Number,
    },
    color: {
        type: String,
    },
});

const iconComponent = computed(() => {
    if (typeof props.icon === 'string') {
        const iconKey = props.icon.toLowerCase().replace('icon', '').replace('-', '');
        const foundIcon = Object.keys(iconMap).find(key => key.toLowerCase() === iconKey);
        return foundIcon ? iconMap[foundIcon] : null;
    } else {
        return props.icon;
    }
});
</script>

<template>
    <component :is="iconComponent" :width="width" :height="height" :color="color" />
</template>

<style scoped>
</style>
