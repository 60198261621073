<script setup>
import NewSelect from "@/Components/Inputs/NewSelect.vue";
import Thead from "@/Components/Table/Thead.vue";
import TheadTh from "@/Components/Table/TheadTh.vue";
import TbodyTr from "@/Components/Table/TbodyTr.vue";
import Table from "@/Components/Table/Table.vue";
import Tbody from "@/Components/Table/Tbody.vue";
import TheadTr from "@/Components/Table/TheadTr.vue";
import Button from "@/Components/Inputs/Button.vue";
import TbodyTd from "@/Components/Table/TbodyTd.vue";
import {ref, watch} from "vue";
import InputField from "@/Components/Inputs/InputField.vue";
import TinyMCE from "@/Components/Inputs/TinyMCE.vue";
import TextArea from "@/Components/Inputs/TextArea.vue";
import Groups from "../Edit/Groups.vue";
import TranslationRow from "./TranslationRow.vue";

const props = defineProps({
    modelValue: Array,
})

const attachmentList = ref(props.modelValue);

</script>

<template>
    <div>
        <div class="attachments-translation-wrapper">
            <h2 class="mb-2 ml-3">Přílohy k překladům</h2>
            <Table class="mb-3">
                <Thead>
                <TheadTr>
                    <TheadTh>
                        Název souboru
                    </TheadTh>
                    <TheadTh>
                        Náhled
                    </TheadTh>
                </TheadTr>
                </Thead>
                <Tbody>
                <TbodyTr v-for="(attachment, attachmentIndex) in attachmentList" :key="attachmentIndex">
                    <TbodyTd>
                        {{ attachment.file.Name }}.{{ attachment.file.Extension }}
                    </TbodyTd>
                    <TbodyTd>
                        <div v-if="['jpg', 'jpeg', 'png', 'gif'].includes(attachment.file.Extension.toLowerCase())">
                        <a :href="'/files/translation-tool/' + attachment.file.Name + '.' + attachment.file.Extension" target="_blank"><img :src="'/files/translation-tool/' + attachment.file.Name + '.' + attachment.file.Extension" width="200"></a>
                        </div>
                        <div v-else>
                        <a :href="'/files/translation-tool/' + attachment.file.Name + '.' + attachment.file.Extension" target="_blank" class="d-flex align-items-center">
                            <img :src="'/project/img/file-extensions/' + attachment.file.Extension + '.svg'" :title="attachment.file.Extension" class="mr-3" width="45">
                            Zobrazit soubor
                        </a>
                        </div>
                    </TbodyTd>
                </TbodyTr>
                </Tbody>
            </Table>
        </div>
    </div>
</template>

<style scoped>

</style>