export class Cell{
    _column;
    _row;

    constructor(row, column) {
        this._column = column;
        this._row = row;
    }

    getData(){
        const data = this.row.dataGrid.getData(true)[this.row.position] ?? undefined;
        if(typeof data !== 'undefined' && data && data.hasOwnProperty(this.column.data)){
            return data[this.column.data] ?? null;
        }else{
            return undefined;
        }
    }

    render(){
        const data = this.getData();

        //TODO identifikovat undefined vs notLoaded data
        if(!this.isLoaded){
            return undefined;
        }


        let renderedData = '';

        if(this._column.format){
            renderedData = this._column.format.render(data);
        }else if(typeof this._column._render === 'function'){
            //TODO čtvrtý parametr expample {settings: {…}, row: 0, col: 0}
            renderedData = this._column._render(data, this.column.format, this._row.data(), {});
        }

        let renderedCell = '';

        renderedCell = '<div class="dataGridCell-dataWrapper">'+renderedData+'</div>';

        if(this.column.editable){
            renderedCell += '<div class="dataGridCell-editIcon"></div>';
        }

        return renderedCell;
    }

    updateData(data){
        if(typeof this.row.dataGrid.getData(true)[this.row.position] !== 'undefined'){
            this.row.dataGrid.getData(true)[this.row.position][this.column.data] = data;
        }
    }

    get column() {
        return this._column;
    }

    set column(value) {
        this._column = value;
    }

    get row() {
        return this._row;
    }

    set row(value) {
        this._row = value;
    }

    get isLoaded(){
        let isLoaded = this.row.isLoaded;

        if(isLoaded){
            return true;
        }

        if(this.column.className && this.column.className.includes('select-checkbox')){
            isLoaded = true;
        }

        if(this.column.data === 'Action'){
            isLoaded = true;
        }

        return isLoaded;
    }
}