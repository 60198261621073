<script setup>
import InputField from "@/Components/Inputs/InputField.vue";
import Button from "@/Components/Inputs/Button.vue";
import {ref} from "vue";
import {useAjax} from "@/js/Ajax/useAjax.js";
import {useAlertStore} from "@/Components/Alerts/stores/alertStore.js";

const props = defineProps({
    label: [Object, null],
});

const emit = defineEmits(['save']);

const ajax = useAjax();

//alerts
const alerts = useAlertStore();
//end alerts

const label = ref(props.label ?? {
    ID: null,
    Name: '',
});

const saveLabel = async () => {
    if(label.value.Name === ''){
        alerts.error('Název štítku nesmí být prázdný');
        return;
    }

    if(label.value.ID){
        const result = await updateLabel();
        if(result){
            emit('save');
        }else{

        }
    }else{
        const result = await createLabel();
        if(result){
            emit('save');
        }else{

        }
    }
}

const createLabel = async () => {
    const data= {
        data: JSON.stringify(label.value)
    };

    let hasError = false;

    await ajax.postForm(window.remoteUrl + '/admin/sheet-label/data-create', data, null, {
        waitingAlert: {
            title: 'Ukládám štítek',
        }
    }).then(({response, alert}) => {
        if(response.data.statuses && response.data.statuses.length){
            alerts.processStatuses(response.data.statuses);
            alert.changeToError('Chyba při ukládání štítku', '', response);
            hasError = true;
            return;
        }

        if(!response.data.items){
            alert.changeToError('Chyba při ukládání štítku', '', response);
            hasError = true;
            return;
        }

        const responseData = response.data.items;
        const id = parseInt(responseData[0]?.ID);

        if(!id){
            alert.changeToError('Chyba při ukládání štítku', '', response);
            hasError = true;
            return;
        }

        alert.changeToSuccess('Štítek úspěšně vytvořen', 'ID: ' + response.data.items[0]?.ID);
    }).catch(function ({error, alert}) {
        alert.changeToError('Chyba při ukládání štítku', error.message ? error.message : '', error);
        hasError = true;
    });

    return !hasError;
}

const updateLabel = async () => {
    const data= {
        data: JSON.stringify(label.value)
    };

    let hasError = false;

    await ajax.postForm(window.remoteUrl + '/admin/sheet-label/data-update', data, null, {
        waitingAlert: {
            title: 'Ukládám štítek',
        }
    }).then(({response, alert}) => {
        if(response.data.statuses && response.data.statuses.length){
            alerts.processStatuses(response.data.statuses);
            alert.changeToError('Chyba při ukládání štítku', '', response);
            hasError = true;
            return;
        }

        if(!response.data.items){
            alert.changeToError('Chyba při ukládání štítku', '', response);
            hasError = true;
            return;
        }

        alert.changeToSuccess('Štítek úspěšně vytvořen', 'ID: ' + label.value.ID);
    }).catch(function ({error, alert}) {
        alert.changeToError('Chyba při ukládání štítku', error.message ? error.message : '', error);
        hasError = true;
    });

    return !hasError;
}
</script>

<template>
    <InputField
        v-model="label.Name"
        label="Název"
        placeholder="Název štítku"
    ></InputField>
    <Button
        v-if="label.ID"
        variant="green"
        size="small"
        class="tw-h-[40px] !tw-mt-5"
        @click="saveLabel"
    >Upravit</Button>
    <Button
        v-else
        variant="green"
        size="small"
        class="tw-h-[40px] !tw-mt-5"
        @click="saveLabel"
    >Vytvořit</Button>
</template>

<style scoped>

</style>