export class PresetSetting {
    _toggle = {
        columns: {
            visible: true
        },
        order: false,
    };
    _saveState = {
        order: true,
        scroller: false,
        select: false,
        paging: false,
        search: false,
        columns: {
            search: false,
            visible: true,
        },
        searchBuilder: false,
        searchPanes: false,
    };

    _presetTriggerSave = null;

    constructor(settings){
        this.toggle = settings.toggle;
        this.saveState = settings.saveState;
        this.presetTriggerSave = settings.presetTriggerSave ?? null;
    }

    get toggle(){
        return this._toggle;
    }

    set toggle(value){
        this._toggle = value;
    }

    get saveState(){
        return this._saveState;
    }

    set saveState(value){
        this._saveState = value;
    }

    get presetTriggerSave(){
        return this._presetTriggerSave;
    }

    set presetTriggerSave(value){
        this._presetTriggerSave = value;
    }
}