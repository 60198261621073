<script setup>
  import Button from "@/Components/Inputs/Button.vue";
  import AlertManager from "@/Components/Alerts/AlertManager.vue";
  import {useAlertStore} from "@/Components/Alerts/stores/alertStore.js";
  import AlertHistoryBar from "@/Components/Alerts/HistoryBar/HistoryBar.vue";
  import {ref} from "vue";
  import {useLayoutStore} from "@project/Excel/ExcelApp/Components/Layout/useLayout.js";

  const props = defineProps({
    backText: {
        type: String,
        default: 'Zpět'
    }
  });

  const emit = defineEmits(['back']);

  const layoutStore = useLayoutStore();

  //alerts
  const alerts = useAlertStore(window.pinia);

  const historyEnabled = ref(false);

  const toggleHistory = () => {
      alerts.showHistoryBar(!alerts.isHistoryBarShown.value);
  }
  //end alerts


  const handleBack = () => {
      layoutStore.executeOnBack();
      emit('back');
  }
</script>

<template>
    <div class="layout">
        <div class="header">
            <div class="back"><Button @click="handleBack"><-</Button>
                {{ props.backText }}</div>
<!--            <div><img v-if="layoutStore.showLogo" :src="remanteLogo" height="36"></div>-->
            <div><img v-if="layoutStore.showLogo" src="/project/img/logo_remante.png" height="36"></div>
            <div>User</div>
        </div>
        <div class="content">
            <slot></slot>
        </div>
        <AlertManager class="alert-manager"></AlertManager>
        <div class="alert-history-btn-wrapper">
            <Button class="alert-history-btn" variant="green" outline="true" @click="toggleHistory()" icon="list" icon-color="green" tooltip="Historie alertů"></Button>
        </div>
    </div>
</template>

<style scoped>
    .layout{
        padding: 15px 40px;
        position: relative;
    }

    .header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #FFF;
        font-size: 14px;
        padding-bottom: 15px;

        .back{
            font-weight: 500;
            text-decoration: underline;
        }
    }

    :global(#alertGrid){
        height: calc(100vh - 70px);
    }

    .alert-history-btn-wrapper{
        position: fixed;
        right: 15px;
        bottom: 15px;

        .alert-history-btn{
            :deep(button){
                border-radius: 50% !important;
                padding: 15px 15px;
            }
        }
    }



</style>