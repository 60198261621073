<script setup>
import Layout from "./Components/Layout/Layout.vue";

const props = defineProps({
    remoteUrl: String,
    createSheetFromGridSheet: String,
});

window.remoteUrl = props.remoteUrl ?? '';
</script>

<template>
    <main class="text-inter">
        <Layout>
            <RouterView />
        </Layout>
    </main>
</template>

<style scoped>
    :global(.text-inter){
        font-family: Inter;
    }
</style>