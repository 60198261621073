<script setup>
import {IconProps, useIcon} from './useIcon.js'

const props = defineProps(IconProps(21,21));

const { color } = useIcon(props);
</script>

<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="props.width" :height="props.height" viewBox="0 0 21 21" fill="none" :style="{color: color}">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.625 1.75H5.25C3.31712 1.75 1.75 3.31712 1.75 5.25V15.75C1.75 17.6829 3.31712 19.25 5.25 19.25H15.75C17.6829 19.25 19.25 17.6829 19.25 15.75C19.25 13.7629 19.25 11.375 19.25 11.375C19.25 10.892 18.858 10.5 18.375 10.5C17.892 10.5 17.5 10.892 17.5 11.375V15.75C17.5 16.716 16.716 17.5 15.75 17.5C12.8363 17.5 8.16288 17.5 5.25 17.5C4.28312 17.5 3.5 16.716 3.5 15.75C3.5 12.8363 3.5 8.16288 3.5 5.25C3.5 4.28312 4.28312 3.5 5.25 3.5H9.625C10.108 3.5 10.5 3.108 10.5 2.625C10.5 2.142 10.108 1.75 9.625 1.75ZM16.2627 3.5H13.125C12.642 3.5 12.25 3.108 12.25 2.625C12.25 2.142 12.642 1.75 13.125 1.75H18.375C18.858 1.75 19.25 2.142 19.25 2.625V7.875C19.25 8.358 18.858 8.75 18.375 8.75C17.892 8.75 17.5 8.358 17.5 7.875V4.73725L11.1186 11.1186C10.7774 11.4599 10.2226 11.4599 9.88137 11.1186C9.53925 10.7774 9.53925 10.2226 9.88137 9.88137L16.2627 3.5Z" fill="currentColor"/>
    </svg>
</template>

<style scoped>

</style>