<script setup>

</script>

<template>
    <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
        <slot></slot>
    </tr>
</template>

<style scoped>

</style>