<script setup>

</script>

<template>
    <tfoot>
        <slot></slot>
    </tfoot>
</template>

<style scoped>

</style>