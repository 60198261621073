<script setup>
import {IconProps, useIcon} from './useIcon.js'

const props = defineProps(IconProps(15,15));

const { color } = useIcon(props);
</script>

<template>
    <svg  :width="props.width" :height="props.height"  viewBox="0 0 4 10" fill="none" xmlns="http://www.w3.org/2000/svg" :style="{color: color}">
        <path d="M1.90471 2.38095C2.56219 2.38095 3.09519 1.84796 3.09519 1.19048C3.09519 0.532994 2.56219 0 1.90471 0C1.24723 0 0.714233 0.532994 0.714233 1.19048C0.714233 1.84796 1.24723 2.38095 1.90471 2.38095Z" fill="white"/>
        <path d="M3.80952 9.28571V9.7619C3.80952 9.82505 3.78444 9.88561 3.73979 9.93026C3.69514 9.97491 3.63458 10 3.57143 10H0.238095C0.174948 10 0.114388 9.97491 0.0697363 9.93026C0.0250848 9.88561 0 9.82505 0 9.7619V9.28571C0.000824194 9.23 0.0209121 9.17629 0.0568503 9.13371C0.0927886 9.09113 0.14236 9.06231 0.197143 9.05214C0.410935 9.00731 0.602756 8.8902 0.740312 8.72051C0.877868 8.55082 0.952761 8.33891 0.952381 8.12047V5.23809C0.952381 5.04865 0.877126 4.86697 0.743172 4.73302C0.609217 4.59906 0.427536 4.52381 0.238095 4.52381C0.174948 4.52381 0.114388 4.49872 0.0697363 4.45407C0.0250848 4.40942 0 4.34886 0 4.28571V3.80952C0 3.74637 0.0250848 3.68581 0.0697363 3.64116C0.114388 3.59651 0.174948 3.57143 0.238095 3.57143H2.61905C2.68219 3.57143 2.74275 3.59651 2.78741 3.64116C2.83206 3.68581 2.85714 3.74637 2.85714 3.80952V8.12047C2.8571 8.33882 2.93208 8.55055 3.06952 8.7202C3.20697 8.88985 3.39854 9.00713 3.61214 9.05238C3.66693 9.06249 3.71653 9.09126 3.75251 9.13379C3.78849 9.17633 3.80863 9.23001 3.80952 9.28571Z" fill="white"/>
    </svg>
</template>

<style scoped>

</style>