import {computed} from "vue";

export function IconProps(defWidth = 16, defHeight = 16, loading = false){
    const props = {
        width: {
            type: Number,
            default: defWidth,
        },
        height: {
            type: Number,
            default: defHeight,
        },
        color: {
            type: String,
            default: 'white',
        },
        loading: {
            type: Boolean,
            default: loading,
        }
    };

    return props;
}

export function useIcon(props){
    const colors = {
        white: '#fff',
        black: '#000',
        blue: '#4294FF',
        gray: '#8898A7',
        green: '#24C586',
    }

    const color = computed(() => {
        if(!props.color) {
            return colors.white;
        }

        if(props.color.startsWith('#')){
            return props.color;
        }else{
            return colors[props.color] ?? colors.white;
        }
    });

    return {
        color,
    }
}