<script setup>
import { computed, useSlots, ref, watchEffect, nextTick, watch} from 'vue';
import Icon from '../../Icons/Icon.vue';
import Tooltip from "../Other/Tooltip.vue";

const props = defineProps({
    type: {
        type: String,
        default: 'button', // např. 'button', 'submit', ...
    },
    variant: {
        type: String,
        default: 'default', // např. 'default', 'alternative', 'dark', ...
    },
    outline: {
        type: Boolean,
        default: false,
    },
    size: {
        type: String,
        default: 'base', // např. 'extra-small', 'small', 'base', 'large', 'extra-large'
    },
    icon: {
        type: [String, Object],
    },
    iconColor: {
        type: String,
        default: 'white',
    },
    iconDirection: {
        type: String,
        default: 'right',
    },
    disabled: Boolean,
    hoverClass: {
        type: [String, Object],
    },
    tooltip: {
        type: String,
    },
});

const slots = useSlots();

const emit = defineEmits(['click']);

const buttonClass = computed(() => {
    let cl = `${props.variant}`;

    if(props.hoverClass && hoover.value){
        return `${props.hoverClass}`;
    }

    if(props.outline){
        return `${cl} ${props.variant}-outline outline`;
    }

    return `${cl}`;
});

const onClick = () => {
    if (!props.disabled) {
        emit('click');
    }
};

const hasSlotContent = ref(false);
const hoover = ref(false);

watch(
    () => slots.default && slots.default(),
    (newVal) => {
        hasSlotContent.value = newVal && newVal.length > 0;
    },
    { immediate: true, deep: true }
);
</script>

<template>
    <Tooltip v-if="props.tooltip" :text="props.tooltip">
        <button
            :class="{
            [buttonClass]: true,
            'with-icon': props.icon,
            ['icon-color-'+props.iconColor]: props.iconColor && !props.iconColor.startsWith('#'),
            'icon-left': props.iconDirection === 'left',
            'icon-right': props.iconDirection === 'right',
            'empty-slot': !hasSlotContent,
            'icon-only': !hasSlotContent && props.icon,
            ['size-' + props.size]: true,
        }"
            :disabled="disabled"
            @click="onClick"
            :type="type"
            @mouseenter="hoover = true"
            @mouseleave="hoover = false"
        >
            <div class="b-content-wrapper">
                <Icon v-if="props.icon && props.iconDirection === 'left'" :icon="props.icon" :width="!hasSlotContent ? 16 : 13" :height="!hasSlotContent ? 16 : 13" :style="{ marginRight: hasSlotContent ? '8px' : '0' }" :color="props.iconColor" class="icon"></Icon>
                <slot></slot> <!-- umožňuje vložení vlastního obsahu do tlačítka -->
                <Icon v-if="props.icon && props.iconDirection === 'right'" :icon="props.icon" :width="!hasSlotContent ? 16 : 13" :height="!hasSlotContent ? 16 : 13" :style="{ marginLeft: hasSlotContent ? '8px' : '0' }" :color="props.iconColor" class="icon"></Icon>
            </div>
        </button>
    </Tooltip>
    <button
        v-else
        :class="{
            [buttonClass]: true,
            'with-icon': props.icon,
            ['icon-color-'+props.iconColor]: props.iconColor && !props.iconColor.startsWith('#'),
            'icon-left': props.iconDirection === 'left',
            'icon-right': props.iconDirection === 'right',
            'empty-slot': !hasSlotContent,
            'icon-only': !hasSlotContent && props.icon,
            ['size-' + props.size]: true,
        }"
        :disabled="disabled"
        @click="onClick"
        :type="type"
        @mouseenter="hoover = true"
        @mouseleave="hoover = false"
    >
        <div class="b-content-wrapper">
            <Icon v-if="props.icon && props.iconDirection === 'left'" :icon="props.icon" :width="!hasSlotContent ? 16 : 13" :height="!hasSlotContent ? 16 : 13" :style="{ marginRight: hasSlotContent ? '8px' : '0' }" :color="props.iconColor" class="icon"></Icon>
            <slot></slot> <!-- umožňuje vložení vlastního obsahu do tlačítka -->
            <Icon v-if="props.icon && props.iconDirection === 'right'" :icon="props.icon" :width="!hasSlotContent ? 16 : 13" :height="!hasSlotContent ? 16 : 13" :style="{ marginLeft: hasSlotContent ? '8px' : '0' }" :color="props.iconColor" class="icon"></Icon>
        </div>
    </button>
</template>

<style scoped>
    button{
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        font-family: Inter;

        padding: 12px 20px;
        justify-content: center;
        align-items: center;

        border-radius: 21px;
        border: none;
        color: #FFFFFF;

        margin: 0px 5px;
        min-height: 43px;

        &.size-small{
            min-height: 33px;
            padding: 9px 15px;
            margin: 0px 4px;
        }

        &:hover{
            cursor: pointer;
        }

        &.icon-color-white{
            .icon{
                color: white !important;
            }
        }

        &.icon-color-black{
            .icon{
                color: #091219 !important;
            }
        }

        &.with-icon{
            &:not(.empty-slot){
                &.icon-left{
                    padding-left: 14px;
                }

                &.icon-right{
                    padding-right: 14px;
                }
            }

            &.icon-only{
                padding: 8px;
                border-radius: 4px;
            }
        }

        &.green {
            background: #24C586;

            &:hover{
                background: #017749;
            }

            &.outline{
                border: 1px solid #24C586;

                &:hover{
                    background: #24C586;
                }
            }
        }

        &.orange {
            background: #fdb615;

            &:hover{
                background: #dca115;
            }

            &.outline{
                border: 1px solid #fdb615;

                &:hover{
                    background: #fdb615;
                }
            }
        }

        &.default {
            background: #ECF2FF;
            color: #091219;

            .icon{
                color: #091219 !important;
            }

            &:hover{
                background: #c5cad6;
            }

            &.outline{
                border: 1px solid #ECF2FF;

                &:hover{
                    background: #ECF2FF;
                }
            }
        }

        &.red {
            background: #F00;

            &:hover{
                background: #bf0202;
            }

            &.outline{
                border: 1px solid #F00;

                &:hover{
                    background: #F00;
                }
            }
        }

        &.blue {
            background: #4294FF;

            &:hover{
                background: #376CFB;
            }

            &.outline{
                border: 1px solid #4294FF;

                &:hover{
                    background: #4294FF;
                }
            }
        }

        &.white {
            background: #FFF;
            color: #091219;

            &.outline{
                border: 1px solid #CDD8ED;
            }

            &:hover{
                background: #CDD8ED;
            }
        }

        &.gray {
            background: #506069;

            &.outline{
                border: 1px solid #506069;
            }

            &:hover{
                background: #2e373c;
            }
        }

        .b-content-wrapper{
            display: flex;
            align-items: center;
        }

        &.outline{
            background: #FFF;
            color: #091219;

            &:hover{
                color: #FFF;

                &.white{
                    color: #091219;
                }
            }
        }
    }
</style>
