<script setup>
import LabelsGrid from "@project/Excel/ExcelApp/Grids/LabelsGrid.vue";
import {ref} from "vue";
import LabelEditorDialog from "@project/Excel/ExcelApp/Components/LabelEditor/LabelEditorDialog.vue";
import Button from "@/Components/Inputs/Button.vue";
import {useAjax} from "@/js/Ajax/useAjax.js";

const showLabelEditorDialog = ref(false);
const selectedLabel = ref(null);

const ajax = useAjax();

const editLabel = (item) => {
  if(item){
    selectedLabel.value = {
      ID: item.ID,
      Name: item.Name,
    };
    showLabelEditorDialog.value = true;
  }
}

const deleteLabel = async (item) => {
  if(item){
    if(confirm('Opravdu chcete smazat štítek?')){
      const data= {
        data: JSON.stringify({
          ID: item.ID,
        })
      };

      let hasError = false;

      await ajax.postForm(window.remoteUrl + '/admin/sheet-label/data-delete', data, null, {
        waitingAlert: {
          title: 'Mazání štítku',
        }
      }).then(({response, alert}) => {
        if(response.data.error && response.data.error === true){
          alert.changeToError('Chyba při smazání štítku', response.data.description ? response.data.description : '');
          hasError = true;
          return;
        }

        alert.changeToSuccess('Štítek úspěšně smazán', 'ID: ' + item.ID);
      }).catch(function ({error, alert}) {
        alert.changeToError('Chyba při smazání štítku', error.message ? error.message : '', error);
        hasError = true;
      });

      if(!hasError){
        labels_datagrid.refresh();
      }
    }
  }
}

const createLabel = () => {
  selectedLabel.value = null;
  showLabelEditorDialog.value = true;
}

const save = () => {
  labels_datagrid.refresh();
}
</script>

<template>
  <div>
    <div class="title-bar">
      <div><h2>Štítky</h2></div>
      <div>
        <Button @click="createLabel" variant="green" icon="plus">Nový štítek</Button>
      </div>
    </div>
    <LabelsGrid
      @editLabel="editLabel"
      @deleteLabel="deleteLabel"
    ></LabelsGrid>
    <LabelEditorDialog
        :label="selectedLabel"
        v-if="showLabelEditorDialog"
        :show="showLabelEditorDialog"
        @close="showLabelEditorDialog = false; selectedLabel = null"
        @save="save"
    >
    </LabelEditorDialog>
  </div>
</template>

<style scoped>
.title-bar{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
</style>