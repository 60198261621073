<script setup>
import {computed, reactive, ref} from "vue";

    const props = defineProps({
        scroller: {
            type: Object,
            required: true
        }
    });

    const minMax = computed(() => {
        if(!props.scroller.viewportItems.length){
            return {
                min: 0,
                max: 0
            }
        }

        return {
            min: Math.min(...props.scroller.viewportItems) + 1,
            max: Math.max(...props.scroller.viewportItems) + 1
        }
    });

    const itemsLength = computed(() => {
        return props.scroller.virtualListRef?.items?.length?.toLocaleString('cs-CZ') ?? 0;
    });
</script>

<template>
    <div v-if="scroller.virtualListRef" class="pagination-info">
        Zobrazuji {{minMax.min}} až {{minMax.max}} z celkem {{ itemsLength }} záznamů
    </div>
</template>

<style scoped>
.pagination-info{
    padding-top: 5px;
    padding-left: 4px;
    white-space: nowrap;
}
</style>