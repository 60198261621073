<script setup lang="ts">
import {onMounted, reactive, ref, watch} from "vue";
    import Radio from "@/Components/Inputs/Radio.vue";
    import NewSelect from "@/Components/Inputs/NewSelect.vue";
    import Button from "@/Components/Inputs/Button.vue";
    import {Sheet} from "@project/Excel/Handsontable/js/Sheet";
    import type {IModelDefinitionColumn} from "@project/Excel/Handsontable/js/ModelDefinitionColumn";
    import {useModelDefinitionLoader} from "@project/Excel/ExcelApp/Hooks/useModelDefinitionLoader";
    import SheetEditor from "@project/Excel/ExcelApp/Components/SheetEditor/SheetEditor.vue";
    import {SheetDefinitionColumn} from "@project/Excel/Handsontable/js/SheetDefinitionColumn";
    import {type IModelDefinitionJoin, ModelDefinitionJoin} from "@project/Excel/Handsontable/js/ModelDefinitionJoin";
    import {type IModelDefinition, ModelDefinition} from "@project/Excel/Handsontable/js/ModelDefinition";
import {Filter, type FilterState} from "@/Filter/js/Filter";
    import {FilterItem} from "@/Filter/js/FilterItem";
    import Thead from "@/Components/Table/Thead.vue";
    import Tbody from "@/Components/Table/Tbody.vue";
    import TbodyTr from "@/Components/Table/TbodyTr.vue";
    import TbodyTd from "@/Components/Table/TbodyTd.vue";
    import Table from "@/Components/Table/Table.vue";
    import TheadTh from "@/Components/Table/TheadTh.vue";
    import TheadTr from "@/Components/Table/TheadTr.vue";

    import CompetitorDialog from "@project/Excel/PriceList/Components/CompetitorDialog.vue";
    import GroupPriceListDialog from "@project/Excel/PriceList/Components/GroupPriceListDialog.vue";
    import EshopPriceListDialog from "@project/Excel/PriceList/Components/EshopPriceListDialog.vue";
import {SheetDefinition} from "@project/Excel/Handsontable/js/SheetDefinition";
import {cloneDeep} from "lodash";
import TreeView from "@/Components/Other/TreeView/TreeView.vue";
import CdbProductSalesStatsDialog from "@project/Excel/PriceList/Components/CdbProductSalesStatsDialog.vue";
import HeliosProductSalesDialog from "@project/Excel/PriceList/Components/HeliosProductSalesDialog.vue";
import axios from "axios";
import {CurrencyType} from "@project/Excel/Handsontable/js/ColumnTypes/Currency/CurrencyType";
import {PriceListTable} from "@project/Excel/Handsontable/js/PriceList/PriceListTable";
import {useAlertStore} from "@/Components/Alerts/stores/alertStore.js";
import {SheetSettings} from "@project/Excel/Handsontable/js/SheetSettings";
import { useRouter } from 'vue-router';
import {SheetManager} from "@project/Excel/Handsontable/js/SheetManager";
import {useSheetManager} from "@project/Excel/ExcelApp/Hooks/useSheetManager";
import {useLayoutStore} from "@project/Excel/ExcelApp/Components/Layout/useLayout";

    const props = withDefaults(defineProps<{
        onCreateSheet?: any
        id: any,
        showDefinitionEditor?: boolean,
        onBackFromDefinitionEditor?: any,
    }>(), {
        sheet: null,
        showDefinitionEditor: false,
        id: null,
    });

    const emit = defineEmits(['back']);

    const alerts = useAlertStore(window.pinia);
    const router = useRouter();

    const getMetaCurrency = () => {
        if(state.Settings.currency === 'EUR'){
            return CurrencyType.CURRENCY_EUR;
        }else{
            return CurrencyType.CURRENCY_CZK;
        }
    };

    const columns: {[key: string]: any} = {
        Admin_Manager_Product: [
            {
                data: 'ID',
                alias: '_Product_ID',
                width: 65,
            },
            {
                data: 'Code',
                alias: '_RmtCode',
                width: 170,
            },
            {
                data: 'Name',
                alias: '_ProductName',
                width: 410,
            },
            {
                data: 'ProductTypeName',
                width: 210,
            },
            {
                data: 'Availability7',
                width: 125,
            },
            {
                data: 'Availability14',
                width: 125,
            },
            {
                data: 'Ranking',
                type: 'numeric',
                width: 100,
            },
            {
                data: 'VehicleTypeMode',
                width: 160,
            },
            {
                data: 'LifeCycle',
                width: 100,
            },
            {
                data: 'LifeCycleCategory',
                width: 175,
            },
            {
                data: 'InProduction',
                width: 125,
            },
            {
                data: 'APurchasePriceEUR',
                type: 'currency',
                columnMeta: {
                    currency: CurrencyType.CURRENCY_EUR,
                },
                width: 185,
            },
            {
                data: 'PriceVNCEUR',
                alias: '_PriceVNCEUR',
                type: 'currency',
                columnMeta: {
                    currency: CurrencyType.CURRENCY_EUR,
                },
                width: 130,
            }
        ],
        Admin_Manager_GroupSpecialPrice: [
            {
                data: 'Price',
                alias: '_CurrentPrice',
                type: 'currency',
                columnMeta: {
                    currency: getMetaCurrency,
                },
                width: 155,
            },
            {
                data: 'PriceDeposit',
                alias: '_CurrentPriceDeposit',
                type: 'currency',
                columnMeta: {
                    currency: getMetaCurrency,
                },
                width: 155,
            }
        ],
        Admin_Manager_CompetitorLog: [
            {
                data: 'CompetitorName',
                width: 160,
            },
            {
                data: 'Price',
                type: 'currency',
                columnMeta: {
                    currency: CurrencyType.CURRENCY_EUR, //NEMA label
                },
                width: 155,
            }
        ],
        Admin_Manager_ShopInfo: [
            {
                data: 'PriceVirSale',
                alias: '_CurrentPrice',
                type: 'currency',
                columnMeta: {
                    currency: getMetaCurrency,
                },
                width: 155,
            },
            {
                data: 'PriceDeposit',
                alias: '_CurrentPriceDeposit',
                type: 'currency',
                columnMeta: {
                    currency: getMetaCurrency,
                },
                width: 155,
            }
        ],
        Admin_Manager_Datapool: [
            {
                data: 'SalesLager',
                width: 90,
            },
            {
                data: 'SalesB2C',
                width: 90,
            },
            {
                data: 'SalesB2B',
                width: 90,
            },
        ],
        Admin_Manager_ProductSalesStats: [
            {
                data: 'OrderCount',
                width: 90,
            },
        ],
        Admin_Manager_ProductSales: [
            {
                data: 'Quantity',
                width: 90,
            },
        ],
        custom: [
            {
                title: 'Aktuální marže',
                alias: 'CurrentMargin',
                type: 'percentage',
                defaultValue: '=IF(column(_CurrentPrice), ((column(_CurrentPrice)-column(_PriceVNCEUR))/column(_CurrentPrice)), "-")',
                formating: '=IF(column(CurrentMargin), IF(column(CurrentMargin) < 0.15, "#FF7070", IF(AND(column(CurrentMargin) >= 0.15 ,column(CurrentMargin) < 0.30), "#ffd285", "#70FF84")))',
                width: 140,
            },
            {
                title: 'Nová marže',
                alias: 'NewMargin',
                type: 'percentage',
                defaultValue: '=IF(column(NewPrice), ((column(NewPrice)-column(_PriceVNCEUR))/column(NewPrice)), "-")',
                formating: '=IF(column(NewMargin), IF(column(NewMargin) < 0.15, "#FF7070", IF(AND(column(NewMargin) >= 0.15 ,column(NewMargin) < 0.30), "#ffd285", "#70FF84")))',
                width: 140,
            },
            {
                title: 'Nová cena',
                alias: 'NewPrice',
                defaultValue: '',
                formating: '=IF((column(NewPrice) - column(_CurrentPrice)) > 0, "#FF7070", IF(column(NewPrice) = "", "#dbdbdb", IF(column(NewPrice) = column(_CurrentPrice), "#ffd285" ,"#70FF84")))',
                type: 'currency',
                columnMeta: {
                    currency: getMetaCurrency,
                },
                width: 130,
            },
            {
                title: 'Nový deposit',
                alias: 'NewDeposit',
                defaultValue: '',
                formating: '=IF((column(NewDeposit) - column(_CurrentPriceDeposit)) > 0, "#FF7070", IF(column(NewDeposit) = "", "#dbdbdb", IF(column(NewDeposit) = column(_CurrentPriceDeposit), "#ffd285" ,"#70FF84")))',
                type: 'currency',
                columnMeta: {
                    currency: getMetaCurrency,
                },
                width: 130,
            },
            {
                title: 'Importovat',
                alias: 'EnableForImport',
                type: 'checkbox',
                defaultValue: '',
                width: 110,
                className: 'htCenter htMiddle',
            },
        ],
    };

    const sheet: Sheet = reactive({data: props.id ? null : new Sheet({type: 'price-list'})});


    //url
    const url = window.remoteUrl;
    //url

    const saveToServer = async (state: any) => {
        if(!state.ID){
            const data = {
                data: JSON.stringify({
                    Settings: JSON.stringify(state.Settings),
                    Sheet_ID: state.Sheet_ID
                })
            };

            const response = await axios.post(url + '/admin/price-list-configurator/data-create', data, {
                method: "POST",
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })

            const responseData = response.data.items;

            state.ID = parseInt(responseData[0].ID);
        }else{
            const data = {
                data: JSON.stringify({
                    ID: state.ID,
                    Settings: JSON.stringify(state.Settings),
                    Sheet_ID: state.Sheet_ID
                })
            };

            const response = await axios.post(url + '/admin/price-list-configurator/data-update', data, {
                method: "POST",
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
        }
    }

    const loadFromServer = async (sheet_id: any) => {
        return await PriceListTable.loadPriceListConfigurator(sheet_id);
    };

    const saveToLocal = (id: any, state: any) => {
        let priceLists = loadLocal();

        priceLists[id] = state;

        localStorage.setItem("price-lists", JSON.stringify(priceLists));
    }

    const loadLocal = (): {[key: string]: any} => {
        const priceLists = localStorage.getItem("price-lists");
        const parsedPriceLists = priceLists ? JSON.parse(priceLists) : {};

        return parsedPriceLists;
    }

    const loadLocalByID = (id: any) => {
        if(!id) return null;

        let priceLists = loadLocal();

        return priceLists[id] ?? null;
    }

    const state = reactive<{[key: string]: any}>({
        ID: null,
        User_ID: null,
        Sheet_ID: null,
        DateTime: null,
        Settings: {
            type: '',
            groupPrice: '',
            dataSource: '',
            groupPriceLists: [
            ],
            eshopPriceLists: [
            ],
            competitors: [
            ],
            cdbProductSalesStats: [
            ],
            heliosProductSales: [
            ],
            productTypeFilter: [],
            productTypeFilterJoinType: ModelDefinitionJoin.JOIN_TYPE_LEFT,
            eshop: '',
            currency: '',
        }
    });

    const joinTypeOptions = [
        {text: 'Left', value: ModelDefinitionJoin.JOIN_TYPE_LEFT},
        {text: 'Inner', value: ModelDefinitionJoin.JOIN_TYPE_INNER},
    ]

    const initializeState = async (sheetId: any) => {
        try {
            const data = await loadFromServer(sheetId);
            if (data) {
                Object.assign(state, data); // Update state with fetched data
            }
        }catch (err){

        }
    };

    onMounted(() => {
        if(props.id){
            initializeState(props.id);
        }else{
            state.Settings.type = 'group';
        }
    });

    const showEditor = ref(props.showDefinitionEditor ?? false);

    const {loadModelDefinition} = useModelDefinitionLoader();
    const createConfiguration = async () => {
        if(!state.Settings.type || !state.Settings.currency || !state.Settings.dataSource){
            alerts.warning('Vyberte prosím typ, měnu a zdroj dat');
            return;
        }

        if(state.Settings.type === 'group' && !state.Settings.groupPrice){
            alerts.warning('Vyberte prosím skupinový ceník');
            return;
        }

        if(state.Settings.type === 'eshop' && !state.Settings.eshop){
            alerts.warning('Vyberte prosím eshop ceník');
            return;
        }

        if(!sheet.data.settings){
            sheet.data.settings = new SheetSettings();
        }

        sheet.data.settings.sheetDefinition = new SheetDefinition();

        //ID 9 TEMPLATE
        const newModelDefinition = await loadModelDefinition(state.Settings.dataSource);

        if(!newModelDefinition){
            alerts.warning('Nepodařilo se načíst data model', state.Settings.dataSource);
            return;
        }

        newModelDefinition.columns?.forEach((column: IModelDefinitionColumn) => {
            column.joinData = newModelDefinition.joinChain ? newModelDefinition.joinChain + '.' + column.data : column.data;
        });


        sheet.data.settings.modelDefinition = new ModelDefinition(newModelDefinition);

        let options = {};
        if(state.Settings.type === 'group'){
            if(state.Settings.dataSource === 'Admin_Manager_Product') {
                sheet.data.settings.modelDefinition.dataFilter = new Filter({
                    items: getProductTypeFilterItems(),
                });

            }else if(state.Settings.dataSource === 'Admin_Manager_GroupSpecialPrice'){
                sheet.data.settings.modelDefinition.dataFilter = new Filter({
                    items: {
                        Group_ID: new FilterItem({
                            filters: [
                                {
                                    operation: 'equal',
                                    value: state.Settings.groupPrice.value,
                                }
                            ],
                            name: 'Group_ID',
                        })
                    }
                });

                options = columns['Admin_Manager_GroupSpecialPrice'].reduce((acc: any, item: any) => {
                    acc[(typeof item === 'object' ? item.data : item) + state.Settings.currency] = (typeof item === 'object' ? item : {});
                    return acc;
                }, {});
            }
        }else{
            if(state.Settings.dataSource === 'Admin_Manager_Product') {
                sheet.data.settings.modelDefinition.dataFilter = new Filter({
                    items: getProductTypeFilterItems(),
                });

            }else if(state.Settings.dataSource === 'Admin_Manager_ShopInfo'){
                sheet.data.settings.modelDefinition.dataFilter = new Filter({
                    items: {
                        Shop_ID: new FilterItem({
                            filters: [
                                {
                                    operation: 'equal',
                                    value: state.Settings.eshop.value,
                                }
                            ],
                            name: 'Shop_ID',
                        })
                    }
                });

                options = columns['Admin_Manager_ShopInfo'].reduce((acc: any, item: any) => {
                    acc[(typeof item === 'object' ? item.data : item) + state.Settings.currency] = (typeof item === 'object' ? item : {});
                    return acc;
                }, {});
            }
        }

        addColumnsFromModelDefinition(sheet.data.settings.modelDefinition, options);
        addCustomColumns();
        await addJoins();

        alerts.success('Model listu úspěšně nakonfigurován');
        showEditor.value = true;
    }

    const addJoins = async () => {
        let model = null;
        if(state.Settings.type === 'group'){
            if(state.Settings.dataSource === 'Admin_Manager_Product') {
                const columnOptions = columns['Admin_Manager_GroupSpecialPrice'].reduce((acc: any, item: any) => {
                    acc[(typeof item === 'object' ? item.data : item) + state.Settings.currency] = (typeof item === 'object' ? item : {});
                    return acc;
                }, {});

                await addJoin(sheet.data.settings.modelDefinition as ModelDefinition, 'Admin_Manager_GroupSpecialPrice', null, new Filter({
                        items: {
                            Group_ID: new FilterItem({
                                filters: [
                                    {
                                        operation: 'equal',
                                        value: state.Settings.groupPrice.value,
                                    }
                                ],
                                name: 'Group_ID',
                            })
                        }
                    }),
                    columnOptions);

                model = sheet.data.settings.modelDefinition;
            }else if(state.Settings.dataSource === 'Admin_Manager_GroupSpecialPrice'){
                const newJoin = await addJoin(sheet.data.settings.modelDefinition as ModelDefinition, 'Admin_Manager_Product', null, new Filter({
                    items: getProductTypeFilterItems(),
                }), null, null, state.Settings.productTypeFilterJoinType);

                model = newJoin?.modelDefinition;
            }
        }else{
            if(state.Settings.dataSource === 'Admin_Manager_Product') {
                const columnOptions = columns['Admin_Manager_ShopInfo'].reduce((acc: any, item: any) => {
                    acc[(typeof item === 'object' ? item.data : item) + state.Settings.currency] = (typeof item === 'object' ? item : {});
                    return acc;
                }, {});

                await addJoin(sheet.data.settings.modelDefinition as ModelDefinition, 'Admin_Manager_ShopInfo', null, new Filter({
                        items: {
                            Shop_ID: new FilterItem({
                                filters: [
                                    {
                                        operation: 'equal',
                                        value: state.Settings.eshop.value,
                                    }
                                ],
                                name: 'Shop_ID',
                            })
                        }
                    }),
                    columnOptions);

                model = sheet.data.settings.modelDefinition;
            }else if(state.Settings.dataSource === 'Admin_Manager_ShopInfo'){
                const newJoin = await addJoin(sheet.data.settings.modelDefinition as ModelDefinition, 'Admin_Manager_Product', null, new Filter({
                    items: getProductTypeFilterItems(),
                }), null, null, state.Settings.productTypeFilterJoinType);

                model = newJoin?.modelDefinition;
            }
        }

        if(model){
            const newJoin = await addJoin(model, 'Admin_Manager_Datapool');

            await addGroupPrices(model);
            await addCompetitors(model);
            await addEshopPrices(model);
            await addCdbProductSalesStats(model);
            await addHeliosProductSales(model);
        }
    }

    const addJoin = async (modelDefinition: ModelDefinition, model: string, customName: string | null = null, dataFilter: Filter | null = null, columnOptions: any = null, sort: any = null, joinType = ModelDefinitionJoin.JOIN_TYPE_LEFT) => {
        let newModelDefinitionState = await loadModelDefinition(model);

        if(!newModelDefinitionState){
            console.error('Cannot load model definition', model);
        }else{
            newModelDefinitionState.customName = customName ?? null;
            newModelDefinitionState.joinID = modelDefinition.getJoinUniqueID(newModelDefinitionState.id);
            newModelDefinitionState.joinChain = modelDefinition.joinChain ? modelDefinition.joinChain + '.' + newModelDefinitionState.joinID : newModelDefinitionState.joinID;

            newModelDefinitionState.columns?.forEach((column: IModelDefinitionColumn) => {
                column.joinData = newModelDefinitionState.joinChain ? newModelDefinitionState.joinChain + '.' + column.data : column.data;
            });
        }

        let realation = modelDefinition.relations?.find((relation) => relation.model === model);

        const newModelDefinition = new ModelDefinition(newModelDefinitionState);

        if(dataFilter){
            newModelDefinition.dataFilter = dataFilter;
        }

        if(!realation){
            console.error('Cannot find relation', model);
            return;
        }

        const newJoin: IModelDefinitionJoin = new ModelDefinitionJoin({
            ...realation,
            modelDefinition: newModelDefinition,
            joinType: joinType,
        });

        modelDefinition.joins = [
            ...modelDefinition.joins ?? [],
            newJoin
        ];
        // debugger;
        addColumnsFromModelDefinition(newModelDefinition, columnOptions ?? {});

        return newJoin;
    }

    const addGroupPrices = async (modelDefinition: IModelDefinition) => {
        for (const priceList of state.Settings.groupPriceLists) {
            const columnOptions = columns['Admin_Manager_GroupSpecialPrice'].reduce((acc: any, item) => {
                acc[(typeof item === 'object' ? item.data : item) + state.Settings.currency] = {
                    title: '[' + priceList.priceList.text + '] ' + (typeof item === 'object' ? item.data : item) + state.Settings.currency,
                    type: 'currency',
                    columnMeta: {
                        currency: getMetaCurrency(),
                    }
                };

                return acc;
            }, {});
            debugger;
            await addJoin(modelDefinition, 'Admin_Manager_GroupSpecialPrice', 'Skupinový ceník pro porovnání', new Filter({
                    items: {
                        Group_ID: new FilterItem({
                            filters: [
                                {
                                    operation: 'equal',
                                    value: priceList.priceList.value,
                                }
                            ],
                            name: 'Group_ID',
                        }),
                        EURfromCZK: new FilterItem({
                            filters: [
                                {
                                    operation: 'equal',
                                    value: priceList.source?.value === 'calculate' ? 1 : 0,
                                }
                            ],
                            name: 'EURfromCZK',
                        }),
                        CZKfromEUR: new FilterItem({
                            filters: [
                                {
                                    operation: 'equal',
                                    value: priceList.source?.value === 'calculate' ? 1 : 0,
                                }
                            ],
                            name: 'CZKfromEUR',
                        })
                    }
                }),
                columnOptions, null, priceList.joinType);
        }
    }

    const addEshopPrices = async (modelDefinition: IModelDefinition) => {
        for (const priceList of state.Settings.eshopPriceLists) {
            const columnOptions = columns['Admin_Manager_ShopInfo'].reduce((acc: any, item) => {
                acc[(typeof item === 'object' ? item.data : item) + state.Settings.currency] = {
                    title: '[' + priceList.priceList.text + '] ' + (typeof item === 'object' ? item.data : item) + state.Settings.currency,
                    type: 'currency',
                    columnMeta: {
                        currency: getMetaCurrency(),
                    }
                };
                return acc;
            }, {});

            await addJoin(modelDefinition, 'Admin_Manager_ShopInfo', 'Eshop ceník pro porovnání', new Filter({
                    items: {
                        Shop_ID: new FilterItem({
                            filters: [
                                {
                                    operation: 'equal',
                                    value: priceList.priceList.value,
                                }
                            ],
                            name: 'Shop_ID',
                        }),
                        EURfromCZK: new FilterItem({
                            filters: [
                                {
                                    operation: 'equal',
                                    value: priceList.source?.value === 'calculate' ? 1 : 0,
                                }
                            ],
                            name: 'EURfromCZK',
                        }),
                        CZKfromEUR: new FilterItem({
                            filters: [
                                {
                                    operation: 'equal',
                                    value: priceList.source?.value === 'calculate' ? 1 : 0,
                                }
                            ],
                            name: 'CZKfromEUR',
                        })
                    }
                }),
                columnOptions, null, priceList.joinType);
        }
    }

    const addCompetitors = async (modelDefinition: IModelDefinition) => {
        for (const competitor of state.Settings.competitors) {
            const columnOptions = columns['Admin_Manager_CompetitorLog'].reduce((acc: any, item) => {
                acc[item] = { title: '[' + competitor.competitor.text + '] ' + item };
                return acc;
            }, {});

            await addJoin(modelDefinition, 'Admin_Manager_CompetitorLog', 'Ceny koknkurentů k porovnání', new Filter({
                    items: {
                        Competitor_ID: new FilterItem({
                            filters: [
                                {
                                    operation: 'equal',
                                    value: competitor.competitor.value,
                                }
                            ],
                            name: 'Competitor_ID',
                        })
                    }
                }),
                columnOptions, null, competitor.joinType);
        }
    }

    const addCdbProductSalesStats = async (modelDefinition: IModelDefinition) => {
        for (const productSaleStats of state.Settings.cdbProductSalesStats) {
            const columnOptions = columns['Admin_Manager_ProductSalesStats'].reduce((acc: any, item) => {
                acc[item] = { title: '[' + productSaleStats.company.text + '] ' + item };
                return acc;
            }, {});

            let filterItems: FilterState =  {
                items: {
                    CompanyName: new FilterItem({
                        filters: [
                            {
                                operation: 'equal',
                                value: productSaleStats.company.text,
                            }
                        ],
                        name: 'CompanyName',
                    })
                }
            }

            if(productSaleStats.dateRange){
                if(productSaleStats.dateRange.from){
                    filterItems.items = {
                        ...filterItems.items,
                        dateFrom: new FilterItem({
                            filters: [
                                {
                                    operation: 'equal',
                                    value: productSaleStats.dateRange.from,
                                }
                            ],
                            name: 'dateFrom',
                        })
                    }
                }
                if(productSaleStats.dateRange.to){
                    filterItems.items = {
                        ...filterItems.items,
                        dateTo: new FilterItem({
                            filters: [
                                {
                                    operation: 'equal',
                                    value: productSaleStats.dateRange.to,
                                }
                            ],
                            name: 'dateTo',
                        })
                    }
                }
            }

            await addJoin(modelDefinition, 'Admin_Manager_ProductSalesStats', 'CDB produktové prodeje firmy k porovnání', new Filter(filterItems),
                columnOptions);
        }
    }

    const addHeliosProductSales = async (modelDefinition: IModelDefinition) => {
        for (const productSale of state.Settings.heliosProductSales) {
            const columnOptions = columns['Admin_Manager_ProductSales'].reduce((acc: any, item) => {
                acc[item] = { title: '[' + productSale.company.text + '] ' + item };
                return acc;
            }, {});

            let filterItems: FilterState =  {
                items: {
                    Company: new FilterItem({
                        filters: [
                            {
                                operation: 'equal',
                                value: productSale.company.text,
                            }
                        ],
                        name: 'Company',
                    })
                }
            }

            if(productSale.dateRange){
                if(productSale.dateRange.from){
                    filterItems.items = {
                        ...filterItems.items,
                        dateFrom: new FilterItem({
                            filters: [
                                {
                                    operation: 'equal',
                                    value: productSale.dateRange.from,
                                }
                            ],
                            name: 'dateFrom',
                        })
                    }
                }
                if(productSale.dateRange.to){
                    filterItems.items = {
                        ...filterItems.items,
                        dateTo: new FilterItem({
                            filters: [
                                {
                                    operation: 'equal',
                                    value: productSale.dateRange.to,
                                }
                            ],
                            name: 'dateTo',
                        })
                    }
                }
            }

            await addJoin(modelDefinition, 'Admin_Manager_ProductSales', 'Heilops produktové prodeje firmy k porovnání', new Filter(filterItems),
                columnOptions);
        }
    }

    const addCustomColumns = () => {
        const cols = cloneDeep(columns.custom);

        cols.forEach((column: any) => {
            if(typeof column.columnMeta !== 'undefined'){
                Object.entries(column.columnMeta).forEach(([key, value]) => {
                    if(typeof value === 'function'){
                        column.columnMeta[key] = value();
                    }
                });
            }

            sheet.data.settings?.sheetDefinition?.addColumn(new SheetDefinitionColumn(column));
        });
    }

    const addColumnsFromModelDefinition = (modelDefinition: IModelDefinition, options: {} = {}) => {
        modelDefinition.columns?.forEach((column: IModelDefinitionColumn) => {
            if(!columns.hasOwnProperty(modelDefinition.id)){
                return;
            }

            let template = null;
            columns[modelDefinition.id].forEach((col, i) => {
               if(template) return;

               if(typeof col === 'object'){
                   if(col.data === column.data){
                       template = col;
                   }
               }else{
                   if(col === column.data){
                       template = col;
                   }
               }
            });

            if(template){
                if(typeof template.columnMeta !== 'undefined'){
                    Object.entries(template.columnMeta).forEach(([key, value]) => {
                        if(typeof value === 'function'){
                            template.columnMeta[key] = value();
                        }
                    });
                }
            }else{
                if(options.hasOwnProperty(column.data)){
                    if(typeof options[column.data].columnMeta !== 'undefined') {
                        Object.entries(options[column.data].columnMeta).forEach(([key, value]) => {
                            if (typeof value === 'function') {
                                options[column.data].columnMeta[key] = value();
                            }
                        });
                    }
                }
            }

            //@ts-ignore
            if(template || options.hasOwnProperty(column.data)){
                if(template && typeof template === 'object'){
                    sheet.data.settings?.sheetDefinition?.addColumn(new SheetDefinitionColumn({
                        //@ts-ignore
                        title: options[column.data]?.title ?? column.title,
                        data: column.data,
                        //@ts-ignore
                        type: options[column.data]?.type ?? column.type,
                        defaultValue: column.defaultValue,
                        model: modelDefinition.id,
                        joinData: column.joinData,
                        afterLoad: options.afterLoad ?? null,
                        ...template ?? options[column.data] ?? {},
                    }));
                }else{
                    sheet.data.settings?.sheetDefinition?.addColumn(new SheetDefinitionColumn({
                        //@ts-ignore
                        title: options[column.data]?.title ?? column.title,
                        data: column.data,
                        type: options[column.data]?.type ?? column.type,
                        defaultValue: column.defaultValue,
                        model: modelDefinition.id,
                        joinData: column.joinData,
                        ...options[column.data] ?? {},
                    }));
                }

            }
        });
    }

    watch(() => state.Settings.type, (newVal, oldVal) => {
        if(newVal != oldVal && oldVal !== ''){
            state.Settings.groupPrice = '';
            state.Settings.dataSource = '';
            state.Settings.groupPriceLists = [];
            state.Settings.eshopPriceLists= [];
            state.Settings.competitors = [];
            state.Settings.eshop = '';
            state.Settings.currency = '';
        }
    });

    const getProductTypeFilterItems = () => {
        let items = {};
        let query: {[key: string]: any} = {'section':[], 'type':[], 'pattern':[]}
        state.Settings.productTypeFilter.forEach(function (a: string) {
            const v: any[] = a.split('.');
            query.section.indexOf(v[0]) === -1 ? query.section.push(v[0]) : null;
            if (v[1] !== undefined)
                query.type.indexOf(v[1]) === -1 ? query.type.push(v[1]) : null;
            if (v[2] !== undefined)
                query.pattern.indexOf(v[2]) === -1 ? query.pattern.push(v[2]) : null;
        });

        if(query.section.length){
            items = {
                ...items ?? {},
                Section_ID: new FilterItem({
                    filters: [
                        {
                            operation: 'in',
                            value: query.section,
                        }
                    ],
                    name: 'Section_ID',
                })
            };
        }

        if(query.type.length){
            items = {
                ...items ?? {},
                ProductType_ID: new FilterItem({
                    filters: [
                        {
                            operation: 'in',
                            value: query.type,
                        }
                    ],
                    name: 'ProductType_ID',
                })
            };
        }

        if(query.pattern.length){
            items = {
                ...items ?? {},
                Pattern_ID: new FilterItem({
                    filters: [
                        {
                            operation: 'in',
                            value: query.pattern,
                        }
                    ],
                    name: 'Pattern_ID',
                })
            };
        }

        return items;
    }

    //porovnaní group ceníku dialog
    const showGroupPriceListDialog = ref(false);
    //end porovnaní group ceníku dialog

    //porovnaní eshop ceníku dialog
    const showEshopPriceListDialog = ref(false);
    //end porovnaní eshop ceníku dialog

    //konkurenti dialog
    const showCompetitorDialog = ref(false);
    //end konkurenti dialog

    //cdb produktové prodeje dialog
    const showCdbProductSalesStatsDialog = ref(false);
    //end cdb produktové prodeje dialog

    //helios produktové prodeje dialog
    const showHeliosProductSalesDialog = ref(false);
    //end helios produktové prodeje dialog

    const onCreateSheet = async (sheet: Sheet, result) => {
        if(sheet.id && result){
            state.Sheet_ID = sheet.id;
            await saveToServer(state);

            router.push({ name: 'homepage' });
        }
    }

const layoutStore = useLayoutStore();
const {sheetManager}: SheetManager = useSheetManager();
    onMounted(async () => {
        if(props.id){
            const loadedSheet = await sheetManager.loadByID(props.id);

            if(loadedSheet){
                sheet.data = loadedSheet;
            }
        }
    })

    watch(() => showEditor.value, (newVal, oldVal) => {
        if(newVal === false){
            layoutStore.setOnBack(() => {
                showEditor.value = true;
            });
        }
    });
</script>

<template>
    <div>
        <div v-if="!showEditor" class="configurator">
            <div class="left-panel">
                <h2>Price List Configurator</h2>
                <div class="section">
                    <div class="section-title">
                        Typ ceníku:
                    </div>
                    <div class="radio-inputs-wrapper">
                        <Radio id="type_group" value="group" v-model="state.Settings.type" name="type" label="Skupinový" />
                        <Radio id="type_eshop" value="eshop" v-model="state.Settings.type" name="type" label="Eshop" />
                    </div>
                </div>
                <div v-if="state.Settings.type === 'group'" class="section">
                    <div class="section-title">
                        Ceník k aktualizaci:
                    </div>
                    <NewSelect
                        v-model="state.Settings.groupPrice"
                        :url="url+'/user/group-price/data-list'"
                        :filterable="true"
                        :return-object="true"
                    ></NewSelect>
                </div>
                <div v-else-if="state.Settings.type === 'eshop'" class="section">
                    <div class="section-title">
                        Ceník k aktualizaci:
                    </div>
                    <NewSelect
                        v-model="state.Settings.eshop"
                        :url="url+'/admin/shop/data-list'"
                        :filterable="true"
                        :return-object="true"
                    ></NewSelect>
                </div>
                <div class="section" v-if="state.Settings.type === 'group'">
                    <div class="section-title">
                        Zdroj dat:
                    </div>
                    <div class="radio-inputs-wrapper">
                        <Radio id="dataSource_product" value="Admin_Manager_Product" v-model="state.Settings.dataSource" name="dataSource" label="Produkty" />
                        <Radio id="dataSource_price-list" value="Admin_Manager_GroupSpecialPrice" v-model="state.Settings.dataSource" name="dataSource" label="Položky ceníku" />
                    </div>
                </div>
                <div class="section" v-if="state.Settings.type === 'eshop'">
                    <div class="section-title">
                        Zdroj dat:
                    </div>
                    <div class="radio-inputs-wrapper">
                        <Radio id="dataSource_product" value="Admin_Manager_Product" v-model="state.Settings.dataSource" name="dataSource" label="Produkty" />
                        <Radio id="dataSource_price-list" value="Admin_Manager_ShopInfo" v-model="state.Settings.dataSource" name="dataSource" label="Položky ceníku" />
                    </div>
                </div>
                <div class="section">
                    <div class="section-title">
                        Měna:
                    </div>
                    <div class="radio-inputs-wrapper">
                        <Radio id="currency_czk" value="CZK" v-model="state.Settings.currency" name="currency" label="CZK" />
                        <Radio id="currency_eur" value="EUR" v-model="state.Settings.currency" name="currency" label="EUR" />
                    </div>
                </div>
                <div class="section">
                    <div class="section-title">
                        Typ produktů:
                    </div>
                    <NewSelect
                        v-if="(state.Settings.type === 'group' && state.Settings.dataSource === 'Admin_Manager_GroupSpecialPrice') || (state.Settings.type === 'eshop' && state.Settings.dataSource === 'Admin_Manager_ShopInfo')"
                        v-model="state.Settings.productTypeFilterJoinType"
                        label="Typ propojení"
                        :options="joinTypeOptions"
                    ></NewSelect>
                    <TreeView :data-url="url+'/default/product/load-product-type-tree'" v-model="state.Settings.productTypeFilter"></TreeView>
                </div>
            </div>

            <div class="right-panel">
                <div class="section">
                    <div class="table-tool-header">
                        <div class="section-title">
                            Skupinové ceníky pro porovnání:
                        </div>
                        <div>
                            <Button variant="green" @click="showGroupPriceListDialog = true" size="small" icon="plus">Přidat</Button>
                            <GroupPriceListDialog v-if="showGroupPriceListDialog" :show="showGroupPriceListDialog" @save="(pricelist) => {state.Settings.groupPriceLists = [...state.Settings.groupPriceLists, pricelist]}" @close="showGroupPriceListDialog = false"></GroupPriceListDialog>
                        </div>
                    </div>
                    <Table>
                        <Thead>
                            <TheadTr>
                                <TheadTh>Ceník k porovnání</TheadTh>
                                <TheadTh>Typ propojení</TheadTh>
                                <TheadTh>Zdroj ceny</TheadTh>
                                <TheadTh></TheadTh>
                            </TheadTr>
                        </Thead>
                        <Tbody>
                        <template v-if="state.Settings.groupPriceLists.length">
                            <TbodyTr v-for="(priceList, index) in state.Settings.groupPriceLists" :key="index">
                                <TbodyTd>
                                    {{priceList.priceList.text}}
                                </TbodyTd>
                                <TbodyTd>
                                    {{priceList.joinType}}
                                </TbodyTd>
                                <TbodyTd>
                                    {{priceList.source?.text ?? ''}}
                                </TbodyTd>
                                <TbodyTd>
                                    <Button @click="state.Settings.groupPriceLists.splice(index, 1);" variant="red" size="small" class="mb-0 text-base">X</Button>
                                </TbodyTd>
                            </TbodyTr>
                        </template>
                        <TbodyTr v-else>
                            <TbodyTd :colspan="2" class="table-no-content">
                                <span>Žádný ceník k porovnání</span>
                            </TbodyTd>
                        </TbodyTr>
                        </Tbody>
                    </Table>
                </div>
                <div class="section">
                    <div class="table-tool-header">
                        <div class="section-title">
                            Eshop ceníky pro porovnání:
                        </div>
                        <div>
                            <Button variant="green" @click="showEshopPriceListDialog = true" size="small" icon="plus">Přidat</Button>
                            <EshopPriceListDialog v-if="showEshopPriceListDialog" :show="showEshopPriceListDialog" @save="(pricelist) => {state.Settings.eshopPriceLists = [...state.Settings.eshopPriceLists, pricelist]}" @close="showEshopPriceListDialog = false"></EshopPriceListDialog>
                        </div>
                    </div>
                    <Table>
                        <Thead>
                            <TheadTr>
                                <TheadTh>Ceník k porovnání</TheadTh>
                                <TheadTh>Typ propojení</TheadTh>
                                <TheadTh>Zdroj ceny</TheadTh>
                                <TheadTh></TheadTh>
                            </TheadTr>
                        </Thead>
                        <Tbody>
                        <template v-if="state.Settings.eshopPriceLists.length">
                            <TbodyTr v-for="(priceList, index) in state.Settings.eshopPriceLists" :key="index">
                                <TbodyTd>
                                    {{priceList.priceList.text}}
                                </TbodyTd>
                                <TbodyTd>
                                    {{priceList.joinType}}
                                </TbodyTd>
                                <TbodyTd>
                                    {{priceList.source?.text ?? ''}}
                                </TbodyTd>
                                <TbodyTd>
                                    <Button @click="state.Settings.eshopPriceLists.splice(index, 1);" variant="red" size="small" class="mb-0 text-base">X</Button>
                                </TbodyTd>
                            </TbodyTr>
                        </template>
                        <TbodyTr v-else>
                            <TbodyTd :colspan="2" class="table-no-content">
                                <span>Žádný ceník k porovnání</span>
                            </TbodyTd>
                        </TbodyTr>
                        </Tbody>
                    </Table>
                </div>
                <div class="section">
                    <div class="table-tool-header">
                        <div class="section-title">
                            Konkurenti:
                        </div>
                        <div>
                            <Button variant="green" @click="showCompetitorDialog = true" size="small" icon="plus">Přidat</Button>
                            <CompetitorDialog v-if="showCompetitorDialog" :show="showCompetitorDialog" @save="(competitor) => {state.Settings.competitors = [...state.Settings.competitors, competitor]}" @close="showCompetitorDialog = false"></CompetitorDialog>
                        </div>
                    </div>
                    <Table>
                        <Thead>
                            <TheadTr>
                                <TheadTh>Konkurent</TheadTh>
                                <TheadTh>Typ propojení</TheadTh>
                                <TheadTh></TheadTh>
                            </TheadTr>
                        </Thead>
                        <Tbody>
                        <template v-if="state.Settings.competitors.length">
                            <TbodyTr v-for="(competitor, index) in state.Settings.competitors" :key="index">
                                <TbodyTd>
                                    {{competitor.competitor.text}}
                                </TbodyTd>
                                <TbodyTd>
                                    {{competitor.text}}
                                </TbodyTd>
                                <TbodyTd>
                                    <Button @click="state.Settings.competitors.splice(index, 1);" variant="red" size="small" class="mb-0 text-base">X</Button>
                                </TbodyTd>
                            </TbodyTr>
                        </template>
                        <TbodyTr v-else>
                            <TbodyTd :colspan="2" class="table-no-content">
                                <span>Žádný konkurent</span>
                            </TbodyTd>
                        </TbodyTr>
                        </Tbody>
                    </Table>
                </div>
                <div class="section">
                    <div class="table-tool-header">
                        <div class="section-title">
                            Produktové prodeje (CDB):
                        </div>
                        <div>
                            <Button variant="green" @click="showCdbProductSalesStatsDialog = true" size="small" icon="plus">Přidat</Button>
                            <CdbProductSalesStatsDialog v-if="showCdbProductSalesStatsDialog" :show="showCdbProductSalesStatsDialog" @save="(productSaleStats) => {state.Settings.cdbProductSalesStats = [...state.Settings.cdbProductSalesStats, productSaleStats]}" @close="showCdbProductSalesStatsDialog = false"></CdbProductSalesStatsDialog>
                        </div>
                    </div>
                    <Table>
                        <Thead>
                            <TheadTr>
                                <TheadTh>Firma</TheadTh>
                                <TheadTh>Datum od</TheadTh>
                                <TheadTh>Datum do</TheadTh>
                                <TheadTh></TheadTh>
                            </TheadTr>
                        </Thead>
                        <Tbody>
                        <template v-if="state.Settings.cdbProductSalesStats.length">
                            <TbodyTr v-for="(productSaleStats, index) in state.Settings.cdbProductSalesStats" :key="index">
                                <TbodyTd>
                                    {{productSaleStats.company.text}}
                                </TbodyTd>
                                <TbodyTd>
                                    {{productSaleStats.dateFrom}}
                                </TbodyTd>
                                <TbodyTd>
                                    {{productSaleStats.dateTo}}
                                </TbodyTd>
                                <TbodyTd>
                                    <Button @click="state.Settings.cdbProductSalesStats.splice(index, 1);" variant="red" size="small" class="mb-0 text-base">X</Button>
                                </TbodyTd>
                            </TbodyTr>
                        </template>
                        <TbodyTr v-else>
                            <TbodyTd :colspan="4" class="table-no-content">
                                <span>Žádné produktové prodeje</span>
                            </TbodyTd>
                        </TbodyTr>
                        </Tbody>
                    </Table>
                </div>
                <div class="section">
                    <div class="table-tool-header">
                        <div class="section-title">
                            Produktové prodeje (Helios):
                        </div>
                        <div>
                            <Button variant="green" @click="showHeliosProductSalesDialog = true" size="small" icon="plus">Přidat</Button>
                            <HeliosProductSalesDialog v-if="showHeliosProductSalesDialog" :show="showHeliosProductSalesDialog" @save="(productSaleStats) => {state.Settings.heliosProductSales = [...state.Settings.heliosProductSales, productSaleStats]}" @close="showHeliosProductSalesDialog = false"></HeliosProductSalesDialog>
                        </div>
                    </div>
                    <Table>
                        <Thead>
                        <TheadTr>
                            <TheadTh>Firma</TheadTh>
                            <TheadTh>Datum od</TheadTh>
                            <TheadTh>Datum do</TheadTh>
                            <TheadTh></TheadTh>
                        </TheadTr>
                        </Thead>
                        <Tbody>
                        <template v-if="state.Settings.heliosProductSales.length">
                            <TbodyTr v-for="(productSaleStats, index) in state.Settings.heliosProductSales" :key="index">
                                <TbodyTd>
                                    {{productSaleStats.company.text}}
                                </TbodyTd>
                                <TbodyTd>
                                    {{productSaleStats.dateFrom}}
                                </TbodyTd>
                                <TbodyTd>
                                    {{productSaleStats.dateTo}}
                                </TbodyTd>
                                <TbodyTd>
                                    <Button @click="state.Settings.heliosProductSales.splice(index, 1);" variant="red" size="small" class="mb-0 text-base">X</Button>
                                </TbodyTd>
                            </TbodyTr>
                        </template>
                        <TbodyTr v-else>
                            <TbodyTd :colspan="4" class="table-no-content">
                                <span>Žádné produktové prodeje</span>
                            </TbodyTd>
                        </TbodyTr>
                        </Tbody>
                    </Table>
                </div>
                <div class="button-section">
                    <Button v-if="props.id" @click="showEditor = true">Zpět</Button>
                    <Button variant="green" @click="createConfiguration">Nakonfigurovat</Button>
                </div>
            </div>
        </div>
        <div v-else>
            <SheetEditor v-if="sheet.data" class="px-40" :sheet="sheet.data" :on-save="onCreateSheet" :show-configurator="() => {showEditor = false}" :clone="false"></SheetEditor>
        </div>
    </div>
</template>

<style scoped>
    .section{
        margin-bottom: 25px;
    }

    .section-title{
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 15px;
    }

    .radio-inputs-wrapper{
        display: flex;

        & > *{
            margin-right: 20px;
        }
    }

    .configurator{
        display: flex;

        .left-panel{
            width: 500px;
            margin-right: 35px;

            h2{
                margin-bottom: 30px;
            }
        }

        .right-panel{
            flex-grow: 1;
            box-shadow: 0px 4px 44px 0px rgba(9, 19, 37, 0.10);
            background: #FFF;
            border-radius: 10px;
            padding: 35px 35px 50px 35px;

            .section-title{
                margin-bottom: 0px;
            }

            .section{
                margin-bottom: 22px;
            }

            .table-tool-header{
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;
                align-items: center;
            }

            .button-section{
                display: flex;
                justify-content: end;
                margin-top: 50px;
            }
        }
    }

    .table-no-content{
        padding: 15px;
        padding-left: 25px;
    }
</style>