<script setup>
import {IconProps, useIcon} from './useIcon.js'

const props = defineProps(IconProps(19,19));

const { color } = useIcon(props);
</script>

<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="props.width" :height="props.height" viewBox="0 0 19 19" fill="none" :style="{color: color}">
        <path d="M16.0312 4.55406H10.6875C10.5943 4.55545 10.502 4.53652 10.4169 4.49858C10.3318 4.46064 10.2559 4.4046 10.1947 4.33438L9.12594 3.02813C8.95386 2.82246 8.73853 2.65728 8.49529 2.54439C8.25206 2.43149 7.98691 2.37366 7.71875 2.375H2.96875C2.51032 2.36378 2.066 2.53402 1.73242 2.84869C1.39885 3.16336 1.20301 3.59701 1.1875 4.05531V14.9447C1.20301 15.403 1.39885 15.8366 1.73242 16.1513C2.066 16.466 2.51032 16.6362 2.96875 16.625H16.0312C16.4897 16.6362 16.934 16.466 17.2676 16.1513C17.6011 15.8366 17.797 15.403 17.8125 14.9447V6.23438C17.797 5.77607 17.6011 5.34242 17.2676 5.02775C16.934 4.71309 16.4897 4.54285 16.0312 4.55406ZM16.625 14.9447C16.61 15.0881 16.5394 15.2199 16.4285 15.312C16.3175 15.4041 16.175 15.4491 16.0312 15.4375H2.96875C2.82503 15.4491 2.68246 15.4041 2.57151 15.312C2.46056 15.2199 2.39003 15.0881 2.375 14.9447V4.05531C2.39003 3.91191 2.46056 3.78008 2.57151 3.68799C2.68246 3.5959 2.82503 3.55086 2.96875 3.5625H7.71875C7.81192 3.56112 7.90428 3.58005 7.98939 3.61799C8.0745 3.65593 8.15031 3.71196 8.21156 3.78219L9.28031 5.08844C9.45345 5.29286 9.669 5.45717 9.91199 5.56995C10.155 5.68273 10.4196 5.74129 10.6875 5.74156H16.0312C16.175 5.72993 16.3175 5.77496 16.4285 5.86705C16.5394 5.95914 16.61 6.09098 16.625 6.23438V14.9447Z" fill="currentColor"/>
    </svg>
</template>

<style scoped>

</style>