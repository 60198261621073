import {type ITable, Table, type TableState} from "@project/Excel/Handsontable/js/Table";
import axios from "axios";
import {SheetManager} from "@project/Excel/Handsontable/js/SheetManager";
import {useSheetManager} from "@project/Excel/ExcelApp/Hooks/useSheetManager";

export interface IPriceListTable extends ITable {

}

export interface PriceListTableState extends TableState {
    configurator?: any,
}

export class PriceListTable extends Table implements IPriceListTable{
    public configurator?: any;

    constructor(tableState: PriceListTableState | null = null){
        super(tableState);

        this.setConfigurator();
    }

    async setConfigurator(){
        if(this.sheet && this.sheet.id){
            this.configurator = await PriceListTable.loadPriceListConfigurator(this.sheet.id);
        }
    }

    getImportPriceListData(){
        let data = this.getData();

        const productIDcol = this.sheet.settings.sheetDefinition.getColumnByAlias('_Product_ID');
        const productNameCol = this.sheet.settings.sheetDefinition.getColumnByAlias('_ProductName');
        const rmtCodeCol = this.sheet.settings.sheetDefinition.getColumnByAlias('_RmtCode');
        const currentPriceCol = this.sheet.settings.sheetDefinition.getColumnByAlias('_CurrentPrice');
        const currentPriceDepositCol = this.sheet.settings.sheetDefinition.getColumnByAlias('_CurrentPriceDeposit');

        data = data.map((row) => {
            if(row.EnableForImport === false || row.EnableForImport === ''){
                return;
            }

            let newPrice = row.NewPrice ?? null;
            if(newPrice === ''){
                newPrice = null;
            }

            let newDeposit = row.NewDeposit ?? null;
            if(newDeposit === ''){
                newDeposit = null;
            }

            let currentPrice = row[currentPriceCol.joinData] ?? null;
            if(currentPrice === ''){
                currentPrice = null;
            }

            let currentDepositPrice = row[currentPriceDepositCol.joinData] ?? null;
            if(currentDepositPrice === ''){
                currentDepositPrice = null;
            }

            if(newPrice || newDeposit){
                return {
                    ID: row[productIDcol.joinData] ?? null,
                    ProductName: row[productNameCol.joinData] ?? null,
                    RmtCode: row[rmtCodeCol.joinData] ?? null,
                    CurrentPrice: currentPrice !== null ? currentPrice*100 : null,
                    CurrentPriceDeposit: currentDepositPrice !== null ? currentDepositPrice*100 : null,
                    NewPrice: newPrice !== null ? newPrice : null,
                    NewDeposit: newDeposit !== null ? newDeposit : null,
                };
            }
        });

        data = data.filter((row) => {return typeof row !== 'undefined'});

        return data;
    }

    importPriceList(){
        //url
        const url = window.remoteUrl;
        //url

        if(!this.configurator){
            this.alerts.error('Konfigurátor není načtený')
            console.error('Configurator not set');
            return;
        }

        const data = this.getImportPriceListData();

        data.map((row) => {
            return {
                ID: row.ID,
                NewPrice: row.NewPrice,
                NewDeposit: row.NewDeposit
            }
        });

        let priceListID = null;
        if(this.configurator.Settings.type === 'group'){
            priceListID = this.configurator.Settings.groupPrice.value;
        }else if(this.configurator.Settings.type === 'eshop'){
            priceListID = this.configurator.Settings.eshop.value;
        }

        const attrs = {
            sheetID: this.sheet.id,
            data: JSON.stringify(data),
            currency: this.configurator.Settings.currency,
            type: this.configurator.Settings.type,
            priceListID: priceListID,
        }

        const {sheetManager}: SheetManager = useSheetManager();
        let result = null;
        this.ajax.postForm(`${url}/default/handsontable/import-price-list`, attrs, null, {
            waitingAlert: {
                title: 'Import ceníku',
            }
        }).then(({response, alert}) => {
            alert.changeToSuccess('Ceník úspěšně importován');
            this.saveSheetDataToServer();

            sheetManager.logImportPriceList(this.sheet, {
                importData: data,
                result: 'OK',
            });
        }).catch(({error, alert}) => {
            alert.changeToError('Chyba při importu ceníku', error.message ? error.message : '', error);

            sheetManager.logImportPriceList(this.sheet, {
                importData: data,
                result: error.message,
            });
        });





        // tableData.forEach((row, index) => {
        //     data[row[0]] = row[1];
        // });
    }

    static async loadPriceListConfigurator(sheet_id: any){
        //url
        const url = window.remoteUrl;
        //url

        if (!sheet_id) return null;

        const data = {
            Sheet_ID: sheet_id,
        };

        try {
            const response = await axios.get(`${url}/admin/price-list-configurator/data-list`, {
                params: data,
            });

            const responseData = response.data.items;
            const priceList = responseData[0] ?? null;

            if (!priceList) return null;

            priceList.Settings = JSON.parse(priceList.Settings);

            return priceList;
        } catch (error) {
            console.error('Error loading data from server:', error);
            throw error;
        }
    };
}