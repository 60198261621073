<script setup>
    import {DataGrid} from "@/DataGrid/js/DataGrid.js";
    import Head from "@/DataGrid/Components/Table/Head/Head.vue";
    import {onMounted, ref} from "vue";
    import Body from "@/DataGrid/Components/Table/Body/Body.vue";
    import LoadingLine from "@/Components/Other/Loading/LoadingLine.vue";
    import ContextMenu from "@/DataGrid/Components/Table/ContextMenu/ContextMenu.vue";
    import Footer from "@/DataGrid/Components/Table/Footer/Footer.vue";

    const props = defineProps({
        dataGrid: {
            type: DataGrid,
            required: true
        }
    });

    const gridRef = ref(null);

    const initHeaderScroll = () => {
        const elmnt = gridRef.value.querySelector(".header-wrapper");
        const elmnt2 = gridRef.value.querySelector(".v-vl--show-scrollbar");

        elmnt2.addEventListener("scroll", (e) => {
            elmnt.scrollLeft = e.target.scrollLeft;
        });

    }

    onMounted(() => {
        initHeaderScroll();

        props.dataGrid?.onInitComplete?.();
    });
</script>

<template>
    <div class="grid grid-vue" style="width: 100%" ref="gridRef">
        <div class="grid-wrapper">
            <Head :columns="dataGrid.columns" :data-grid="dataGrid"></Head>
            <div class="body-wrapper">
                <div v-if="dataGrid.ajax" class="loading-line-wrapper">
                    <LoadingLine v-if="dataGrid.ajax._loading"></LoadingLine>
                </div>
                <Body :rows="dataGrid._rows" :scroller="dataGrid.scroller" :data-grid="dataGrid"></Body>
            </div>
        </div>
        <div>
            <Footer :data-grid="dataGrid"></Footer>
        </div>
    </div>
</template>

<style scoped>
.grid-wrapper{
    box-shadow: 0 3px 15px rgba(62, 70, 84, .3882352941);
}

.grid{
    margin-top: 10px;

    .loading-line-wrapper{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1;
    }
}

.body-wrapper{
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
}
</style>