<script setup>
import { computed } from 'vue';
import {IconProps, useIcon} from './useIcon.js'

const props = defineProps({
    ...IconProps(12, 7),
    direction: {
        type: String,
        default: 'bottom',
    }
});

const rotation = {
    'right': 'rotate(-90deg)',
    'bottom': 'rotate(0deg)',
    'top': 'rotate(180deg)',
    'left': 'rotate(90deg)'
}

const { color } = useIcon(props);
</script>

<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="props.width" :height="props.height" viewBox="0 0 12 7" fill="none" style="transition: transform .3s" :style="{color: color, transform: rotation[direction]}">
        <path d="M5.8,6.8L0.5,1.6c-0.3-0.3-0.3-0.8,0-1.1c0.3-0.3,0.8-0.3,1.1,0l4.2,4.2l4.2-4.2c0.3-0.3,0.8-0.3,1.1,0s0.3,0.8,0,1.1L5.8,6.8z" fill="currentColor" />
    </svg>
</template>

<style scoped>

</style>