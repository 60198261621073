import { defineStore } from 'pinia';
import {reactive, ref} from "vue";
import { useAlert } from "@/Components/Alerts/js/useAlert";

// Max počet alertů
const DEFAULT_ALERT_COUNT = 50

export const useAlertStore = defineStore('alerts', () => {

    const alerts = ref( []);
    const maxAlerts = ref(DEFAULT_ALERT_COUNT);
    const isHistoryBarShown = ref(false);

    /**
     * @brief Vloží alert do alertStoru
     * @param type typ alertu - momentálně success, warning, danger a **waiting**
     * @param title nadpis alertu
     * @param message zpráva alertu
     * @param data data, které do alertu můžeme poslat za účelem např. vypsání prostředí, když nastane chyba
     * @param duration délka zobrazení alertu (ms)
     * @returns objekt vytvořeného alertu
     * */
    function push (type, title, message, data = null, duration = 5000) {
        const alert = useAlert({type: type, title: title, message: message, data: data, duration: duration});
        // const alert =
        if(alerts.value.length === maxAlerts.value) {
               alerts.value.shift();
        }
        alerts.value.push(alert);
        return alert;
    }

    function clearAll() {
        alerts.value = [];
    }

    function removeAlert(alert) {
        const index = alerts.value.indexOf(alert);
        if (index !== -1) {
            alerts.value.splice(index, 1);
        }
    }

    function danger(title, message, data = null, duration = 5000){
        return push('danger', title, message, data, duration);
    }

    function error(title, message, data = null, duration = 5000){
        return danger(title, message, data, duration);
    }

    function info(title, message, data = null, duration = 5000){
        return push('info', title, message, data, duration);
    }

    function warning(title, message, data = null, duration = 5000){
        return push('warning', title, message, data, duration);
    }

    function waiting(title, message, data = null, duration = 5000){
        return push('waiting', title, message, data, duration);
    }

    function success(title, message, data = null, duration = 5000){
        return push('success', title, message, data, duration);
    }

    function alert(title, type = 'info', message = '', persist = false){
        if(type === 'error'){
            type = 'danger';
        }

        return push(type, title, message);
    }

    function showHistoryBar(show = true){
        isHistoryBarShown.value = show;
    }


    return { alerts, push, removeAlert, clearAll, danger, error, info, warning, waiting, success, alert, showHistoryBar, isHistoryBarShown}
});