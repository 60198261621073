<script setup lang="ts">
import {reactive, ref, watch} from 'vue';
import InputField from "@/Components/Inputs/InputField.vue";
import Checkbox from "@/Components/Inputs/Checkbox.vue";
import NewSelect from "@/Components/Inputs/NewSelect.vue";
import Table from "@/Components/Table/Table.vue";
import Thead from "@/Components/Table/Thead.vue";
import TheadTr from "@/Components/Table/TheadTr.vue";
import TheadTh from "@/Components/Table/TheadTh.vue";
import Tbody from "@/Components/Table/Tbody.vue";
import TbodyTr from "@/Components/Table/TbodyTr.vue";
import TbodyTd from "@/Components/Table/TbodyTd.vue";
import Button from "@/Components/Inputs/Button.vue";
import axios from "axios";
import TextArea from "@/Components/Inputs/TextArea.vue";
import TinyMCE from "@/Components/Inputs/TinyMCE.vue";
import DatePicker from "@/Components/Inputs/DatePicker.vue";
import Supervisors from "./Edit/Supervisors.vue";
import Groups from "./Translate/Groups.vue";
import Languages from "./Edit/Languages.vue";
import Attachments from "./Edit/Attachments.vue";
import AttachmentList from "./Translate/AttachmentList.vue";
import ModelEditor from "@project/Excel/ExcelApp/Components/SheetEditor/ModelEditor.vue";
import Modal from "@/Components/Modal/Modal.vue";


const props = defineProps<{
    languages: Array,
    data: {
        ID: string,
        Title: string,
        Description: string,
        SourceLanguage_ID: string,
        Deadline: string,
        translationLanguages: Array<{
            Language_ID: string,
            Completed: string,
            language: Array<{
                ID: string,
                Name: string
            }>,
            user: Array<{
                ID: string,
                Name: string
            }>,
        }>,
        translationItemGroups: Array<{
            ID: string,
            Type: number,
            translationItems: Array<{
                ID: string,
                Item: string,
                Note: string,
                MinLenght: number,
                MaxLenght: number,
                Type: string
            }>,
        }>,
        translationSupervisors: Array<{
            ID: string,
            User_ID: number,
            user: Array<{
              ID: string,
              Name: string,
            }>,
        }>,
        translationAttachments: Array<{
            ID: string,
            File_ID: number,
            file: Array<{
              ID: string,
              Type: string,
              Name: string,
              Extension: string,
            }>,
        }>,
        createdBy: {
            Name: string,
            Email: string
        }
    },
    languageid: Number,
}>();

const state = reactive({
    ID: props.data.ID,
    Title: props.data.Title,
    Description: props.data.Description,
    SourceLanguage_ID: parseInt(props.data.SourceLanguage_ID),
    Deadline: props.data.Deadline,
    translationLanguages: props.data.translationLanguages ? props.data.translationLanguages.map(lang => ({
        ID: lang.ID,
        Language_ID: lang.Language_ID,
        Completed: lang.Completed,
        user:{
            ID: lang.user.ID,
            Name: lang.user.Name,
        },
        language: {
            ID: lang.language.ID,
            Name: lang.language.Name,
        },
    })) : [],
    translationItemGroups: props.data.translationLanguages ? props.data.translationItemGroups.map(group => ({
        ID: group.ID,
        Type: group.Type,
        translationItems: group.translationItems.map(item => ({
            ID: item.ID,
            Item: item.Item,
            Note: item.Note,
            MinLenght: item.MinLenght,
            MaxLenght: item.MaxLenght,
            Type: item.Type,
            translationItemLangs: {
                ID: item.translationItemLangs[0] ? item.translationItemLangs[0].ID : null,
                TranslationItem_ID: item.translationItemLangs[0] ? item.translationItemLangs[0].TranslationItem_ID : null,
                Language_ID: item.translationItemLangs[0] ? item.translationItemLangs[0].Language_ID : null,
                Translation: item.translationItemLangs[0] ? item.translationItemLangs[0].Translation : '',

            },
        })),
    })) : [],
    translationSupervisors: props.data.translationSupervisors ? props.data.translationSupervisors.map(item => ({
        ID: item.ID,
        User_ID: item.User_ID,
        user: item.user,
    })) : [],
    translationAttachments: props.data.translationAttachments ? props.data.translationAttachments.map(attachment => ({
        ID: attachment.ID,
        File_ID: attachment.File_ID,
        file: {
            ID: attachment.file.ID,
            Type: attachment.file.Type,
            Name: attachment.file.Name,
            Extension: attachment.file.Extension,
        },
        UploadedFile: false,
        UploadedFileName: false,
    })) : [],
    createdBy: props.data.createdBy
});
const languageID = ref(props.languageid);


const groupsRef = ref();
const submitData = () => {
    if (!groupsRef.value.validateAllTranslations()) {
        alert('Všechna pole pro překlad musí být vyplněna a nepřekračovat maximální délku.');
        return;
    }

    var bodyFormData = new FormData();
    bodyFormData.append('translationId', state.ID);
    bodyFormData.append('languageId', languageID.value);

    axios.post('/default/translation-tool/finilize-translation', bodyFormData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
    .then(response => {
        if (response.data.success) {
            window.location.href = '/default/translation-tool/list';
        } else {
            alert('Došlo k chybě při odesílání dat: ' + response.data.message);
        }
    })
    .catch(error => {
        console.error('Error submitting data:', error);
        alert('Nastala chyba při ukládání překladu');
    });

    alert('ok');
};

const commentTranslation = () => {
    const commentData = {
        ID: props.data.ID,
        LangID: props.languageid,
        Comment: commentText.value,


    }

    if (commentText.value.trim() !== '') {
        alert('Comment Submitted: ' + commentText.value);
        axios.post('/default/translation-tool/add-comment', commentData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                if (response.data.success) {
                    window.location.href = '/default/translation-tool/list';
                }
            })
            .catch(error => {
                console.error('Error submitting data:', error);
                alert('Nastala chyba při ukládání překladu');
            });
        // Handle comment logic here
    } else {
        alert('Please enter a comment.');
    }
    closeModal();
};

const showCommentModal = ref<boolean>(false);
const commentText = ref<string>("");

const close = () => {
    showCommentModal.value = false;
}

</script>

<template>
    <div>
        <div class="translation-header-wrapper">
            <br>
            <h1>{{ state.Title }}</h1>
            <p>{{ state.Description}}</p>
            <br>
        </div>
        <br>

        <div v-if="state.translationAttachments && state.translationAttachments.length" class="translate-attachment-wrapper">
            <AttachmentList ref="groupsRef" v-model="state.translationAttachments"></AttachmentList>
        </div>

        <Groups ref="groupsRef" v-model="state.translationItemGroups" :languageID="languageID"></Groups>

        <div class="text-right pt-5 pb-5 translate-finished-wrapper">
            <Button size="small" @click="showCommentModal = true" variant="red" class="translation-finished-button">Přidat komentář k překladu</Button>

            <Button size="small" @click="submitData" variant="green" class="translation-finished-button">Označit tyto překlady jako dokončené</Button>
        </div>

        <Modal :isVisible="showCommentModal" @update:isVisible="close">
            <template #header>
                Přidat komentář k překladu
            </template>
            <template #body>
                <TextArea label="Komentář k překladu" class="modal-text-area" v-model="commentText"/>
                <p>dfdsfsdfsd</p>
            </template>
            <template #footer>
                <Button size="small" variant="green" @click="commentTranslation">Uložit</Button>
                <Button size="small" variant="red" @click="close">Zavřít</Button>
            </template>
        </Modal>



    </div>
</template>

<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.comment-modal {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width: 400px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

textarea {
    width: 100%;
    height: 100px;
    margin-bottom: 10px;
}

.submit-button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 15px;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 3px;
}

.close-button {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 3px;
}

.translation-finished-button {
    margin: 20px;
}

.modal-text-area {
    width: 80%;
    textarea {
        width: 80% !important;
    }
}
</style>
