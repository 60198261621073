<script setup>

import Modal from "@/Components/Modal/Modal.vue";
import Button from "@/Components/Inputs/Button.vue";
import LabelEditor from "@project/Excel/ExcelApp/Components/LabelEditor/LabelEditor.vue";
import { reactive, ref, watch } from "vue";
import { cloneDeep } from "lodash";

const props = defineProps({
    label: [Object, null],
    show: Boolean
});

const emit = defineEmits(['save', 'close']);

const show = ref(props.show);

const save = () => {
    emit('save');
    close();
}

const close = () => {
    show.value = false;
    emit('close');
}

watch(() => props.show, (val) => {
    show.value = props.show;
});
</script>

<template>
    <Modal :isVisible="show" @update:isVisible="close">
        <template #header>
            <div v-if="!label || (label && !label.ID)">Vytvoření štítku</div>
            <div v-else>Úprava štítku</div>
        </template>
        <template #body>
            <LabelEditor :label="label" @save="save"></LabelEditor>
        </template>
        <template #footer>
        </template>
    </Modal>
</template>

<style scoped>

</style>