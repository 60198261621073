<script setup>
import {onMounted, onUnmounted, ref, watch} from "vue";
import moment from "moment";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

const props = defineProps({
    modelValue: {
        type: [String, Object],
        default: ''
    },
    returnObject: {
        type: Boolean,
        default: false
    },
    returnFormat: {
        type: String,
        default: 'YYYY-MM-DD'
    },
    inputFormat: {
        type: String,
        default: 'YYYY-MM-DD'
    },
    range: {
        type: Boolean,
        default: false
    },
    label: String,
    type: {
        type: String,
        default: 'date' // date, time, datetime
    },
    font: {
        type: String,
        default: 'Inter'
    },
});

function getDateObject(date){
    if(!date){
        return null;
    }

    if(typeof date === 'object'){
        return date;
    }else{
        return moment(date, props.inputFormat).toDate(); // Parsování řetězce na objekt Date pomocí moment.js
    }
}

function initObject(obj){
    if(!props.range){
        return getDateObject(obj);
    }else{
        let newObj = {
            from: null,
            to: null,
        };

        if(obj){
            Object.entries(obj).forEach(([key, value]) => {
                newObj[key] = getDateObject(value);
            });

            return newObj;
        }else{
            return newObj;
        }
    }
}

const emit = defineEmits(['update:modelValue']);

const selectedDate = ref(initObject(props.modelValue));

watch(props.modelValue, (newValue) => {
    selectedDate.value = initObject(newValue);
});

watch(() => selectedDate.value, (newValue) => {
    if(props.returnObject){
        emit('update:modelValue', newValue);
    }else{
        let formattedDate = null;


        if(props.range){
            formattedDate = {
                from: moment(newValue.from).format(props.returnFormat),
                to: moment(newValue.to).format(props.returnFormat),
            }
        }else{
            formattedDate = moment(newValue).format(props.returnFormat);
        }

        emit('update:modelValue', formattedDate);
    }
});

const format = (date) => {
    let format = 'DD.MM.YYYY HH:mm:ss';

    if(props.type === 'date'){
        format = 'DD.MM.YYYY';
    }else if(props.type === 'time') {
        format = 'HH:mm:ss';
    }else if(props.type === 'datetime') {
        format = 'DD.MM.YYYY HH:mm:ss';
    }


    return moment(date).format(format);
}
</script>

<template>
    <div>
        <div v-if="!props.range" class="date-time-wrapper">
            <label
                v-if="typeof props.label !== 'undefined'"
            >
                {{ label }}
            </label>
            <div class="input-wrapper">
                <VueDatePicker v-model="selectedDate" locale="cs" cancelText="zrušit" selectText="vybrat" :teleport="true" :enable-time-picker="props.type === 'datetime' || props.type === 'time'" :format="format"
                   v-bind:time-picker="props.type === 'time' ? true : null"
                />
            </div>
        </div>
        <div v-else class="date-time-wrapper">
            <label v-if="typeof props.label !== 'undefined'">{{ label }}</label>
            <div class="date-range-wrapper">
                <div class="input-wrapper date-from">
                    <VueDatePicker v-model="selectedDate.from" locale="cs" cancelText="zrušit" selectText="vybrat" :teleport="true" :enable-time-picker="props.type === 'datetime' || props.type === 'time'" :format="format"
                       v-bind:time-picker="props.type === 'time' ? true : null"
                    />
                </div>
                <div class="separator">-</div>
                <div class="input-wrapper date-to">
                    <VueDatePicker v-model="selectedDate.to"  locale="cs" cancelText="zrušit" selectText="vybrat" :teleport="true" :enable-time-picker="props.type === 'datetime' || props.type === 'time'" :format="format"
                       v-bind:time-picker="props.type === 'time' ? true : null"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    input{
        border-radius: 6px !important;
        border: 1px solid #CDD8ED !important;
        background: #FFF !important;
        font-size: 14px !important;
        color: #091219 !important;

        &:focus{
            outline: none !important;
        }
    }

    label{
        font-family: v-bind(font);
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 8px;
    }

    .separator{
        font-size: 16px;
        font-weight: 600;
    }

    .date-time-wrapper{
        .date-range-wrapper{
            display: flex;
            align-items: center;

            .input-wrapper{
                &.date-from{
                    margin-right: 13px;
                }

                &.date-to{
                    margin-left: 13px;
                }
            }
        }

        .input-wrapper{
            position: relative;

            .icon-wrapper{
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                display: flex;
                align-items: center;
                padding-left: 14px;
                pointer-events: none;

                .icon{
                    width: 16px;
                    height: 16px;
                    color: #506069;
                }
            }

            input{
                min-height: 40px;
                padding-left: 40px;
                padding-right: 10px;
            }
        }
    }
</style>
