import Handsontable from "handsontable";
import {CurrencyEditor} from "@project/Excel/Handsontable/js/ColumnTypes/Currency/CurrencyEditor";

export class CurrencyType {
    public editor: any;
    public renderer: any;
    public validator: any;
    public className: string;

    static CURRENCY_CZK = 'CZK';
    static CURRENCY_EUR = 'EUR';

    static CURRENCY_CZK_SYMBOL = 'Kč';
    static CURRENCY_EUR_SYMBOL = '€';

    constructor() {
        this.editor = CurrencyEditor;
        this.renderer = this.customRenderer;
        this.validator = this.customValidator;
        this.className = 'currency-cell';
    }

    customRenderer(instance, td, row, column, prop, value, cellProperties) {
        //@ts-ignore
        // Handsontable.renderers.TextRenderer.apply(this, arguments);

        if(typeof value !== 'undefined' && value !== null && value !== '' && cellProperties.currency){
            // td.innerHTML = (value/100) + ' ' + CurrencyType.getCurrencySymbol(cellProperties.currency);
            td.innerHTML = (value) + ' ' + CurrencyType.getCurrencySymbol(cellProperties.currency);
        }else{
            if(typeof value === 'undefined'){
                td.innerHTML = '';
            }else{
                td.innerHTML = value;
            }
        }
    }

    customValidator(query, callback){
        callback(true);
    }

    static getCurrencySymbol(currency: string){
        switch(currency){
            case CurrencyType.CURRENCY_CZK:
                return CurrencyType.CURRENCY_CZK_SYMBOL;
            case CurrencyType.CURRENCY_EUR:
                return CurrencyType.CURRENCY_EUR_SYMBOL;
            default:
                return '';
        }
    }

}