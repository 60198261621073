import type { ISheetDefinition } from "@project/Excel/Handsontable/js/SheetDefinition";
import type {IModelDefinition, LoadData} from "@project/Excel/Handsontable/js/ModelDefinition";
import type { ISheetSettings, SheetSettingsState } from "@project/Excel/Handsontable/js/SheetSettings";

import { SheetSettings } from "@project/Excel/Handsontable/js/SheetSettings";

import type { ISheetDefinitionColumn } from "@project/Excel/Handsontable/js/SheetDefinitionColumn";
import type { IFilter } from "@/Filter/js/Filter";
import { Type, Expose, plainToInstance, Exclude } from "class-transformer";
import { ModelDefinition } from "@project/Excel/Handsontable/js/ModelDefinition";
import "reflect-metadata";
import { SheetDefinition } from "@project/Excel/Handsontable/js/SheetDefinition";
import {ModelDefinitionJoin} from "@project/Excel/Handsontable/js/ModelDefinitionJoin";
import axios from "axios";
import {useAlertStore} from "@/Components/Alerts/stores/alertStore.js";
import {useAjax} from "@/js/Ajax/useAjax.js";
import {TransformWithConstructor} from "./ClassTransformer/CustomTransformer.js"

export interface ISheet extends SheetState {
    loadSettings(): Promise<LoadSettings | undefined>;
    loadData(modelDefinition: IModelDefinition | null): Promise<LoadData | undefined>;
    prepareDataForHS(data: LoadData): { [key: string]: any }[];
    prepareData(data: LoadData): { [key: string]: any }[];
    findRowsBy(by: string, value: string): { [key: string]: any }[];
    findRowsIndexesBy(by: string, value: string): (number | null)[];
    // getModelForColumn(column: ISheetDefinitionColumn): IModelDefinition | null;
    getDataForSheetColumns(): [][];
}

export interface SheetState {
    id: number | null;
    date: Date | null;
    data: { [key: string]: any }[];
    dataUrl?: string | null;
    name: string;
    // sheetDefinition?: ISheetDefinition | null;
    // modelDefinition?: IModelDefinition | null;
    type: string;

    settings: ISheetSettings | null;
}

export class Sheet implements ISheet {
    @Exclude()
    public state: SheetState = {};

    @Exclude()
    public ajax: any;

    @Exclude()
    public alerts: any;

    @Exclude()
    public dataDirty: any;
    //
    // @Type(() => SheetDefinition)
    // public sheetDefinition?: ISheetDefinition | null;
    //
    // @Type(() => ModelDefinition)
    // public modelDefinition?: IModelDefinition | null;

    constructor(sheetState: SheetState | null = null) {
        Object.assign(
            this,
            sheetState ?? {
                name: "",
                id: null,
                date: null,
                data: [{}],
                dataUrl: null,
                settings: null,
                // sheetDefinition: new SheetDefinition(),
                // modelDefinition: null,
            },
        );

        this.name = sheetState?.name ?? '';
        this.type = sheetState?.type ?? 'default';
        this.id = sheetState?.id ?? null;
        this.date = new Date(sheetState?.date) ?? null;

        this.data = sheetState?.data ?? [{}];
        this.dataUrl = sheetState?.dataUrl ?? null;

        this.alerts = window.pinia ? useAlertStore(window.pinia) : null;
        this.ajax = useAjax();
        this.dataDirty = false;
        // this.sheetDefinition = sheetState?.sheetDefinition ?? new SheetDefinition();
        // this.modelDefinition = sheetState?.modelDefinition ?? null;
    }

    @Expose({name: 'Name'})
    get name(){
        return this.state.name;
    }
    set name(name){
        this.state.name = name;
    }

    @Expose({name: 'settings'})
    get settings(){
        return this.state.settings;
    }
    set settings(settings){
        this.state.settings = settings ? (settings instanceof SheetSettings ? settings : new SheetSettings(settings)) : null;
    }

    @Expose({name: 'ID'})
    get id(){
        return this.state.id;
    }

    set id(id){
        this.state.id = id;
    }

    @Expose({name: 'Date'})
    get date(){
        return this.state.date;
    }
    set date(date){
        this.state.date = date;
    }

    @Expose({name: 'Type'})
    get type(){
        return this.state.type;
    }
    set type(type){
        this.state.type = type;
    }

    @Expose()
    get data(){
        return this.state.data;
    }
    set data(data){
        this.state.data = data;
    }

    @Expose()
    get dataUrl(){
        return this.state.dataUrl;
    }
    set dataUrl(dataUrl){
        this.state.dataUrl = dataUrl;
    }

    public async loadSettings() {
        // debugger
        if(!this.settings?.id) {
            this.settings = null;
            let error = false;
            // TODO: možná rework nevypadá to jako správný způsob
            const response = await axios.get(window.remoteUrl + '/admin/sheet/data-list?settings=true&id=' + this.id, {
            }).catch(function (e) {
                error = e;
            });

            if(error){
                if(this.alerts){
                    this.alerts.error('Chyba při načtení nastavení listu', error.message ? error.message : '', error);
                }

                return false;
            }
            this.settings = plainToInstance(SheetSettings, JSON.parse(response.data.items[0].SheetSettings));
        }
    }

    public async loadData(modelDefinition: IModelDefinition | null = null, dataFilter: IFilter | null = null): Promise<LoadData | undefined> {
        if (!this.settings.modelDefinition) return;

        if (!modelDefinition) return await this.settings.modelDefinition.loadDataFromURL(dataFilter);
        else return await modelDefinition.loadDataFromURL(dataFilter);

    }

    public prepareData(data: LoadData): { [key: string]: any }[] {
        let preparedData: {[key: string]: any}[] = [];
        preparedData = data.items as {[key: string]: any}[];

        const resolveJoins = (join: any, parentData: any) => {
            let currentChain = join.modelDefinition.joinChain ? join.modelDefinition.joinChain + "." : join.modelDefinition.joinChain;

            let chainBefore = join.modelDefinition.joinChain.slice(0, join.modelDefinition.joinChain.lastIndexOf(".") !== -1 ? join.modelDefinition.joinChain.lastIndexOf(".") : 0);
            chainBefore = chainBefore ? chainBefore + "." : "";

            let joinRow = join.items.find((joinItem: any) => joinItem[join.join.foreignKey] === parentData[chainBefore + join.join.localKey]);

            if (joinRow) {
                joinRow = Object.keys(joinRow).reduce((newObj: {[key: string]: any}, key) => {
                    const newKey = currentChain + key; // Přidání prefixu k názvu vlastnosti
                    newObj[newKey] = joinRow[key]; // Přiřazení původní hodnoty k novému klíči
                    return newObj;
                }, {});
                if (join.joins) {
                    join.joins.forEach((subJoin: any) => {
                        if(joinRow === false){
                            return;
                        }

                        const joinData = resolveJoins(subJoin, joinRow);

                        if(join.join.joinType === ModelDefinitionJoin.JOIN_TYPE_INNER){
                            if(joinData === false) {
                                joinRow = false;
                                return;
                            }else{
                                joinRow = { ...joinRow, ...joinData };
                            }
                        }else{
                            joinRow = { ...joinRow, ...joinData };
                        }


                    });
                }

                return joinRow;
            }

            if(join.join.joinType === ModelDefinitionJoin.JOIN_TYPE_INNER){
                return false;
            }else{
                return {};
            }
        };

        let resolvedData: any[] = [];
        if (data.joins && data.joins.length) {
            preparedData.forEach((row, index) => {
                let filteredInnerJoin = false;
                let resolvedRow = {...preparedData[index]};

                data.joins.forEach((join: any) => {
                    if(filteredInnerJoin){
                        return;
                    }

                    const joinData = resolveJoins(join, preparedData[index]);

                    if(join.join.joinType === ModelDefinitionJoin.JOIN_TYPE_INNER){
                        if(joinData === false){
                            filteredInnerJoin = true;
                            return;
                        }else{
                            resolvedRow = { ...resolvedRow, ...joinData };
                        }
                    }else{
                        resolvedRow = { ...resolvedRow, ...joinData };
                    }


                });

                if(!filteredInnerJoin){
                    resolvedData.push(resolvedRow);
                }
            });
        }else{
            resolvedData = preparedData;
        }

        return resolvedData;
    }

    public prepareDataForHS(data: LoadData): { [key: string]: any }[] {
        if (!this.settings.sheetDefinition || !this.settings.modelDefinition) {
            return [{}];
        }

        let preparedData: { [key: string]: any }[] = this.prepareData(data);

        let finalData: { [key: string]: any }[] = [];
        if (this.settings.sheetDefinition.columns) {
            preparedData.forEach((preparedDataRow, index) => {
                let row: any[] = [];
                this.settings.sheetDefinition?.columns?.forEach((column: ISheetDefinitionColumn, c: number) => {
                    let value = preparedDataRow[column.data] ?? column.defaultValue ?? undefined;

                    // if (typeof value === "undefined"){
                    //     if(column.defaultValue){
                    //         value = column.defaultValue;
                    //     }
                    // }

                    row.push(value);
                });

                finalData.push(row);
            });
        }

        return finalData;
    }

    public findRowsBy(by: string, value: string) {
        let row = this.data?.filter((row: any) => row[by] === value);
        return row;
    }

    public findRowsIndexesBy(by: string, value: string): (number | null)[] {
        let indexes = this.data?.map((row: any, index) => (row[by] === value ? index : null)).filter((index) => index !== null);
        return indexes;
    }

    // getModelForColumn(column: ISheetDefinitionColumn): IModelDefinition | null {
    //     if (!this.modelDefinition) {
    //         return null;
    //     }
    //
    //     let model = this.modelDefinition.getModelForColumn(column.model ?? "");
    //
    //     return model;
    // }

    getDataForSheetColumns(): [][] {
        if (!this.settings.sheetDefinition || !this.data) {
            return [[]];
        }

        let filteredData: [][] = [];
        this.data?.forEach((row: any, index) => {
            let newRow: any[] = [];
            this.settings.sheetDefinition?.columns?.forEach((column: ISheetDefinitionColumn, c: number) => {
                newRow.push(row[column.joinData as string] ?? undefined);
            });
            filteredData.push(newRow as []);
        });

        return filteredData;
    }

    async loadDataFromServer(){
        //url
        const url = window.remoteUrl;
        //url

        const data= {
            Sheet_ID: this.id,
            sort: '-DateTime',
            count: 1,
        };

        let sheetData;
        await this.ajax.get(url + '/admin/sheet-data/data-list', {
            params: data,
        },{
            waitingAlert: {
                title: 'Načítam historické data',
            }
        }).then(({response, alert}) => {
            if(response.data && typeof response.data.items !== 'undefined' && response.data.items.length){
                console.log(JSON.parse(response.data.items[0].Data));
                sheetData = JSON.parse(response.data.items[0].Data);
                alert.changeToSuccess('Data načteny');
            }else{
                alert.changeToError('Chyba při načítání dat');
                return;
            }
        });

        return sheetData;
    }
}
