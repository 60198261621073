import axios from 'axios';
import {useAlertStore} from "../../Components/Alerts/stores/alertStore";

export function useAjax(){
    const alerts = window.pinia ? useAlertStore(window.pinia) : null;

    async function request(method, url, data = null, config = null, options = null) {
        let hasError = false;

        let {alert} = _onBeforeRequest(options);

        let props = { method, url, data, ...config };

        if(method === 'get'){
            props = { method, url, ...config };
        }

        return axios(props)
            .then(response => {
                handleSuccess(response); // Vlastní logika úspěchu
                return { response, alert };
            })
            .catch(error => {
                hasError = true;
                handleError(error); // Vlastní logika chyby
                throw { error, alert }; // Přehodíme chybu dál, aby ji mohli zachytit volající
            })
            .finally(() => {
                _onFinally(alert, hasError);
            });
    }

    async function get(url, config = null, options = null) {
        return request('get', url, null, config, options);
    }

    async function post(url, data = null, config = null, options = null) {
        return request('post', url, data, config, options);
    }

    async function put(url, data = null, config = null, options = null) {
        return request('put', url, data, config, options);
    }

    async function patch(url, data = null, config = null, options = null) {
        return request('patch', url, data, config, options);
    }

    async function postForm(url, data = null, config = null, options = null) {
        const conf = {
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
            },
            ...config,
        }

        return request('postForm', url, data, conf, options);
    }

    async function putForm(url, data = null, config = null, options = null) {
        const conf = {
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
            },
            ...config,
        }

        return request('putForm', url, data, conf, options);
    }

    async function patchForm(url, data = null, config = null, options = null) {
        const conf = {
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
            },
            ...config,
        }

        return request('patchForm', url, data, conf, options);
    }

    function handleSuccess(response) {

    }

    function handleError(error) {

    }

    function _onFinally(alert, hasError){
        if(alert && alert.type.value === 'waiting'){
            if(hasError){
                alert.changeToError();
            }else{
                alert.changeToSuccess();
            }
        }
    }

    function _onBeforeRequest(options){
        let alert = null;
        if(options){
            if(options.waitingAlert && alerts){
                if(typeof options.waitingAlert === 'object'){
                    const {title, message, data, duration} = options.waitingAlert;
                    alert = alerts.waiting(title, message, data, duration);
                }else{
                    alert = alerts.waiting("Čekám na odpověd");
                }
            }
        }

        return {alert};
    }

    return {
        request,
        get,
        post,
        put,
        patch,
        postForm,
        putForm,
        patchForm,
    }
}