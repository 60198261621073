<script setup>
import { ref, watch, computed } from 'vue';

const props = defineProps({
    modelValue: String, // Hodnota použitá ve v-model z rodičovské komponenty
    label: String,
    id: String,
    placeholder: String,
    required: Boolean,
    size: {
        type: String,
        default: 'default' // možnosti jsou 'small', 'default', 'large'
    },
    rows: {
        type: Number,
        default: 4 // Defaultní počet řádků
    }
});

const emit = defineEmits(['update:modelValue']); // Pro aktualizaci hodnoty
const inputValue = ref(props.modelValue); // Inicializace s modelValue

const inputEl = ref(null); // Reference na input element (pro focus, blur, atd.

// Sleduje změny v props.modelValue pro aktualizaci inputValue, pokud se hodnota změní zvenčí
watch(() => props.modelValue, (newVal) => {
    inputValue.value = newVal;
});

// Aktualizuje modelValue při změně hodnoty v textovém poli
const updateValue = (value) => {
    emit('update:modelValue', value);
};

</script>

<template>
    <div
        class="input-holder"
        :class="{
            ['size-'+props.size]: true,
        }"
    >
        <label
            v-if="label"
            :for="id"
            @click="inputEl.focus()"
        >
            {{ label }}
        </label>
        <textarea ref="inputEl" :id="id" :placeholder="placeholder" :required="required" :rows="rows" :value="inputValue" @input="updateValue($event.target.value)"></textarea>
    </div>
</template>

<style scoped>
    label{
        display: block;
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 8px;
    }

    textarea{
        border: 1px solid #CDD8ED;
        border-radius: 6px;
        padding: 10px;
        font-size: 14px;
        font-weight: 400;
        font-family: Inter;

        &:focus{
            border: 1px solid #CDD8ED;
            box-shadow: 0 0 0 1px #CDD8ED;
            outline: none;
        }
    }

    .input-holder{
        &.size-large{
            textarea{
                font-size: 16px;
            }
        }
    }
</style>