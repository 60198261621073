import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useLayoutStore = defineStore('layout', () => {
    const defaultOnBack = () => {
        console.log('Default back action');
    };

    const onBack = ref(defaultOnBack)
    const showLogo = ref(true);

    const setOnBack = (callback) => {
        onBack.value = callback;
    };

    const resetOnBack = () => {
        onBack.value = defaultOnBack;
    };

    const executeOnBack = () => {
        if (onBack.value) {
            onBack.value();
        }
    };

    const setShowLogo = (value) => {
        showLogo.value = value;
    };

    return {
        onBack,
        setOnBack,
        executeOnBack,
        resetOnBack,
        showLogo,
        setShowLogo
    };
});