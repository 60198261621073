<script setup>
import {ref, watch} from "vue";

const props = defineProps({
    modelValue: String,
    number: Boolean,
})

const emit = defineEmits(['update:modelValue', 'keyup.enter']);

const value = ref(props.modelValue);

const updateValue = (event) => {
    emit('update:modelValue', value.value);
}

watch(() => props.modelValue, (newValue) => {
    value.value = newValue;
})
</script>

<template>
    <div class="text-editor">
        <input v-model="value" @input="updateValue" @keyup.enter="emit('keyup.enter')" :type="props.number ? 'number' : 'text'">
    </div>
</template>

<style scoped>

</style>