<script setup>
import {ref, watch} from 'vue';
import Icon from "@/Icons/Icon.vue";
import CloseIcon from "@/Icons/CloseIcon.vue";

    const props = defineProps({
        modelValue: String|Number, // Přijímá hodnotu použitou v v-model z rodičovské komponenty
        label: String,
        id: String,
        type: String,
        placeholder: String,
        required: Boolean,
        font: {
            type: String,
            default: 'Inter'
        },
        size: {
            type: String,
            default: 'default' // možnosti jsou 'small', 'default', 'large'
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        showClear: {
            type: Boolean,
            default: false,
        },
        variant: {
            type: String,
            default: 'default'
        },
        icon: {
            type: String,
        },
        inputRef: Object,
    });

    const emit = defineEmits(['update:modelValue', 'set-input-ref']); // Pro aktualizaci hodnoty

    const inputValue = ref(props.modelValue); // Inicializace s modelValue
    const inputEl = ref(null); // Reference na input element (pro focus, blur, atd.

    // Sleduje změny v props.modelValue pro aktualizaci inputValue, pokud se hodnota změní zvenčí
    watch(() => props.modelValue, (newVal) => {
        inputValue.value = newVal;
    });

    // Aktualizuje modelValue při změně hodnoty vstupu
    const updateValue = (value) => {
        emit('update:modelValue', value);
    };

    watch(() => inputEl.value, (newVal) => {
        emit('set-input-ref', newVal);
    });
</script>

<template>
    <div :class="{
            [props.variant]: true,
            'input-holder': true,
            ['size-'+props.size]: true,
        }
    ">
        <label
            v-if="typeof props.label !== 'undefined'"
            :for="id"
            @click="inputEl.focus()"
        >
            {{ label }}
        </label>
        <div
            class="input-wrapper"
            :class="{'with-icon': props.icon, 'read-only': props.readonly}"
        >
            <Icon v-if="props.icon" :icon="props.icon" :width="15" :height="15" color="black" class="icon"></Icon>
            <input
                :type="type"
                :id="id"
                :placeholder="placeholder"
                :required="required"
                :value="inputValue"
                @input="updateValue($event.target.value)"
                :readonly="readonly"
                ref="inputEl"
                class="vue-input"
            />
            <CloseIcon
                v-if="showClear && inputValue"
                @click="inputValue = null;updateValue(null);"
                class="clear-icon"
                color="#888"
                :width="15"
                :height="15"
            />
        </div>
    </div>
</template>

<style scoped>
    .vue-input{
        border-radius: 6px;
        border: 1px solid #CDD8ED;
        min-height: 36px;
        padding: 0px 10px 0px 10px;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        font-family: v-bind(font);
        width: 100%;
        box-sizing: border-box;

        &:focus{
            border: 1px solid #CDD8ED;
            box-shadow: 0 0 0 1px #CDD8ED;
            outline: none;
        }
    }

    label{
        font-family: v-bind(font);
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 8px;
    }

    .input-holder{
        display: flex;
        flex-direction: column;

        &.fancy{
            input{
                border-radius: 25px;
                background: #ECF2FF;
                border: none;
                padding-left: 20px;

                &:focus{
                    box-shadow: 0 0 0 1px #ECF2FF;
                }
            }

        }

        .input-wrapper{
            position: relative;

            &.read-only{
                background: #CDD8ED;
            }

            &.with-icon{
                .icon{
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 12px;
                }

                input{
                    padding-left: 35px;
                }
            }

            .clear-icon {
                position: absolute;
                right: 12px;
                top: 50%;
                transform: translateY(-50%);
                cursor: pointer;
            }
        }

        &.size-large{
            input{
                min-height: 43px;
                font-size: 16px;
            }

            label{
                font-size: 14px;
            }
        }

        &.size-large-xl{
            input{
                min-height: 50px;
                font-size: 16px;
            }

            label{
                font-size: 16px;
            }
        }
    }


</style>
