import type { ColumnState, IColumn } from "@project/Excel/Handsontable/js/Column";
import { Column } from "@project/Excel/Handsontable/js/Column";

export interface ISheetDefinitionColumn extends _T<SheetDefinitionColumnState> {}

type _T<T extends ColumnState> = T & IColumn<T>;
export interface SheetDefinitionColumnState extends ColumnState {
    model: string | null;
    position?: number | null;
    formating?: string | null;
    alias?: string | null;
    afterLoad?: string | null;
    columnMeta?: {[key: string]: any} | null;
    width?: number | null;
    className?: string | null;
}

export class SheetDefinitionColumn extends Column<SheetDefinitionColumnState> implements ISheetDefinitionColumn {
    public model: string | null = null;
    public formating?: string | null;
    public position: number | null = null;
    public alias?: string | null;
    public afterLoad?: string | null;
    public columnMeta?: {[key: string]: any} | null;
    public width?: number | null;
    public className?: string | null;

    constructor(sheetDefinitionColumnState: SheetDefinitionColumnState | null = null) {
        super(
            sheetDefinitionColumnState ?? {
                data: "",
                title: "",
                type: "text",
                defaultValue: null,
                joinData: "",
                model: null,
            },
        );

        Object.assign(
            this,
            sheetDefinitionColumnState ?? {
                data: "",
                title: "",
                type: "text",
                defaultValue: null,
                model: null,
                width: 200,
            },
        );


        this.width = sheetDefinitionColumnState?.width ?? 200;
        this.className = sheetDefinitionColumnState?.className ?? null;
        if(!sheetDefinitionColumnState?.alias){
            // this.alias = this.joinData ? this.joinData : this.data;
        }else{
            this.alias = sheetDefinitionColumnState.alias;
        }

        if(sheetDefinitionColumnState?.columnMeta){
            this.columnMeta = sheetDefinitionColumnState.columnMeta;
        }
    }

    getColumnTableIndex() {
        let index = this.position;
        let columnName = '';
        while (index >= 0) {
            columnName = String.fromCharCode((index % 26) + 65) + columnName;
            index = Math.floor(index / 26) - 1;
        }
        return columnName;
    }
}
