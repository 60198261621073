<script setup>
import {FORMULAS} from "@project/Excel/Handsontable/js/Formulas/formulas";
import {onMounted, ref, computed} from "vue";
import Button from "@/Components/Inputs/Button.vue";
import Modal from "@/Components/Modal/Modal.vue";
import Table from "@/Components/Table/Table.vue";
import TheadTh from "@/Components/Table/TheadTh.vue";
import Tbody from "@/Components/Table/Tbody.vue";
import TbodyTr from "@/Components/Table/TbodyTr.vue";
import Thead from "@/Components/Table/Thead.vue";
import TbodyTd from "@/Components/Table/TbodyTd.vue";
import TheadTr from "@/Components/Table/TheadTr.vue";
import InputField from "@/Components/Inputs/InputField.vue";
import HighlightText from "@/Components/Other/HighlightText.vue";

const props = defineProps({
    show: Boolean,
});

const emit = defineEmits(['close']);

const show = ref(props.show);

const close = () => {
    show.value = false;
    emit('close');
}

const functionsList = ref({});
const searchQuery = ref('');  // Search query for filtering

const loadFunctions = () => {
    functionsList.value = FORMULAS;
};

// Computed property to filter functions based on the search query
const filteredFunctionsList = computed(() => {
    if (!searchQuery.value) {
        return functionsList.value;
    }

    const filtered = {};

    Object.keys(functionsList.value).forEach(category => {
        const filteredFunctions = Object.keys(functionsList.value[category])
            .filter(functionName => functionName.toLowerCase().includes(searchQuery.value.toLowerCase()))
            .reduce((acc, functionName) => {
                acc[functionName] = functionsList.value[category][functionName];
                return acc;
            }, {});

        if (Object.keys(filteredFunctions).length > 0) {
            filtered[category] = filteredFunctions;
        }
    });

    return filtered;
});

onMounted(() => {
    loadFunctions();
});

</script>

<template>
    <Modal :isVisible="show" @update:isVisible="close">
        <template #header>
            Vzorce - manuál
        </template>
        <template #body>
            <!-- Search Input -->
            <div class="tw-mb-4">
                <InputField
                    v-model="searchQuery"
                    placeholder="Hledat podle názvu funkce..."
                    variant="fancy"
                    icon="search"
                />
            </div>
            <!-- Filtered Functions Display -->
            <div v-for="(functions, category) in filteredFunctionsList" :key="category">
                <!-- Display category title if there are functions to display -->
                <h3>{{ category }}</h3>
                <!-- Display table for each category -->
                <Table class="formula-table tw-mb-8">
                    <Thead>
                    <TheadTr>
                        <TheadTh>Název funkce</TheadTh>
                        <TheadTh>Popis</TheadTh>
                        <TheadTh>Syntax</TheadTh>
                    </TheadTr>
                    </Thead>
                    <Tbody>
                    <TbodyTr v-for="(details, functionName) in functions" :key="functionName">
                        <TbodyTd><HighlightText :highlight="searchQuery" :text=" functionName"></HighlightText></TbodyTd>
                        <TbodyTd>{{ details.description }}</TbodyTd>
                        <TbodyTd>{{ details.syntax }}</TbodyTd>
                    </TbodyTr>
                    </Tbody>
                </Table>
            </div>
            <!-- Show message if no results found -->
            <div v-if="Object.keys(filteredFunctionsList).length === 0">
                <p>Žádné výsledky.</p>
            </div>
        </template>
        <template #footer>
            <Button variant="red" @click="close">Zavřít</Button>
        </template>
    </Modal>
</template>

<style scoped>
h3 {
    font-size: 16px !important;
    font-weight: bold;
    margin-bottom: 10px !important;
}
input {
    padding: 8px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
}
</style>
