<script setup>
import {ref, computed, onMounted, watch, onUnmounted, nextTick} from "vue";
import axios from "axios";
import InputField from "./InputField.vue";
import ArrowIcon from "@/Icons/ArrowIcon.vue";
import CloseIcon from "@/Icons/CloseIcon.vue";

const props = defineProps({
    options: Array,
    modelValue: [String, Object, Array],
    placeholder: String,
    label: String,
    filterable: Boolean,
    url: String, // URL adresa pro načítání dat
    pageSize: {
        type: Number,
        default: 20, // Počet položek na stránku
    },
    required: {
        type: Boolean,
        default: false,
    },
    multiple: {
        type: Boolean,
        default: false, // Multiselect
    },
    returnObject: {
        type: Boolean,
        default: false, // Vracet objekt místo hodnoty
    },
    remoteName: {
        type: String,
        default: 'Name'
    },
    textFromValue: {
        type: Boolean,
        default: false
    },
    textProperty: {
        type: String,
        default: 'text'
    },
    remoteIdentifier: {
        type: String,
        default: 'ID'
    },
    remoteValueObject: {
        type: Boolean,
        default: false,
    },
    size: {
        type: String,
        default: 'default' // možnosti jsou 'small', 'default', 'large'
    },
    readonly: {
        type: Boolean,
        default: false,
    },
    font: {
        type: String,
        default: 'Inter'
    },
});

const options = ref(props.options ?? []);
const getOptionByValue = (value) => {
    return options.value.find(option => option.value === value);
}

const emit = defineEmits(['update:modelValue']);
const isOpen = ref(false);
const filterText = ref('');
const selectedValue = ref(
    typeof props.modelValue === 'object' ? ( // Object/Array
        Array.isArray(props.modelValue) ? ( // Array
            !props.textFromValue ? props.modelValue : props.modelValue.map(item => {
                return {
                    value: item.value,
                    text: props.remoteName ? item.value[props.remoteName] ?? null : item.value[props.textProperty] ?? null
                }
            })
        ) : ( // Object
            !props.textFromValue ? props.modelValue : {
                value: props.modelValue,
                text: props.remoteName ? props.modelValue[props.remoteName] ?? null : props.modelValue[props.textProperty] ?? null
            }
        )
    ) : ( // String
        getOptionByValue(props.modelValue) ?? props.modelValue
    )
);

const filteredOptions = computed(() => {
    return filterText.value
        ? options.value.filter(option =>
            option.text.toLowerCase().includes(filterText.value.toLowerCase())
        )
        : options.value;
});

let timeoutId = null;

const selectOption = (option) => {
    if(props.multiple) {
        if(isOptionSelected(option)) {
            const index = selectedValue.value.findIndex(item => item.value === option.value);
            if (index !== -1)
                selectedValue.value.splice(index, 1);
        } else {
            selectedValue.value.push(option);
        }

        if (props.returnObject) {
            emit('update:modelValue', selectedValue.value);
        } else {
            emit('update:modelValue', selectedValue.value.map(item => item.value));
        }
    } else {
        if(isOptionSelected(option)) {
            selectedValue.value = null;
        } else {
            selectedValue.value = option;
        }

        if (props.returnObject) {
            emit('update:modelValue', {...option});
        } else {
            emit('update:modelValue', option.value);
        }
    }

    isOpen.value = false;
};

const clearSelectedValue = () => {
    if(props.multiple)
        selectedValue.value = [];
    else selectedValue.value = null;

    emit('update:modelValue', selectedValue.value);
}

const isOptionSelected = (option) => {
    if(props.multiple) {
        if(selectedValue.value?.filter(selected => selected.value === option.value).length > 0)
            return true;
    } else {
        if(selectedValue.value?.value === option.value) {
            return true;
        }
    }

    return false;
}

const toggleDropdown = (e) => {
    setTimeout(() => {
        if(!props.readonly){
            isOpen.value = !isOpen.value;
            nextTick(() => {
                if (isOpen.value) {
                    updateDropdownPosition();
                    requestAnimationFrame(() => {
                        if (searchInputEl.value && props.filterable) {
                            searchInputEl.value.focus();
                        }
                    });
                }
            });
        }
    },100);
};

// Zavření dropdownu kliknutím mimo komponentu
const closeDropdown = (event) => {
    if (scrollContainer.value && !scrollContainer.value.contains(event.target) && dropdownTriggerEl.value && !dropdownTriggerEl.value.contains(event.target)) {
        isOpen.value = false;
        filterText.value = '';
    }
};

onMounted(() => {
    document.addEventListener('click', closeDropdown);
    window.addEventListener('scroll', updateDropdownPosition, true);
    window.addEventListener('resize', updateDropdownPosition, true);
});
onUnmounted(() => {
    document.removeEventListener('click', closeDropdown);
    if (scrollContainer.value) {
        scrollContainer.value.removeEventListener('scroll', onScroll);
    }
    window.addEventListener('scroll', updateDropdownPosition, true);
    window.removeEventListener('resize', updateDropdownPosition, true);
});

const getOptionValue = (value) => {
    return typeof value === 'object' ? JSON.stringify(value) : value;
};

watch(() => props.options, (newOptions) => {
    options.value = newOptions ?? [];
}, { immediate: true });

//AJAX select
const ajaxState = ref({
    'page': 0,
    'count': 10,
    'totalCount': null,
    'isLoading': false,
});
const scrollContainer = ref(null);
const dropdownTriggerEl = ref(null);
const searchInputEl = ref(null);

const loadData = async (reset = false) => {
    if(!props.url){
        return;
    }

    if (reset) {
        ajaxState.value.page = 0;
        options.value = [];
    }

    ajaxState.value.isLoading = true;
    try {
        let urlParams = {
            start: ajaxState.value.page * ajaxState.value.count,
            count: ajaxState.value.count,
        };

        if (filterText.value) {
            urlParams.q = filterText.value;
        }

        const response = await axios.get(props.url, {
            params: urlParams,
        });

        const tmpOptions = response.data.items.map((item) => {
            return {
                value: !props.remoteValueObject ? item[props.remoteIdentifier] : item,
                text: item[props.remoteName],
            }
        });

        options.value.push(...tmpOptions);
        ajaxState.value.totalCount = parseInt(response.data.numRows);
    } catch (error) {
        console.error("Chyba při načítání dat:", error);
    } finally {
        ajaxState.value.isLoading = false;
    }
};

watch(filterText, () => {
    if(isOpen.value){
        if (timeoutId !== null) {
            clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => {
            loadData(true).then(() => {
                nextTick(() => {
                    updateDropdownPosition();
                });
            });

        }, 750);
    }
});

watch(isOpen, (newVal) => {
    if (newVal) {
        loadData(true).then(() => {
            updateDropdownPosition();
            requestAnimationFrame(() => {
                if (searchInputEl.value && props.filterable) {
                    searchInputEl.value.focus();
                }
            });
        });
    }
});

const onScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;

    if (scrollTop + clientHeight >= scrollHeight - 10 && options.value.length < ajaxState.value.totalCount && !ajaxState.value.isLoading) {
        ajaxState.value.page++;
        loadData();
    }
};

watch(isOpen, (newVal, oldVal) => {
    if(!props.url){
        return;
    }

    if (newVal === true) {
        nextTick().then(() => {
            if (scrollContainer.value) {
                scrollContainer.value.addEventListener('scroll', onScroll);
            }
        });
    } else if (oldVal === true) {
        if (scrollContainer.value) {
            scrollContainer.value.removeEventListener('scroll', onScroll);
        }
    }
}, { immediate: false });
//end AJAX select

const inputClass = computed(() => {
    let baseClasses = "border border-gray-300 text-gray-900 rounded-lg relative pr-6 pl-2.5";

    if(props.readonly){
        baseClasses = baseClasses + ' bg-gray-200';
    }else{
        baseClasses = baseClasses + ' bg-gray-50 cursor-pointer';
    }

    switch (props.size) {
        case 'small':
            return `${baseClasses} py-2 min-h-[34px] text-xs`;
        case 'large':
            return `${baseClasses} py-4 min-h-[58px] text-base`;
        default:
            return `${baseClasses} py-2.5 min-h-[42px] text-sm`;
    }
});

const dropdownStyles = ref({});

const getAbsolutePosition = (element) => {
    if (!element.getClientRects().length) {
        return {top: 0, left: 0};
    }

    let rect = element.getBoundingClientRect();
    let win = element.ownerDocument.defaultView;
    return ({
        top: rect.top + win.pageYOffset,
        left: rect.left + win.pageXOffset
    });
};

const updateDropdownPosition = () => {
    if (dropdownTriggerEl.value && scrollContainer.value) {
        const { width, height } = dropdownTriggerEl.value.getBoundingClientRect();
        const { top, left } = getAbsolutePosition(dropdownTriggerEl.value);
        const windowHeight = window.innerHeight;
        const dropdownHeight = scrollContainer.value.offsetHeight;
        const relativeTop = top - window.scrollY; // Adjust top to be relative to the window's viewport

        window.asdf = () => {return getAbsolutePosition(dropdownTriggerEl.value)};
        window.el = dropdownTriggerEl.value;

        let newTop;
        // Check if dropdown fits below
        if (relativeTop + height + dropdownHeight <= windowHeight) {
            newTop = top + height - 1;
        }
        // Check if dropdown fits above
        else if (relativeTop - dropdownHeight >= 0) {
            newTop = top - dropdownHeight - 1;
        }
        // Default to below if it doesn't fit above either
        else {
            newTop = top + height - 1;
        }

        // Use requestAnimationFrame to avoid layout thrashing
        requestAnimationFrame(() => {
            dropdownStyles.value = {
                position: 'absolute',
                top: `${newTop}px`,
                left: `${left}px`,
                width: `${width}px`,
            };
        });
    }
};
</script>

<template>
    <div class="select">
        <label v-if="props.label" @click="toggleDropdown">{{ label }}</label>
        <div class="input-wrapper" ref="dropdownTriggerEl">
            <div @click="toggleDropdown" class="input" :class="{multiple: multiple}">
                <template v-if="multiple">
                    <template v-if="selectedValue.length === 0">{{ placeholder }}</template>
                    <div v-else v-for="selected in selectedValue" @click.stop="selectOption({value: selected.value, text: selected.text})">
                        {{ selected.text }}
                    </div>
                </template>
                <div v-else>
                    {{ selectedValue?.text || placeholder }}
                </div>
            </div>
            <div class="icons">
                <span v-if="!required && (multiple ? selectedValue?.length > 0 : selectedValue?.value)" class="close-icon icon" @click="clearSelectedValue">
                    <CloseIcon :width="12" :height="12" color="#374151" />
                </span>
                <span class="arrow-icon icon" @click="toggleDropdown">
                    <ArrowIcon :width="12" :height="12" color="#374151" />
                </span>
            </div>
        </div>
        <Teleport to="body">
            <div v-if="isOpen" :style="dropdownStyles" ref="scrollContainer" style="position: absolute" class="dropdown">
                <InputField v-if="filterable" v-model="filterText" @set-input-ref="(ref) => searchInputEl=ref" :font="font" icon="search" placeholder="Vyhledat..."></InputField>
                <div v-if="!props.url" v-for="option in filteredOptions" :key="getOptionValue(option.value)" class="option" :class="{selected: isOptionSelected(option)}" @click="selectOption(option)">
                    {{ option.text }}
                </div>
                <div v-else v-for="(option, index) in options" :key="index" class="option" :class="{selected: isOptionSelected(option)}" @click="selectOption(option)">
                    {{ option.text }}
                </div>
                <div v-if="props.url && ajaxState.isLoading" class="is-loading">
                    Načítám...
                </div>
            </div>
        </Teleport>
    </div>
</template>

<style scoped>
    .select{
        width: 100%;
        position: relative;
        display: inline-block;

        label{
            display: block;
            margin-bottom: 9px;
            font-size: 14px;
            font-weight: 600;
        }

        .input-wrapper {
            border: 1px solid #CDD8ED;
            background-color: #ffffff;
            border-radius: 6px;
            display: flex;
            align-items: center;
            padding: 0 11px;
            height: 40px;
            min-height: 40px;

            .input {
                flex-grow: 1;
                color: #374151;
                padding-right: 11px;
                border-radius: 6px;
                height: 38px;
                min-height: 38px;
                font-size: 14px;
                line-height: 38px;
                cursor: pointer;
                background-color: #ffffff;

                &.multiple {
                    display: flex;
                    gap: 8px;

                    & > div {
                        &:hover {
                            text-decoration: line-through;
                        }
                    }
                }
            }

            .icons {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 6px;
                height: 100%;

                .icon {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }
            }
        }
    }

    .dropdown{
        position: absolute;
        background-color: #fff;
        border: 1px solid #D1D5DB;
        border-radius: 0.375rem;
        z-index: 9999;
        max-height: 300px;
        overflow-y: auto;
        font-size: 14px;

        input{
            padding: 0.5rem;
            width: 100%;
        }

        .option{
            padding: 0.5rem 1rem;

            &:hover{
                background-color: #ECF2FF;
                cursor: pointer;
            }

            &.selected {
                background-color: #ECF2FF;
            }
        }

        .is-loading{
            padding: 0.5rem 1rem;
            background: #F3F4F6;
        }
    }
</style>
