<script setup lang="ts">

import HandsontableApp from "@project/Excel/Handsontable/HandsontableApp.vue";
import {onBeforeMount, onMounted, reactive, ref} from "vue";
import {type ISheet, Sheet} from "@project/Excel/Handsontable/js/Sheet";
import {type ITable, Table} from "@project/Excel/Handsontable/js/Table.js";
import Button from "@/Components/Inputs/Button.vue";
import SheetEditorDialog from "@project/Excel/ExcelApp/Components/SheetEditor/SheetEditorDialog.vue";
import RowDataDialog from "@project/Excel/Handsontable/Components/Table/RowDataDialog.vue";
import ActiveFilters from "@project/Excel/Handsontable/Components/ActiveFilters/ActiveFilters.vue";
import type {ModelDefinition} from "@project/Excel/Handsontable/js/ModelDefinition";
import ActiveFiltersDialog from "@project/Excel/Handsontable/Components/ActiveFilters/ActiveFiltersDialog.vue";
import {type IPriceListTable, PriceListTable} from "@project/Excel/Handsontable/js/PriceList/PriceListTable";
import {SheetManager} from "@project/Excel/Handsontable/js/SheetManager";
import {SheetSettings} from "@project/Excel/Handsontable/js/SheetSettings";
import {useSheetManager} from "@project/Excel/ExcelApp/Hooks/useSheetManager";
import {useLayoutStore} from "@project/Excel/ExcelApp/Components/Layout/useLayout";
import { useRouter } from 'vue-router'
import SaveDialog from "@project/Excel/ExcelApp/Components/DisplaySheet/SaveDialog.vue";
import ImportPriceListDialog from "@project/Excel/ExcelApp/Components/DisplaySheet/PriceList/ImportPriceListDialog.vue";

const props = defineProps<{
    sheet?: ISheet | null,
    id?: string|nubmer|null,
}>();

const emit = defineEmits(['back']);

const sheet: Sheet = reactive({
    data: !props.sheet ? null : new Sheet(cloneDeep(props.sheet)), //TODO pořebuji clone?
});

const {sheetManager}: SheetManager = useSheetManager();
const layoutStore = useLayoutStore();
const router = useRouter();

const initTable = () => {
    const handsontableSettings = {
        data: sheet.data.data,
    };

    if(sheet.data.type === 'price-list'){
        return new PriceListTable({
            // sheet: new Sheet(cloneDeep(Object.fromEntries(Object.entries(props.sheet))) as SheetState),
            sheet: sheet.data,
            handsontableSettings: handsontableSettings,
            onBeforeShowRowDataDialog: () => {
                showRowDataDialog.value = true;
            },
        });
    }else{
        return new Table({
            // sheet: new Sheet(cloneDeep(Object.fromEntries(Object.entries(props.sheet))) as SheetState),
            sheet: sheet.data,
            handsontableSettings: handsontableSettings,
            onBeforeShowRowDataDialog: () => {
                showRowDataDialog.value = true;
            },
        });
    }

}

const table = reactive<{table: ITable|IPriceListTabl0|null}>({table: null});

onMounted(async () => {
    if(props.id){
        const loadedSheet = await sheetManager.loadByID(props.id);

        if(loadedSheet){
            sheet.data = loadedSheet;
        }
    }

    if(sheet.data && !sheet.data.settings){
        await sheet.data.loadSettings();
    }

    if(sheet.data && sheet.data.settings){
        table.table = initTable();
        table.table.loadSheetDataFromServer();
    }
})


//sheetEditorDialog
const showEditorDialog = ref<boolean>(false);

const editorDialogSave = async (sheet: ISheet) => {
    const result = await window.sheetManager.updateOnServer(sheet); //todo smazat a předělat na use

    if(result){
        table.table.sheet = sheet;
        table.table.init();
        showEditorDialog.value = false;
    }
};

const editorDialogTmpSave = async (sheet: ISheet) => {
    table.table.sheet = sheet;
    table.table.init();
    showEditorDialog.value = false;
};
//end sheetEditorDialog
const showActiveFiltersDialog = ref<boolean>(false);
//

//ROW DATA
const showRowDataDialog = ref<boolean>(false);
//end ROW DATA

const back = () => {
    if(table.table.sheet){
        table.table.sheet.data = [[]];
    }

    emit('back');
};

const getRowDataDialogData = () => {
    const selectLast = table.table.handsontable?.getSelectedLast();
    if(selectLast){
        const index = selectLast[0];
        if(index){
            return table.table.sheet?.data[index] ?? {};
        }
    }

    return {};
}

//save dialog
const showSaveDialog = ref(false);
//end save dialog

layoutStore.setOnBack(() => {
    if(table.table.sheet?.dataDirty) {
        showSaveDialog.value = true;
    }else{
        router.push({ name: 'homepage' });
    }
});


//Pricelist dialog
const showImportPriceListDialog = ref(false);
//end pricelist dialog
</script>

<template>
    <div class="sheet">
        <div class="sheet-wrapper">
            <div class="pt-4">
                <Button @click="table.table.loadData()" variant="white" size="small" :outline="true" icon="download" icon-color="green" icon-direction="left" tooltip="Načte data z databáze podle sestaveného data modelu">Načíst data z data modelu</Button>
<!--                <Button  @click="table.table.importPriceList()" variant="white" size="small" :outline="true" icon="send" icon-color="green" icon-direction="left" tooltip="Naimportuje/aktualizuje ceník podle dat z tabulky" v-if="table.table && sheet.data.type === 'price-list'">Naimportovat nový ceník</Button>-->
                <Button  @click="showImportPriceListDialog = true" variant="white" size="small" :outline="true" icon="send" icon-color="green" icon-direction="left" tooltip="Naimportuje/aktualizuje ceník podle dat z tabulky" v-if="table.table && sheet.data.type === 'price-list'">Naimportovat nový ceník</Button>
                <Button @click="table.table.saveSheetDataToServer()" variant="white" size="small" :outline="true" icon="send" icon-color="green" icon-direction="left" tooltip="Uloží data z tabulky do databáze">Uložit</Button>
                <Button @click="table.table.loadSheetDataFromServer()" variant="white" size="small" :outline="true" icon="download" icon-color="green" icon-direction="left" tooltip="Nahraje data do tabulky z databáze, které byly uložené tlačítkem 'Uložit'">Nahrát</Button>
                <Button @click="showEditorDialog = true" variant="white" size="small" :outline="true" icon="edit" icon-color="green" icon-direction="left">Definice listu</Button>
                <Button @click="showActiveFiltersDialog = true" variant="white" size="small" :outline="true" icon="setting" icon-color="green" icon-direction="left">Aktivní filtry</Button>
                <!--        <Button @click="table.table.loadData(table.table.sheet?.settings?.modelDefinition?.joins[0].modelDefinition)">Load TEST data</Button>-->
            </div>
            <div class="table-wrapper">
                <HandsontableApp :table="table.table" v-if="table.table"></HandsontableApp>
            </div>
        </div>


        <SheetEditorDialog v-if="showEditorDialog" :show="showEditorDialog" :sheet="table.table.sheet" @close="showEditorDialog = false" @save="editorDialogSave" @tmpsave="editorDialogTmpSave"></SheetEditorDialog>
<!--        //@ts-ignore-->
        <RowDataDialog v-if="showRowDataDialog && table.table.sheet && table.table.handsontable?.getSelectedLast()" :data="getRowDataDialogData()" :show="showRowDataDialog" @close="showRowDataDialog = false;"></RowDataDialog>
        <ActiveFiltersDialog v-if="table.table?.sheet?.settings?.modelDefinition && showActiveFiltersDialog" :model-definition="table.table.sheet?.settings?.modelDefinition as ModelDefinition" :show="showActiveFiltersDialog"></ActiveFiltersDialog>
        <SaveDialog :show="showSaveDialog" v-if="showSaveDialog" :table="table.table" @close="showSaveDialog = false"></SaveDialog>
        <ImportPriceListDialog :show="showImportPriceListDialog" v-if="showImportPriceListDialog" :table="table.table" @close="showImportPriceListDialog = false;"></ImportPriceListDialog>
    </div>
</template>

<style scoped>
    .sheet-wrapper{
        display: flex;
        flex-direction: column;
        height: 90vh;

        .table-wrapper{
            flex-grow: 1;
            overflow: hidden;
            padding-top: 10px;
        }
    }
</style>