import type { ISheetDefinition } from "@project/Excel/Handsontable/js/SheetDefinition";
import type {IModelDefinition, LoadData} from "@project/Excel/Handsontable/js/ModelDefinition";
import type { ISheetDefinitionColumn } from "@project/Excel/Handsontable/js/SheetDefinitionColumn";
import type { IFilter } from "@/Filter/js/Filter";
import { Type, Expose, Exclude } from "class-transformer";
import { ModelDefinition } from "@project/Excel/Handsontable/js/ModelDefinition";
import "reflect-metadata";
import { SheetDefinition } from "@project/Excel/Handsontable/js/SheetDefinition";
import {ModelDefinitionJoin} from "@project/Excel/Handsontable/js/ModelDefinitionJoin";
import axios from "axios";

export interface ISheetSettings extends SheetSettingsState {
    // loadData(modelDefinition: IModelDefinition | null): Promise<LoadData | undefined>;
    // prepareDataForHS(data: LoadData): { [key: string]: any }[];
    // prepareData(data: LoadData): { [key: string]: any }[];
    findRowsBy(by: string, value: string): { [key: string]: any }[];
    findRowsIndexesBy(by: string, value: string): (number | null)[];
    // getModelForColumn(column: ISheetDefinitionColumn): IModelDefinition | null;
    // getDataForSheetColumns(): [][];
}

export interface SheetSettingsState {
    // id: number | null;
    // data: { [key: string]: any }[];
    dataUrl?: string | null;
    // name: string;
    sheetDefinition?: ISheetDefinition | null;
    modelDefinition?: IModelDefinition | null;
    type: string;
}

export class SheetSettings implements ISheetSettings {
    @Exclude()
    public state: SheetSettingsState = {};

    constructor(sheetSettingsState: SheetSettingsState | null = null) {
        Object.assign(
            this,
            sheetSettingsState ?? {
                name: "",
                sheetDefinition: new SheetDefinition(),
                modelDefinition: null,
            },
        );

        this.type = sheetSettingsState?.type ?? 'default';

        this.sheetDefinition = sheetSettingsState?.sheetDefinition ?? null;
        this.modelDefinition = sheetSettingsState?.modelDefinition ?? null;
    }

    @Expose()
    public get name(){
        return this.state.name;
    }
    public set name(name){
        this.state.name = name;
    }

    @Expose()
    public get type(){
        return this.state.type;
    }
    public set type(type){
        this.state.type = type;
    }

    @Expose()
    @Type(() => SheetDefinition)
    public get sheetDefinition(){
        return this.state.sheetDefinition;
    }
    public set sheetDefinition(sheetDefinition){
        this.state.sheetDefinition = sheetDefinition ? (sheetDefinition instanceof SheetDefinition ? sheetDefinition : new SheetDefinition(sheetDefinition)) : new SheetDefinition();
    }

    @Expose()
    @Type(() => ModelDefinition)
    public get modelDefinition(){
        return this.state.modelDefinition;
    }
    public set modelDefinition(modelDefinition){
        this.state.modelDefinition = modelDefinition ? (modelDefinition instanceof ModelDefinition ? modelDefinition : new ModelDefinition(modelDefinition)) : null;
    }

    // public async loadData(modelDefinition: IModelDefinition | null = null, dataFilter: IFilter | null = null): Promise<LoadData | undefined> {
    //     if (!this.modelDefinition) return;
    //
    //     if (!modelDefinition) return await this.modelDefinition.loadDataFromURL(dataFilter);
    //     else return await modelDefinition.loadDataFromURL(dataFilter);
    // }
    //

    public findRowsBy(by: string, value: string) {
        let row = this.data?.filter((row: any) => row[by] === value);
        return row;
    }

    public findRowsIndexesBy(by: string, value: string): (number | null)[] {
        let indexes = this.data?.map((row: any, index) => (row[by] === value ? index : null)).filter((index) => index !== null);
        return indexes;
    }

    // getModelForColumn(column: ISheetDefinitionColumn): IModelDefinition | null {
    //     if (!this.modelDefinition) {
    //         return null;
    //     }
    //
    //     let model = this.modelDefinition.getModelForColumn(column.model ?? "");
    //
    //     return model;
    // }

    // getDataForSheetColumns(): [][] {
    //     if (!this.sheetDefinition || !this.data) {
    //         return [[]];
    //     }
    //
    //     let filteredData: [][] = [];
    //     this.data?.forEach((row: any, index) => {
    //         let newRow: any[] = [];
    //         this.sheetDefinition?.columns?.forEach((column: ISheetDefinitionColumn, c: number) => {
    //             newRow.push(row[column.joinData as string] ?? undefined);
    //         });
    //         filteredData.push(newRow as []);
    //     });
    //
    //     return filteredData;
    // }

    async loadDataFromServer(){
        //url
        const url = window.remoteUrl;
        //url

        const data= {
            Sheet_ID: this.id,
            sort: '-DateTime',
            count: 1,
        };

        let sheetData = await axios.get(url + '/admin/sheet-data/data-list', {
            params: data,
        });

        if(sheetData.data && typeof sheetData.data.items !== 'undefined' && sheetData.data.items.length){
            console.log(JSON.parse(sheetData.data.items[0].Data));
            return JSON.parse(sheetData.data.items[0].Data);
        }else{
            return false;
        }

    }
}
