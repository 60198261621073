import type { ISheet } from "@project/Excel/Handsontable/js/Sheet";
import Handsontable from "handsontable";
import HyperFormula from "hyperformula";
import { cloneDeep } from "lodash";
import type { ISheetDefinitionColumn } from "@project/Excel/Handsontable/js/SheetDefinitionColumn";
import { SheetDefinitionColumn } from "@project/Excel/Handsontable/js/SheetDefinitionColumn";
import type { IModelDefinition } from "@project/Excel/Handsontable/js/ModelDefinition";
import {CurrencyType} from "@project/Excel/Handsontable/js/ColumnTypes/Currency/CurrencyType";
import {PercentageType} from "@project/Excel/Handsontable/js/ColumnTypes/Percentage/PercentageType";
import axios from "axios";
import {useAjax} from "@/js/Ajax/useAjax.js";
import {useAlertStore} from "@/Components/Alerts/stores/alertStore.js";

export interface ITable extends TableState {
    handsontable: Handsontable | null;
    getColumns(): ISheetDefinitionColumn[];
    addColumn(column: ISheetDefinitionColumn): void;
    loadData(modelDefinition?: IModelDefinition | null): Promise< void >;
    init(tableState?: TableState | null): void;
    updateDefinitionSheetColumnsTitles(): void;
    loadData2(modelDefinition?: IModelDefinition | null): Promise<{[key: string]: any;}[]>;
    updateData(data: {[key: string]: any;}[]): void;
    setData(data: {[key: string]: any;}[]): void;
    isFormula(value: string): boolean;
    prepareFormula(value: string, row: number): string;
    prepareSourceData(data: {[key: string]: any;}[]): [][];
    prepareData(data: {[key: string]: any;}[]): [][];
}

export interface TableState {
    sheet: ISheet | null;
    handsontableSettings?: Handsontable.GridSettings | null;
    onBeforeShowRowDataDialog?: Function | null;
    lastSelectedCells: any;
}

export class Table implements ITable {
    public sheet: ISheet | null = null;
    public handsontable: any;
    public handsontableSettings: Handsontable.GridSettings | null = null;
    public onBeforeShowRowDataDialog?: Function | null;
    public ajax: any;
    public alerts: any;
    public lastSelectedCells: any;

    constructor(tableState: TableState | null = null) {
        this.onBeforeShowRowDataDialog = null;
        // HyperFormula.registerFunctionPlugin(MyCustomPlugin, MyCustomPlugin.translations);

        // const hyperformulaInstance = HyperFormula.buildEmpty({
        //     // to use an external HyperFormula instance,
        //     // initialize it with the `'internal-use-in-handsontable'` license key
        //     licenseKey: "internal-use-in-handsontable",
        //     licenseKeyValidityState: "VALID",
        // });

        // const hfInstance = HyperFormula.buildFromArray([["Anthony", "=ROW(A)"]], { licenseKey: "internal-use-in-handsontable" });
        // // read the value of cell B1
        // const result = hfInstance.getCellValue({ sheet: 0, col: 1, row: 0 });
        //
        // console.log(hfInstance.getAllFunctionPlugins());
        // console.log(result);
        Object.assign(
            this,
            tableState ?? {
                sheet: null,
            },
        );

        this.handsontable = null;
        this.init(tableState);
        this.ajax = useAjax();
        this.alerts = window.pinia ? useAlertStore(window.pinia) : null;
    }

    public async init(tableState: TableState | null = null) {
        Handsontable.cellTypes.registerCellType('currency', new CurrencyType());
        Handsontable.cellTypes.registerCellType('percentage', new PercentageType());

        this.lastSelectedCells = null;


        if(!this.sheet?.settings) {
            return
        }

        let self = this;
        this.handsontableSettings = {
            allowInsertColumn: true,
            bindRowsWithHeaders: true,
            manualColumnResize: true,
            filters: true,
            colHeaders: true,
            rowHeaders: true,
            height: "95%",
            autoWrapRow: true,
            autoWrapCol: true,
            licenseKey: "non-commercial-and-evaluation",
            formulas: {
                engine: HyperFormula,
                //@ts-ignore
                licenseKey: "internal-use-in-handsontable",
                sheetName: "Sheet1",
            },
            ...(tableState?.handsontableSettings ?? {}),
            data: this.sheet ? cloneDeep(this.sheet.data) : [[]],
            hiddenColumns: {
                indicators: true,
            },
            undo: true,
            // manualColumnMove: true,
            //@ts-ignore
            manualColumnMove: this.sheet?.settings?.sheetDefinition?.getColumnsSortByPosition(true).map((column) => column.position) ?? true,
            //@ts-ignore
            contextMenu: {
                items: {
                    row_above: {},
                    row_below: {},
                    // sp1: "---------",
                    // col_left: {},
                    // col_right: {},
                    sp2: "---------",
                    remove_row: {},
                    remove_col: {},
                    sp3: "---------",
                    undo: {},
                    redo: {},
                    sp4: "---------",
                    make_read_only: {},
                    alignment: {},
                    sp5: "---------",
                    copy: {},
                    cut: {},
                    row_data_info: {
                        name: "Data řádku",
                        callback(key, selection, clickEvent) {
                            if (typeof self.onBeforeShowRowDataDialog === "function") {
                                self.onBeforeShowRowDataDialog();
                            }
                        },
                        hidden() {
                            return self.handsontable.getSelectedLast()[1] !== -1;
                        },
                    },
                },
            },
            //@ts-ignore
            dropdownMenu: {
                items: {
                    modelInfo: {
                        // Own custom property
                        // Custom rendered element in the context menu
                        renderer(hot, wrapper, row, col, prop, itemValue) {
                            let column = self.handsontable.getSelectedLast()[1];
                            column = self.sheet?.settings?.sheetDefinition?.columns ? self.sheet.settings.sheetDefinition.columns[column] : false;

                            if(!column){
                                return;
                            }

                            const columnInfo = document.createElement("div");
                            columnInfo.setAttribute("data-column", JSON.stringify(column));
                            columnInfo.id = "ContextMenuColumnInfo";

                            return columnInfo;
                        },
                        disableSelection: true, // Prevent mouseoever from highlighting the item for selection
                        isCommand: false, // Prevent clicks from executing command and closing the menu
                    },
                    dataModelActions: {
                        name: "Data model akce",
                        submenu: {
                            items: [
                                {
                                    key: "dataModelActions:loadModel",
                                    name: "Načíst model",
                                    callback(key, selection, clickEvent) {
                                        let column = self.handsontable.getSelectedLast()[1];
                                        column = self.sheet?.settings?.sheetDefinition?.columns ? self.sheet.settings.sheetDefinition.columns[column] : false;

                                        if(!column){
                                            return;
                                        }

                                        let modelDefinition = self.sheet?.settings?.modelDefinition?.getModelByJoinChain(column.getJoinChain());

                                        self.loadData2(modelDefinition);
                                    },
                                },
                            ],
                        },
                    },
                    filter_by_value: {},
                    filter_action_bar: {},
                },
            },
            colWidths: (visualColumnIndex) => {
                return self.sheet?.settings?.sheetDefinition?.getColumnInPosition(visualColumnIndex)?.width ?? 200;
            },
            rowHeights: 24,
            autoRowSize: false,
            viewportRowRenderingOffset: 50,
            viewportColumnRenderingOffset: 20,
            columnSorting: true,
            customBorders: true,
        };

        // this.handsontableSettings.columns = this.sheet?.settings?.sheetDefinition?.getColumnsForHS() ?? [];
        if(!this.handsontableSettings){
            return;
        }

        this.handsontableSettings.data = this.sheet?.data ? (this.handsontable ? this.sheet.data : this.sheet.getDataForSheetColumns()) : [this.sheet?.settings?.sheetDefinition?.columns?.map((column) => "") ?? []];
        this.handsontableSettings.colHeaders = this.sheet?.settings?.sheetDefinition?.getColumnsSortByPosition()?.map((column) => column.title) ?? [];
        this.handsontableSettings.cells = (row, col) => {
            if (!this.sheet?.settings?.sheetDefinition?.columns) {
                return {};
            }

            // let index = col;
            // if (this.handsontable) {
            //     index = this.handsontable.toVisualColumn(col);
            // }
            //
            // const defColumn = this.sheet.settings.sheetDefinition.getColumnInPosition(index);
            const defColumn = this.sheet.settings.sheetDefinition.columns[col];

            if (!defColumn) {
                return {};
            }

            let cellProperties = defColumn.columnMeta ? cloneDeep(defColumn.columnMeta) : {};

            const self = this;

            if (defColumn.formating) {
                //@ts-ignore
                cellProperties.renderer = function (instance: any, td: any, row: any, col: any, prop: any, value: any, cellProperties: any) {
                    //@ts-ignore
                    // if(defColumn.type === 'currency'){
                    //     Handsontable.renderers.getRenderer(cellProperties.type).apply(this, arguments);
                    // }else{
                    //     Handsontable.renderers.TextRenderer.apply(this, arguments);
                    // }
                    Handsontable.renderers.getRenderer(cellProperties.type).apply(this, arguments);

                    if(defColumn.formating){
                        if (self.isFormula(defColumn.formating)) {
                            const result = instance.getPlugin("formulas").engine.calculateFormula(self.prepareFormula(defColumn.formating, instance.toPhysicalRow(row) + 1), 0);
                            td.style.backgroundColor = result;
                        } else {
                            td.style.backgroundColor = defColumn.formating;
                        }
                    }else{
                        td.style.backgroundColor = '#fff';
                    }
                };
            }

            //@ts-ignore
            cellProperties.type = defColumn.type;
            //@ts-ignore
            cellProperties.wordWrap = false;
            if(defColumn.className){
                cellProperties.className = defColumn.className;
            }

            if(typeof this.sheet?.data !== 'undefined' && this.sheet.data[row] && typeof this.sheet?.data[row][defColumn.data ? defColumn.joinData : defColumn.alias] !== 'undefined' && this.isFormula(this.sheet.data[row][defColumn.alias ? defColumn.alias : defColumn.joinData])){
                cellProperties.origFormula = this.sheet.data[row][defColumn.data ? defColumn.joinData : defColumn.alias];
            }


            return cellProperties;
        };

        this.handsontableSettings.afterGetCellMeta = (row, column, cellProperties) => {
            if(this.handsontable){
                const colDef = this.sheet.settings.sheetDefinition.columns[this.handsontable.toPhysicalColumn(column)];
                const cellData = this.handsontable.getSourceDataAtCell(this.handsontable.toPhysicalRow(row), column);

                if(this.isFormula(cellData)){
                    if (colDef && colDef.data) {
                        cellProperties.origFormula = this.sheet.data[this.handsontable.toPhysicalRow(row)][colDef.joinData];
                    }else{
                        cellProperties.origFormula = this.sheet.data[this.handsontable.toPhysicalRow(row)][colDef.alias];
                    }
                }else if(!this.isFormula(cellData) && typeof cellProperties.origFormula !== 'undefined'){
                    cellProperties.origFormula = null;
                }
            }

        };

        this.handsontableSettings.beforeCreateCol = (index, amount, source) => {
            return false;
        };

        this.handsontableSettings.afterCreateCol = (index, amount, source) => {
            setTimeout(() => {
                if (!this.sheet?.settings?.sheetDefinition && !this.sheet?.settings?.sheetDefinition?.columns) {
                    return;
                }

                for (let i = 0; i < amount; i++) {
                    this.sheet.settings.sheetDefinition.addColumn(new SheetDefinitionColumn({ title: this.handsontable.getColHeader(index), type: "text", defaultValue: "", model: null, data: "" }));
                }

                if(this.sheet?.settings?.sheetDefinition?.columns){
                    this.sheet.settings.sheetDefinition.moveColumnsPhysically([this.sheet.settings.sheetDefinition.columns.length - amount], this.handsontable.toPhysicalColumn(index));
                    this.sheet.settings.sheetDefinition.moveColumnToPosition(this.sheet.settings.sheetDefinition.columns.length - amount, index);
                }


                // this.updateDefinitionSheetColumnsTitles();
                this.handsontable.render();
            }, 0);
        };

        this.handsontableSettings.afterRemoveCol = (index, amount, physicalColumns, source) => {
            setTimeout(() => {
                this.lastSelectedCells = null;
                if (!(this.sheet?.settings?.sheetDefinition && this.sheet?.settings?.sheetDefinition?.columns)) {
                    return;
                }

                // this.sheet.settings.sheetDefinition.columns.splice(index, amount);
                for(let i = 0; i < amount; i++){
                    this.sheet.settings.sheetDefinition.removeColumnInPosition(index+i);
                }


                // this.updateDefinitionSheetColumnsTitles();

                this.handsontable.render();
            }, 0);
        };

        this.handsontableSettings.afterColumnMove = (movedColumns, finalIndex, dropIndex, movePossible, orderChanged) => {
            if (this.sheet?.settings?.sheetDefinition && orderChanged) {
                this.lastSelectedCells = null;
                this.sheet.settings.sheetDefinition.moveColumnsPosition(movedColumns, finalIndex);
                this.updateDefinitionSheetColumnsTitles();

                if (this.handsontable) this.handsontable.render();
            }
        };

        this.handsontableSettings.beforeUpdateData = (sourceData, initialLoad, source) => {
            if (this.sheet) {
                let _data: {[key: string]: any}[] = self.prepareSourceData(sourceData);

                this.sheet.data = [..._data] as { [key: string]: any }[];

                _data = self.prepareData(_data);

                return _data;
            }
        };

        this.handsontableSettings.afterChange = (changes) => {
            changes?.forEach(([row, prop, oldValue, newValue]) => {
                if(!(this.sheet?.settings?.sheetDefinition)){
                    return;
                }

                const col = this.sheet.settings.sheetDefinition.getColumnInPosition(prop as number);

                const cellMeta = this.handsontable.getCellMeta(row, prop);
                if(cellMeta && typeof cellMeta.origFormula !== 'undefined' && cellMeta.origFormula){
                    newValue = cellMeta.origFormula;
                }

                if (col && col.data) {
                    this.sheet.data[this.handsontable.toPhysicalRow(row)][col.joinData as string] = newValue;
                }else if(col){
                    this.sheet.data[this.handsontable.toPhysicalRow(row)][col.alias as string] = newValue;
                }

                this.sheet.dataDirty = true;
            });
        };

        this.handsontableSettings.beforeChange = (changes) => {
            console.log('before change');
            changes?.forEach(([row, prop, oldValue, newValue], index) => {
                if(!(this.sheet?.settings?.sheetDefinition)){
                    return;
                }

                const col = this.sheet.settings.sheetDefinition.getColumnInPosition(prop as number);

                if (col && col.data) {
                    this.sheet.data[this.handsontable.toPhysicalRow(row)][col.joinData as string] = newValue;
                }else if(col){
                    this.sheet.data[this.handsontable.toPhysicalRow(row)][col.alias as string] = newValue;
                }

                if(this.isFormula(newValue)){
                    this.handsontable.setCellMeta(row, prop, 'origFormula', newValue);
                    changes[index][3] = this.prepareFormula(newValue, this.handsontable.toPhysicalRow(row) + 1);
                }else{
                    this.handsontable.setCellMeta(row, prop, 'origFormula', null);
                }
            });
        };

        this.handsontableSettings.beforeBeginEditing = (row, column, initialValue, event, fullEditMode) => {
            if(this.isFormula(this.handsontable.getActiveEditor().originalValue)){
                console.log(row, this.handsontable.toPhysicalRow(row));
                const cellMeta = this.handsontable.getCellMeta(row, column);
                console.log(cellMeta);

                if(typeof cellMeta.origFormula !== 'undefined' && cellMeta.origFormula && this.isFormula(cellMeta.origFormula)){
                    this.handsontable.getActiveEditor().originalValue = cellMeta.origFormula;
                }
            }

        };

        this.handsontableSettings.afterBeginEditing = (row, column) => {
            // console.log(this.handsontable.getCellMeta(row, column));
        };

        // this.handsontableSettings.modifyColHeader = (column) => {
        //     if(this.handsontable){
        //         // console.log('modifyColHeader', column);
        //         // console.log(this.handsontable.getColHeader(column));
        //     }
        //
        // }

        this.handsontableSettings.beforeAutofill = (selectionData, sourceRange, targetRange, direction) => {
            console.log(selectionData, sourceRange, targetRange, direction)

            if(!(this.sheet?.settings?.sheetDefinition)){
                return;
            }

            const sourceRangeStart = sourceRange.getTopLeftCorner();
            const sourceRangeEnd = sourceRange.getBottomRightCorner();
            const sourceRangeHeight = sourceRange.getHeight();
            const sourceRangeWidth = sourceRange.getWidth();

            const sourceDirection = sourceRange.getDirection();

            selectionData.forEach((row, rowIndex) => {
                row.forEach((cell, cellIndex) => {
                    let sourceRow, sourceCol;

                    switch (sourceDirection) {
                        case 'NW-SE':
                            sourceRow = sourceRangeStart.row + (rowIndex % sourceRangeHeight);
                            sourceCol = sourceRangeStart.col + (cellIndex % sourceRangeWidth);
                            break;
                        case 'NE-SW':
                            sourceRow = sourceRangeStart.row + (rowIndex % sourceRangeHeight);
                            sourceCol = sourceRangeEnd.col - (cellIndex % sourceRangeWidth);
                            break;
                        case 'SW-NE':
                            sourceRow = sourceRangeEnd.row - (rowIndex % sourceRangeHeight);
                            sourceCol = sourceRangeStart.col + (cellIndex % sourceRangeWidth);
                            break;
                        case 'SE-NW':
                            sourceRow = sourceRangeEnd.row - (rowIndex % sourceRangeHeight);
                            sourceCol = sourceRangeEnd.col - (cellIndex % sourceRangeWidth);
                            break;
                        default:
                            sourceRow = sourceRangeStart.row + (rowIndex % sourceRangeHeight);
                            sourceCol = sourceRangeStart.col + (cellIndex % sourceRangeWidth);
                    }

                    const sourceCellMeta = this.handsontable.getCellMeta(sourceRow, sourceCol);
                    if (typeof sourceCellMeta.origFormula !== 'undefined' && sourceCellMeta.origFormula && this.isFormula(sourceCellMeta.origFormula)) {
                        selectionData[rowIndex][cellIndex] = sourceCellMeta.origFormula;
                    }
                });
            });
        }

        this.handsontableSettings.afterSelection = (row, column, row2, column2, preventScrolling, selectionLayerLevel) => {
            table.lastSelectedCells = this.handsontable.getSelected() ? [...this.handsontable.getSelected()] : null; //TODO jak vyřešit table?
        };

        this.handsontableSettings.afterSetSourceDataAtCell = (changes) => {
            console.log(changes);
        };

        if (this.handsontable) {
            this.handsontable.updateSettings(this.handsontableSettings);
        }
    }

    public getColumns(): ISheetDefinitionColumn[] {
        return this.sheet?.settings?.sheetDefinition?.columns ?? [];
    }

    public addColumn(column: ISheetDefinitionColumn): void {
        if (this.sheet?.settings?.sheetDefinition?.columns && this.handsontableSettings?.columns) {
            this.sheet.settings.sheetDefinition.columns = [...(this.sheet?.settings?.sheetDefinition?.columns ?? []), column];
            this.handsontable.updateSettings({
                columns: this.getColumns(),
            });
            // this.handsontableSettings.columns = this.getColumns();
        }
    }

    public updateDefinitionSheetColumnsTitles(): void {
        if (this.handsontable && this.sheet?.settings?.sheetDefinition?.columns) {
            this.sheet.settings.sheetDefinition.columns.forEach((column, index) => {
                column.title = this.handsontable.getColHeader(column.position);
            });
        }
    }

    // public updateColumnsPosition(){
    //     this.handsontable
    // }

    public async loadData(modelDefinition: IModelDefinition | null = null): Promise< void > {
        if (!this.sheet) return;

        const waitingAlert = this.alerts.waiting('Načítám data');
        try{
            let data = await this.sheet.loadData(modelDefinition);
            if (!data) return;

            // data = this.sheet.prepareDataForHS(data);
            const preparedData = this.sheet.prepareData(data);

            let _data: [][] = [];

            if (this.sheet?.settings?.sheetDefinition?.columns) {
                preparedData.forEach((preparedDataRow, index) => {
                    this.sheet?.settings?.sheetDefinition?.getColumnsSortByPosition()?.forEach((column: ISheetDefinitionColumn, c: number) => {
                        let value: any;
                        if(column.data){
                            value = preparedDataRow[column.joinData] ?? null;

                            if(column.type === 'currency' && typeof value != 'undefined' && value){
                                value /= 100;
                            }

                            if(preparedDataRow.hasOwnProperty(column.joinData)){
                                preparedDataRow[column.joinData] = value;
                            }
                        }
                    });
                });
            }

            this.updateData(preparedData);
            this.sheet.dataDirty = false;
        }catch (e) {
            waitingAlert.changeToError('Chyba při načítání dat', e.message ? e.message : '', e);
            return;
        }

        this.lastSelectedCells = null;

        waitingAlert.changeToSuccess('Data načtena');
        return;
    }

    public setData(data: {[key: string]: any;}[]) {
        // let _data: [][] = [];
        // if (this.sheet?.settings?.sheetDefinition?.columns) {
        //     data.forEach((preparedDataRow, index) => {
        //         let row: [] = [];
        //         this.sheet.sheetDefinition?.columns?.forEach((column: ISheetDefinitionColumn, c: number) => {
        //             let value = preparedDataRow[column.joinData] ?? column.defaultValue ?? undefined;
        //
        //             if (value) {
        //                 if (this.isFormula(value)) {
        //                     value = this.prepareFormula(value, index + 1);
        //                 }
        //             }
        //
        //             row.push(value);
        //         });
        //
        //         _data.push(row);
        //     });
        // }

        this.handsontable.updateData([]);
        this.handsontable.updateData(data);
    }

    public isFormula(value: string): boolean {
        if(!value) return false;

        if(typeof value.startsWith === 'undefined'){
            return false;
        }

        return value.startsWith("=");
    }

    public prepareFormula(value: string, row: number): string {
        let formula = value.substring(1);

        if(value.includes('%row%')){
            formula = formula.replace(/\%row\%/g, row.toString());
        }

        const extractColumns = (input: any) => {
            const columnRegex = /column\(([^)]+)\)/gi; // Definovat regex uvnitř funkce
            const matches = [];
            let match;
            while ((match = columnRegex.exec(input)) !== null) {
                matches.push(match[1]);
            }
            return matches;
        }

        if(/column\(([^)]+)\)/i.test(formula)){
            const columns = extractColumns(formula);

            columns.forEach((column) => {
                //@ts-ignore
                const col = this.sheet.settings.sheetDefinition.getColumnByAliasOrJoinChain(column);
                if(col){
                    const replaceRegex = new RegExp(`column\\(${column}\\)`, 'gi');
                    formula = formula.replace(replaceRegex, col.getColumnTableIndex() + row.toString());
                }
            });
        }


        return "=" + formula;
    }

    public async loadData2(modelDefinition: IModelDefinition | null = null): Promise<{[key: string]: any;}[]> {
        if (!this.sheet) return [{}];

        let loadData = await this.sheet.loadData(null);
        if (!loadData) return [{}];

        // data = this.sheet.prepareDataForHS(data);
        let data = this.sheet.prepareData(loadData);

        let _data = this.sheet.data;

        data?.forEach((row, index) => {
            if (!this.sheet) return;

            let rowsIndexes = this.sheet.findRowsIndexesBy("ID", row.ID);

            if (rowsIndexes) {
                const resolveModelDefinitionColumns = (modelDefinition: IModelDefinition | null = null, rowIndex: number) => {
                    modelDefinition?.columns?.forEach((column) => {
                        if(column.joinData){
                            _data[rowIndex][column.joinData] = row[column.joinData];
                        }
                    });

                    if (modelDefinition?.joins) {
                        modelDefinition.joins.forEach((join) => {
                            resolveModelDefinitionColumns(join.modelDefinition, rowIndex);
                        });
                    }
                };

                rowsIndexes.forEach((rowIndex: number | null) => {
                    resolveModelDefinitionColumns(modelDefinition, rowIndex as number);
                });
            }
        });

        this.setData(_data);

        return [[]];
    }

    public updateData(data: {[key: string]: any;}[]) {
        // let _data: [][] = [];
        // let sourceData = this.handsontable.getSourceData();
        //
        // if (this.sheet?.settings?.sheetDefinition?.columns) {
        //     data.forEach((preparedDataRow, index) => {
        //         let row: [] = [];
        //         if (index >= sourceData.length) {
        //             return;
        //         }
        //         this.sheet.sheetDefinition?.columns?.forEach((column: ISheetDefinitionColumn, c: number) => {
        //             let value = preparedDataRow[column.joinData] ?? sourceData[index][c];
        //
        //             if (value) {
        //                 if (this.isFormula(value)) {
        //                     value = this.prepareFormula(value, index + 1);
        //                 }
        //             }
        //
        //             row.push(value);
        //         });
        //
        //         _data.push(row);
        //     });
        // }


        const filterConditions = this.handsontable.getPlugin('Filters').conditionCollection.exportAllConditions();

        this.handsontable.updateData([]);
        this.handsontable.updateData(data);

        if(filterConditions && filterConditions.length > 0){
            this.handsontable.getPlugin('Filters').conditionCollection.importAllConditions(filterConditions);
            this.handsontable.getPlugin('Filters').filter();
        }

    }

    public prepareSourceData(data: {[key: string]: any;}[]): [][] {
        let _data: [][] = [];
        if (this.sheet?.settings?.sheetDefinition?.columns) {
            data.forEach((preparedDataRow, index) => {
                let row: {[key: string]: any;} = {...preparedDataRow};
                this.sheet?.settings?.sheetDefinition?.getColumnsSortByPosition()?.forEach((column: ISheetDefinitionColumn, c: number) => {
                    let value: any;
                    if(column.data){
                        value = preparedDataRow[column.joinData] ?? column.defaultValue ?? null;
                    }else{
                        value = preparedDataRow[column.alias] ?? column.defaultValue ?? null;
                    }

                    if(column.type === 'checkbox'){
                        value = value === "0" ? false : Boolean(value);
                    }

                    row[column.data ? column.joinData : column.alias] = (value);
                });

                _data.push(row as []);
            });
        }

        return _data;
    }

    public prepareData(data: {[key: string]: any;}[]): [][] {
        let _data: [][] = [];

        if (this.sheet?.settings?.sheetDefinition?.columns) {
            data.forEach((preparedDataRow, index) => {
                let row: any[] = [];
                this.sheet?.settings?.sheetDefinition?.getColumnsSortByPosition()?.forEach((column: ISheetDefinitionColumn, c: number) => {
                    let value: any;
                    if(column.data){
                        value = preparedDataRow[column.joinData] ?? null;
                    }else{
                        value = preparedDataRow[column.alias] ?? null;
                    }

                    //if(column.type === 'currency' && typeof value != 'undefined' && value){
                    //    value /= 100;
                    //}

                     if (value) {
                         if (this.isFormula(value)) {
                             value = this.prepareFormula(value, index + 1);
                         }
                     }

                    row.push(value);
                });

                _data.push(row as []);
            });
        }

        return _data;
    }

    getColumnIndexFromTableIndex(tableIndex: any) {
        let index = 0;
        for (let i = 0; i < tableIndex.length; i++) {
            index *= 26;
            index += tableIndex.charCodeAt(i) - 64; // 'A' má ASCII hodnotu 65
        }
        return index - 1; // Převádíme na 0-indexovaný formát
    }

    getTableData(source = false, onlyVisible = false){
        let data = [];

        let tableData;
        if(source){
            tableData = this.handsontable.getSourceData();
        }else{
            tableData = this.handsontable.getData();
        }


        const cols = this.sheet.settings.sheetDefinition.getColumnsSortByPosition();

        if(!source){
            onlyVisible = true;
        }

        const rowCount = onlyVisible ? this.handsontable.countRows() : this.handsontable.getSourceData().length;

        for(let i=0; i < rowCount; i++){
            let rowData = {};
            let rawRowData;
            if(source){
                rawRowData = this.getSourceDataAtRow(i);
                // rawRowData = onlyVisible ? this.handsontable.getSourceDataAtRow(i) : this.handsontable.getSourceData(i, 0, i, this.handsontable.countCols())[0];
            }else{
                rawRowData = this.handsontable.getData(i, 0, i, this.handsontable.countCols())[0];
            }

            cols.forEach((col, position) => {
                rowData[col.data ? col.joinData : col.alias] = rawRowData[position];

                if(col.type === 'currency' && rowData[col.data ? col.joinData : col.alias]){
                    //rowData[col.data ? col.joinData : col.alias] *= 100;
                }
            });

            data.push(rowData);
        }

        return data;
    }

    getData(source = false){
        let sheetData = this.getTableData(source);
        let data = [];

        // this.sheet.data.forEach((row, index) => {
        //    data[index] = {
        //        ...sheetData[index] ?? {},
        //        ...row,
        //    };
        // });

        return sheetData;
    }

    async loadSheetDataFromServer(){
        let data = await this.sheet.loadDataFromServer();

        if(data){
            this.setData(data);
        }
    }

    async saveSheetDataToServer(){
        if(!this.sheet){
            this.alerts.warning('List není nastavený');
            return;
        }

        //url
        const url = window.remoteUrl;
        //url

        const data= {
            data: JSON.stringify({
                Sheet_ID: this.sheet.id,
                Data: JSON.stringify(this.getTableData(true)),
            })
        };

        console.log(data);
        let result = false;

        await this.ajax.postForm(url + '/admin/sheet-data/data-create', data, null, {
            waitingAlert: {
                title: 'Ukládám data z listu',
            }
        }).then(({response, alert}) => {
            alert.changeToSuccess('Data úspěšně uložena');
            console.log(response.data);
            result = true;
            this.sheet.dataDirty = false;
        }).catch(({error, alert}) => {
            alert.changeToError('Chyba při ukládání dat', error.message ? error.message : '', error);
            result = false;
        });

        return result;
    }

    public getSourceDataAtRow(row){
        let result = [];
        this.getColumns().forEach((column, index) => {
            result.push(this.sheet.data[row][column.data ? column.joinData : column.alias]);
        });

        return result;
    }

    public getHyperformulaInstance(){
        return this.handsontable.getPlugin('formulas').engine;
    }
}
