<script setup>
import NewSelect from "@/Components/Inputs/NewSelect.vue";
import Thead from "@/Components/Table/Thead.vue";
import TheadTh from "@/Components/Table/TheadTh.vue";
import TbodyTr from "@/Components/Table/TbodyTr.vue";
import Table from "@/Components/Table/Table.vue";
import Tbody from "@/Components/Table/Tbody.vue";
import TheadTr from "@/Components/Table/TheadTr.vue";
import Button from "@/Components/Inputs/Button.vue";
import TbodyTd from "@/Components/Table/TbodyTd.vue";
import {ref, watch} from "vue";

const props = defineProps({
    modelValue: Array,
})

const emit = defineEmits(['update:modelValue']);
const verificators = ref(props.modelValue);

watch(props.modelValue, (newVal) => {
    if (JSON.stringify(newVal) !== JSON.stringify(verificators.value)) {
        verificators.value = newVal;
    }
});

watch(verificators, (newVal) => {
    if (JSON.stringify(newVal) !== JSON.stringify(props.modelValue)) {
        emit('update:modelValue', newVal);
    }
});

const removeVerificatorEmail = (index) => {
    if (verificators.value[index].ID) {
        verificators.value[index].ToBeDeleted = true;
    } else {
        verificators.value.splice(index, 1);
    }
};

const addVerificatorEmail = () => {
    verificators.value.push({
        ID: null,
        user:{
            ID: null,
            Name: null,
        }
    });
};


</script>

<template>
    <div class="my-5">
        <Table>
            <Thead>
            <TheadTr>
                <TheadTh>Email schvalujícího - tito uživatelé budou muset schválit a zkontrolovat texty, než se objeví k překladům</TheadTh>
                <TheadTh>Akce</TheadTh>
            </TheadTr>
            </Thead>
            <Tbody>
            <TbodyTr v-for="(email, index) in verificators" :key="index">
                <template v-if="!verificators[index].ToBeDeleted">
                    <TbodyTd>
                        <NewSelect size="small" v-model="verificators[index].user"
                                   :filterable="true"
                                   :url="'/user/user-simple-list/data-list?Shop_ID=3'"
                                   :remote-value-object="true"
                                   :text-from-value="true"
                        >
                        </NewSelect>
                    </TbodyTd>
                    <TbodyTd>
                        <Button size="small" @click="removeVerificatorEmail(index)" variant="red">Odebrat</Button>
                    </TbodyTd>
                </template>
            </TbodyTr>

            <TbodyTr>
                <td :colspan="2">
                    <Button size="small" @click="addVerificatorEmail" variant="green" class="m-3">Přidat email</Button>
                </td>
            </TbodyTr>
            </Tbody>
        </Table>
    </div>
</template>

<style scoped>

</style>