<script setup lang="ts">
    import type { SheetDefinition } from "@project/Excel/Handsontable/js/SheetDefinition";
    import {reactive, ref, watch} from "vue";
    import Button from "@/Components/Inputs/Button.vue";
    import InputField from "@/Components/Inputs/InputField.vue";
    import {
        type ISheetDefinitionColumn,
    } from "@project/Excel/Handsontable/js/SheetDefinitionColumn";
    import ModelColumnsSelectorDialog from "@project/Excel/ExcelApp/Components/SheetEditor/ModelColumnsSelectorDialog.vue";
    import type { ModelDefinition } from "@project/Excel/Handsontable/js/ModelDefinition";
    import type { IModelDefinitionColumn } from "@project/Excel/Handsontable/js/ModelDefinitionColumn";
    import SheetDefinitionColumnEditorDialog
        from "@project/Excel/ExcelApp/Components/SheetEditor/SheetDefinitionColumnEditorDialog.vue";
    import Table from "@/Components/Table/Table.vue";
    import TheadTh from "@/Components/Table/TheadTh.vue";
    import Tbody from "@/Components/Table/Tbody.vue";
    import Tfoot from "@/Components/Table/Tfoot.vue";
    import Thead from "@/Components/Table/Thead.vue";
    import TbodyTd from "@/Components/Table/TbodyTd.vue";
    import TfootTd from "@/Components/Table/TfootTd.vue";
    import TheadTr from "@/Components/Table/TheadTr.vue";
    import TfootTr from "@/Components/Table/TfootTr.vue";
    import TbodyTr from "@/Components/Table/TbodyTr.vue";
    import HighlightText from "@/Components/Other/HighlightText.vue";

    const props = defineProps<{
        sheetDefinition: SheetDefinition,
        modelDefinition: ModelDefinition,
    }>();

    const emit = defineEmits(['save']);

    const sheetDefinition = reactive<SheetDefinition>(props.sheetDefinition);
    const modelDefinition = reactive<ModelDefinition>(props.modelDefinition);

    const saveModelsColumnSelector = (newSheetColumnsList: ISheetDefinitionColumn[]) => {
        newSheetColumnsList.forEach((column, index) => {
            sheetDefinition.addColumn(column);
        });
    };

    //ADD COLUMN
    const addingColumn = ref<boolean>(false);
    const editingColumn = ref(null);

    const addColumn = (column: ISheetDefinitionColumn) => {
        if(editingColumn.value !== null && sheetDefinition.columns){
            let col = sheetDefinition.getColumnInPosition(column.position);
            Object.assign(col, column);
        }else{
            sheetDefinition.addColumn(column);
        }
    }
    //END ADD COLUMN

    //Model columns selector dialo
    const selectingModelColumns = ref<boolean>(false);
    //END Model columns selector dialog

    const unselectColumn = (element) => {
        if(!sheetDefinition.columns){
            return;
        }

        sheetDefinition.removeColumnInPosition(element.position);
    };

    //limit columns
    const showAllColumns = ref<boolean>(false);
    const limitColumns = 5;
    //limit columns

    //search columns
    const searchColumnQ = ref<string>('');

    watch(() => searchColumnQ.value, (value, oldValue) => {
        if(value !== ''){
            isDraggable.value = false;
        }
    });

    const getFilteredColumns = (limit = true): IModelDefinitionColumn[] | undefined => {
        let filteredColumns;
        if(searchColumnQ.value){
            filteredColumns = sheetDefinition.getColumnsSortByPosition().filter((column) => {
                return column.title.toLowerCase().includes(searchColumnQ.value.toLowerCase()) || column.joinData?.toLowerCase().includes(searchColumnQ.value.toLowerCase() || column.alias?.toLowerCase().includes(searchColumnQ.value.toLowerCase()));
            });
        }else{
            filteredColumns = sheetDefinition.getColumnsSortByPosition();
        }

        if(!showAllColumns.value && limit){
            filteredColumns = filteredColumns.slice(0,limitColumns);
        }

        return filteredColumns;
    }
    //serach columns


    //Draggable
    const onAfterColDrag = (val: any, oldVal: any) => {
        val.forEach((column: any, index: number) => {
           sheetDefinition.moveColumnToPosition(column.position, index);
        });
    };

    const isDraggable = ref<boolean>(false);
    //end draggable
</script>

<template>
    <div>
        <div class="columns-editor-title">
            <b>
                Sloupce v listu (<span v-if="!searchColumnQ">{{ sheetDefinition.columns?.length }}</span><span v-else>{{ getFilteredColumns()?.length }} z {{ sheetDefinition.columns?.length }}</span>)
            </b>
        </div>
        <div class="columns-editor-tools">
            <div>
                <Button @click="addingColumn = true;" variant="green" icon="plus">Přidat sloupec</Button>
                <!-- @vue-ignore -->
                <SheetDefinitionColumnEditorDialog v-if="addingColumn" :show="addingColumn" @close="addingColumn = false; editingColumn = null;" @save="addColumn" :column="editingColumn"></SheetDefinitionColumnEditorDialog>
                <Button v-if="modelDefinition.isLoaded" @click="selectingModelColumns = true;" variant="green" icon="plus">Přidat sloupce z data modelu</Button>
                <ModelColumnsSelectorDialog
                    v-if="selectingModelColumns"
                    :show="selectingModelColumns"
                    :model-definition="modelDefinition"
                    @close="selectingModelColumns = false"
                    @save="saveModelsColumnSelector"
                >
                </ModelColumnsSelectorDialog>
                <Button
                    v-if="!isDraggable"
                    variant="green"
                    @click="isDraggable = true"
                >Změna pozice ON
                </Button>
                <Button
                    v-else
                    variant="red"
                    @click="isDraggable = false"
                >Změna pozice OFF
                </Button>
            </div>
            <div v-if="sheetDefinition.columns?.length" class="search-wrapper">
                <div v-if="searchColumnQ" class="mr-2">
                    Sloupců: [{{ getFilteredColumns()?.length }} z {{ sheetDefinition.columns?.length }}]
                </div>
                <InputField
                    v-model="searchColumnQ"
                    placeholder="Vyhledávání..."
                    class="mr-1"
                    variant="fancy"
                    icon="search"
                    size="large"
                />
<!--                <Button @click="searchColumnQ = ''; showAllColumns = false;" variant="red" size="extra-small">X</Button>-->
            </div>
        </div>
        <div class="mt-2">
            <Table class="relative">
                <colgroup>
                    <col width="150">
                    <col width="150">
                    <col width="150">
                    <col width="150">
                    <col width="400">
                    <col width="400">
                    <col width="150">
                </colgroup>
                <Thead>
                    <TheadTr>
                        <TheadTh>Data</TheadTh>
                        <TheadTh>Název</TheadTh>
                        <TheadTh>Alias</TheadTh>
                        <TheadTh>Typ sloupce</TheadTh>
                        <TheadTh>Defaultní hodnota</TheadTh>
                        <TheadTh>Formátování</TheadTh>
                        <TheadTh></TheadTh>
                    </TheadTr>
                </Thead>
                <template v-if="getFilteredColumns()?.length">
                    <Tbody
                       :draggable="isDraggable"
                       :list="getFilteredColumns()"
                       @on-after-drag="onAfterColDrag"
                    >
                        <template v-slot="{ element }">
                            <TbodyTd><HighlightText :highlight="searchColumnQ" :text="element.joinData"></HighlightText></TbodyTd>
                            <TbodyTd><HighlightText :highlight="searchColumnQ" :text="element.title"></HighlightText></TbodyTd>
                            <TbodyTd><HighlightText :highlight="searchColumnQ" :text="element.alias"></HighlightText></TbodyTd>
                            <TbodyTd>{{element.type}}</TbodyTd>
                            <TbodyTd>
                                <InputField
                                    v-model="element.defaultValue"
                                    placeholder="Defaultní hodnota"
                                    class="w-[350px]"
                                />
                            </TbodyTd>
                            <TbodyTd>
                                <InputField
                                    v-model="element.formating"
                                    placeholder="Formátování"
                                    class="w-[350px]"
                                />
                            </TbodyTd>
                            <TbodyTd>
                                <div class="tw-flex">
                                    <Button @click="addingColumn = true; editingColumn = element;" variant="orange" size="small" icon="edit"></Button>
                                    <Button @click="unselectColumn(element)" variant="red" size="small" icon="delete"></Button>
                                </div>
                            </TbodyTd>
                        </template>
                    </Tbody>
                    <Tfoot v-if="getFilteredColumns(false).length > limitColumns && !showAllColumns" @click="showAllColumns = true">
                        <TfootTr class="column-table-footer">
                            <TfootTd :colspan="7" class="">
                                Zobrazit více (+{{sheetDefinition.columns.length - 5}})
                            </TfootTd>
                        </TfootTr>
                    </Tfoot>
                    <Tfoot v-if="getFilteredColumns(false).length > limitColumns && showAllColumns" @click="showAllColumns = false">
                        <TfootTr class="column-table-footer">
                            <TfootTd :colspan="7" class="">
                                Zobrazit méně
                            </TfootTd>
                        </TfootTr>
                    </Tfoot>
                </template>
                <Tbody v-else>
                    <TbodyTr>
                        <TbodyTd :colspan="7" class="no-row">
                            <h2>Žádný sloupec</h2>
                        </TbodyTd>
                    </TbodyTr>
                </Tbody>
            </Table>
        </div>
    </div>
</template>

<style scoped>
    .columns-editor-tools{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
        margin-top: 14px;

        .search-wrapper{
            display: flex;
            align-items: center;
        }
    }

    .columns-editor-title{
        margin-top: 36px;
    }

    .no-row{
        text-align: center;
        opacity: 0.8;
    }

    .column-table-footer{
        text-align: center;
        border-top: 3px solid #ECF2FF;

        &:hover{
            cursor: pointer;
            background: #f1f1f1;
        }

        th{
            padding: 10px;
            font-size: 14px;
            font-weight: 500;
        }

    }
</style>