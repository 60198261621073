<script setup>
    import { computed} from "vue";

    const props = defineProps({
        highlight: String,
        text: String,
    });

    const escapeRegExp = (string) => {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    };

    const highlightedText = computed(() => {
        if(!props.text){
            return "";
        }

        if (!props.highlight) {
            return props.text;
        }

        const regex = new RegExp(escapeRegExp(props.highlight), 'gi');

        return props.text.replace(regex, (match) => {
            return `<span class="highlighted-text" style="background-color: yellow; color: red !important;">${match}</span>`;
        });
    });

</script>

<template>
    <div v-html="highlightedText"></div>
</template>

<style scoped>
    .highlighted-text {
        background-color: yellow;
        color: red;
    }
</style>