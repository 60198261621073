export const FORMULAS = {
    "Array manipulation": {
        "ARRAYFORMULA": {
            "syntax": "ARRAYFORMULA(Formula)",
            "description": "Enables the array arithmetic mode for a single formula."
        },
        "FILTER": {
            "syntax": "FILTER(SourceArray, BoolArray1, BoolArray2, ...BoolArrayN)",
            "description": "Filters an array, based on multiple conditions (boolean arrays)."
        },
        "ARRAY_CONSTRAIN": {
            "syntax": "ARRAY_CONSTRAIN(Array, Height, Width)",
            "description": "Truncates an array to given dimensions."
        }
    },
    "Date and time": {
        "DATE": {
            "syntax": "DATE(Year, Month, Day)",
            "description": "Returns the specified date as the number of full days sincenullDate."
        },
        "DATEDIF": {
            "syntax": "DATEDIF(Date1, Date2, Units)",
            "description": "Calculates distance between two dates, in provided unit parameter."
        },
        "DATEVALUE": {
            "syntax": "DATEVALUE(Datestring)",
            "description": "Parses a date string and returns it as the number of full days sincenullDate.Accepts formats set by thedateFormatsoption."
        },
        "DAY": {
            "syntax": "DAY(Number)",
            "description": "Returns the day of the given date value."
        },
        "DAYS": {
            "syntax": "DAYS(Date2, Date1)",
            "description": "Calculates the difference between two date values."
        },
        "DAYS360": {
            "syntax": "DAYS360(Date2, Date1[, Format])",
            "description": "Calculates the difference between two date values in days, in 360-day basis."
        },
        "EDATE": {
            "syntax": "EDATE(Startdate, Months)",
            "description": "Shifts the given startdate by given number of months and returns it as the number of full days sincenullDate.[1]"
        },
        "EOMONTH": {
            "syntax": "EOMONTH(Startdate, Months)",
            "description": "Returns the date of the last day of a month which falls months away from the start date. Returns the value in the form of number of full days sincenullDate.[1:1]"
        },
        "HOUR": {
            "syntax": "HOUR(Time)",
            "description": "Returns hour component of given time."
        },
        "INTERVAL": {
            "syntax": "INTERVAL(Seconds)",
            "description": "Returns interval string from given number of seconds."
        },
        "ISOWEEKNUM": {
            "syntax": "ISOWEEKNUM(Date)",
            "description": "Returns an ISO week number that corresponds to the week of year."
        },
        "MINUTE": {
            "syntax": "MINUTE(Time)",
            "description": "Returns minute component of given time."
        },
        "MONTH": {
            "syntax": "MONTH(Number)",
            "description": "Returns the month for the given date value."
        },
        "NETWORKDAYS": {
            "syntax": "NETWORKDAYS(Date1, Date2[, Holidays])",
            "description": "Returns the number of working days between two given dates."
        },
        "NETWORKDAYS.INTL": {
            "syntax": "NETWORKDAYS.INTL(Date1, Date2[, Mode [, Holidays]])",
            "description": "Returns the number of working days between two given dates."
        },
        "NOW": {
            "syntax": "NOW()",
            "description": "Returns current date + time as a number of days sincenullDate."
        },
        "SECOND": {
            "syntax": "SECOND(Time)",
            "description": "Returns second component of given time."
        },
        "TIME": {
            "syntax": "TIME(Hour, Minute, Second)",
            "description": "Returns the number that represents a given time as a fraction of full day."
        },
        "TIMEVALUE": {
            "syntax": "TIMEVALUE(Timestring)",
            "description": "Parses a time string and returns a number that represents it as a fraction of a full day.Accepts formats set by thetimeFormatsoption."
        },
        "TODAY": {
            "syntax": "TODAY()",
            "description": "Returns an integer representing the current date as the number of full days sincenullDate."
        },
        "WEEKDAY": {
            "syntax": "WEEKDAY(Date, Type)",
            "description": "Computes a number between 1-7 representing the day of week."
        },
        "WEEKNUM": {
            "syntax": "WEEKNUM(Date, Type)",
            "description": "Returns a week number that corresponds to the week of year."
        },
        "WORKDAY": {
            "syntax": "WORKDAY(Date, Shift[, Holidays])",
            "description": "Returns the working day number of days from start day."
        },
        "WORKDAY.INTL": {
            "syntax": "WORKDAY(Date, Shift[, Mode[, Holidays]])",
            "description": "Returns the working day number of days from start day."
        },
        "YEAR": {
            "syntax": "YEAR(Number)",
            "description": "Returns the year as a number according to the internal calculation rules."
        },
        "YEARFRAC": {
            "syntax": "YEARFRAC(Date2, Date1[, Format])",
            "description": "Computes the difference between two date values, in fraction of years."
        }
    },
    "Engineering": {
        "BIN2DEC": {
            "syntax": "BIN2DEC(Number)",
            "description": "The result is the decimal number for the binary number entered."
        },
        "BIN2HEX": {
            "syntax": "BIN2HEX(Number, Places)",
            "description": "The result is the hexadecimal number for the binary number entered."
        },
        "BIN2OCT": {
            "syntax": "BIN2OCT(Number, Places)",
            "description": "The result is the octal number for the binary number entered."
        },
        "BITAND": {
            "syntax": "BITAND(Number1, Number2)",
            "description": "Returns a bitwise logical \"and\" of the parameters."
        },
        "BITLSHIFT": {
            "syntax": "BITLSHIFT(Number, Shift)",
            "description": "Shifts a number left by n bits."
        },
        "BITOR": {
            "syntax": "BITOR(Number1, Number2)",
            "description": "Returns a bitwise logical \"or\" of the parameters."
        },
        "BITRSHIFT": {
            "syntax": "BITRSHIFT(Number, Shift)",
            "description": "Shifts a number right by n bits."
        },
        "BITXOR": {
            "syntax": "BITXOR(Number1, Number2)",
            "description": "Returns a bitwise logical \"exclusive or\" of the parameters."
        },
        "COMPLEX": {
            "syntax": "COMPLEX(Re, Im[, Symbol])",
            "description": "Returns complex number from Re and Im parts."
        },
        "DEC2BIN": {
            "syntax": "DEC2BIN(Number, Places)",
            "description": "Returns the binary number for the decimal number entered between –512 and 511."
        },
        "DEC2HEX": {
            "syntax": "DEC2HEX(Number, Places)",
            "description": "Returns the hexadecimal number for the decimal number entered."
        },
        "DEC2OCT": {
            "syntax": "DEC2OCT(Number, Places)",
            "description": "Returns the octal number for the decimal number entered."
        },
        "DELTA": {
            "syntax": "DELTA(Number_1, Number_2)",
            "description": "Returns TRUE (1) if both numbers are equal, otherwise returns FALSE (0)."
        },
        "ERF": {
            "syntax": "ERF(Lower_Limit, Upper_Limit)",
            "description": "Returns values of the Gaussian error integral."
        },
        "ERFC": {
            "syntax": "ERFC(Lower_Limit)",
            "description": "Returns complementary values of the Gaussian error integral between x and infinity."
        },
        "HEX2BIN": {
            "syntax": "HEX2BIN(Number, Places)",
            "description": "The result is the binary number for the hexadecimal number entered."
        },
        "HEX2DEC": {
            "syntax": "HEX2DEC(Number)",
            "description": "The result is the decimal number for the hexadecimal number entered."
        },
        "HEX2OCT": {
            "syntax": "HEX2OCT(Number, Places)",
            "description": "The result is the octal number for the hexadecimal number entered."
        },
        "IMABS": {
            "syntax": "IMABS(Complex)",
            "description": "Returns module of a complex number."
        },
        "IMAGINARY": {
            "syntax": "IMAGINARY(Complex)",
            "description": "Returns imaginary part of a complex number."
        },
        "IMARGUMENT": {
            "syntax": "IMARGUMENT(Complex)",
            "description": "Returns argument of a complex number."
        },
        "IMCONJUGATE": {
            "syntax": "IMCONJUGATE(Complex)",
            "description": "Returns conjugate of a complex number."
        },
        "IMCOS": {
            "syntax": "IMCOS(Complex)",
            "description": "Returns cosine of a complex number."
        },
        "IMCOSH": {
            "syntax": "IMCOSH(Complex)",
            "description": "Returns hyperbolic cosine of a complex number."
        },
        "IMCOT": {
            "syntax": "IMCOT(Complex)",
            "description": "Returns cotangens of a complex number."
        },
        "IMCSC": {
            "syntax": "IMCSC(Complex)",
            "description": "Returns cosecans of a complex number."
        },
        "IMCSCH": {
            "syntax": "IMCSCH(Complex)",
            "description": "Returns hyperbolic cosecans of a complex number."
        },
        "IMDIV": {
            "syntax": "IMDIV(Complex1, Complex2)",
            "description": "Divides two complex numbers."
        },
        "IMEXP": {
            "syntax": "IMEXP(Complex)",
            "description": "Returns exponent of a complex number."
        },
        "IMLN": {
            "syntax": "IMLN(Complex)",
            "description": "Returns natural logarithm of a complex number."
        },
        "IMLOG2": {
            "syntax": "IMLOG2(Complex)",
            "description": "Returns binary logarithm of a complex number."
        },
        "IMLOG10": {
            "syntax": "IMLOG10(Complex)",
            "description": "Returns base-10 logarithm of a complex number."
        },
        "IMPOWER": {
            "syntax": "IMPOWER(Complex, Number)",
            "description": "Returns a complex number raised to a given power."
        },
        "IMPRODUCT": {
            "syntax": "IMPRODUCT(Complex1, Complex2, ...ComplexN)",
            "description": "Multiplies complex numbers."
        },
        "IMREAL": {
            "syntax": "IMREAL(Complex)",
            "description": "Returns real part of a complex number."
        },
        "IMSEC": {
            "syntax": "IMSEC(Complex)",
            "description": "Returns the secant of a complex number."
        },
        "IMSECH": {
            "syntax": "IMSECH(Complex)",
            "description": "Returns the hyperbolic secant of a complex number."
        },
        "IMSIN": {
            "syntax": "IMSIN(Complex)",
            "description": "Returns sine of a complex number."
        },
        "IMSINH": {
            "syntax": "IMSINH(Complex)",
            "description": "Returns hyperbolic sine of a complex number."
        },
        "IMSQRT": {
            "syntax": "IMSQRT(Complex)",
            "description": "Returns a square root of a complex number."
        },
        "IMSUB": {
            "syntax": "IMSUB(Complex1, Complex2)",
            "description": "Subtracts two complex numbers."
        },
        "IMSUM": {
            "syntax": "IMSUM(Complex1, Complex2, ..ComplexN)",
            "description": "Adds complex numbers."
        },
        "IMTAN": {
            "syntax": "IMTAN(Complex)",
            "description": "Returns the tangent of a complex number."
        },
        "OCT2BIN": {
            "syntax": "OCT2BIN(Number, Places)",
            "description": "The result is the binary number for the octal number entered."
        },
        "OCT2DEC": {
            "syntax": "OCT2DEC(Number)",
            "description": "The result is the decimal number for the octal number entered."
        },
        "OCT2HEX": {
            "syntax": "OCT2HEX(Number, Places)",
            "description": "The result is the hexadecimal number for the octal number entered."
        }
    },
    "Information": {
        "ISBINARY": {
            "syntax": "ISBINARY(Value)",
            "description": "Returns TRUE if provided value is a valid binary number."
        },
        "ISBLANK": {
            "syntax": "ISBLANK(Value)",
            "description": "Returns TRUE if the reference to a cell is blank."
        },
        "ISERR": {
            "syntax": "ISERR(Value)",
            "description": "Returns TRUE if the value is error value except #N/A!."
        },
        "ISERROR": {
            "syntax": "ISERROR(Value)",
            "description": "Returns TRUE if the value is general error value."
        },
        "ISEVEN": {
            "syntax": "ISEVEN(Value)",
            "description": "Returns TRUE if the value is an even integer, or FALSE if the value is odd."
        },
        "ISFORMULA": {
            "syntax": "ISFORMULA(Value)",
            "description": "Checks whether referenced cell is a formula."
        },
        "ISLOGICAL": {
            "syntax": "ISLOGICAL(Value)",
            "description": "Tests for a logical value (TRUE or FALSE)."
        },
        "ISNA": {
            "syntax": "ISNA(Value)",
            "description": "Returns TRUE if the value is #N/A! error."
        },
        "ISNONTEXT": {
            "syntax": "ISNONTEXT(Value)",
            "description": "Tests if the cell contents are text or numbers, and returns FALSE if the contents are text."
        },
        "ISNUMBER": {
            "syntax": "ISNUMBER(Value)",
            "description": "Returns TRUE if the value refers to a number."
        },
        "ISODD": {
            "syntax": "ISODD(Value)",
            "description": "Returns TRUE if the value is odd, or FALSE if the number is even."
        },
        "ISREF": {
            "syntax": "ISREF(Value)",
            "description": "Returns TRUE if provided value is #REF! error."
        },
        "ISTEXT": {
            "syntax": "ISTEXT(Value)",
            "description": "Returns TRUE if the cell contents reference text."
        },
        "SHEET": {
            "syntax": "SHEET([Value])",
            "description": "Returns sheet number of a given value or a formula sheet number if no argument is provided."
        },
        "SHEETS": {
            "syntax": "SHEETS([Value])",
            "description": "Returns number of sheet of a given reference or number of all sheets in workbook when no argument is provided."
        },
        "NA": {
            "syntax": "NA(Value)",
            "description": "Returns #N/A! error value."
        }
    },
    "Financial": {
        "CUMIPMT": {
            "syntax": "CUMIPMT(Rate, Nper, Pv, Start, End, type)",
            "description": "Returns the cumulative interest paid on a loan between a start period and an end period."
        },
        "CUMPRINC": {
            "syntax": "CUMPRINC(Rate, Nper, Pv, Start, End, Type)",
            "description": "Returns the cumulative principal paid on a loan between a start period and an end period."
        },
        "DB": {
            "syntax": "DB(Cost, Salvage, Life, Period[, Month])",
            "description": "Returns the depreciation of an asset for a period using the fixed-declining balance method."
        },
        "DDB": {
            "syntax": "DDB(Cost, Salvage, Life, Period[, Factor])",
            "description": "Returns the depreciation of an asset for a period using the double-declining balance method."
        },
        "DOLLARDE": {
            "syntax": "DOLLARDE(Price, Fraction)",
            "description": "Converts a price entered with a special notation to a price displayed as a decimal number."
        },
        "DOLLARFR": {
            "syntax": "DOLLARFR(Price, Fraction)",
            "description": "Converts a price displayed as a decimal number to a price entered with a special notation."
        },
        "EFFECT": {
            "syntax": "EFFECT (Nominal_rate, Npery)",
            "description": "Calculates the effective annual interest rate from a nominal interest rate and the number of compounding periods per year."
        },
        "FV": {
            "syntax": "FV(Rate, Nper, Pmt[, Pv,[ Type]])",
            "description": "Returns the future value of an investment."
        },
        "FVSCHEDULE": {
            "syntax": "FV(Pv, Schedule)",
            "description": "Returns the future value of an investment based on a rate schedule."
        },
        "IPMT": {
            "syntax": "IPMT(Rate, Per, Nper, Pv[, Fv[, Type]])",
            "description": "Returns the interest portion of a given loan payment in a given payment period."
        },
        "ISPMT": {
            "syntax": "ISPMT(Rate, Per, Nper, Value)",
            "description": "Returns the interest paid for a given period of an investment with equal principal payments."
        },
        "MIRR": {
            "syntax": "MIRR(Flows, FRate, RRate)",
            "description": "Returns modified internal value for cashflows."
        },
        "NOMINAL": {
            "syntax": "NOMINAL(Effect_rate, Npery)",
            "description": "Returns the nominal interest rate."
        },
        "NPER": {
            "syntax": "NPER(Rate, Pmt, Pv[, Fv[, Type]])",
            "description": "Returns the number of periods for an investment assuming periodic, constant payments and a constant interest rate."
        },
        "NPV": {
            "syntax": "NPV(Rate, Value1, Value2, ...ValueN)",
            "description": "Returns net present value."
        },
        "PDURATION": {
            "syntax": "PDURATION(Rate, Pv, Fv)",
            "description": "Returns number of periods to reach specific value."
        },
        "PMT": {
            "syntax": "PMT(Rate, Nper, Pv[, Fv[, Type]])",
            "description": "Returns the periodic payment for a loan."
        },
        "PPMT": {
            "syntax": "PPMT(Rate, Per, Nper, Pv[, Fv[, Type]])",
            "description": "Calculates the principal portion of a given loan payment."
        },
        "PV": {
            "syntax": "PV(Rate, Nper, Pmt[, Fv[, Type]])",
            "description": "Returns the present value of an investment."
        },
        "RATE": {
            "syntax": "RATE(Nper, Pmt, Pv[, Fv[, Type[, guess]]])",
            "description": "Returns the interest rate per period of an annuity."
        },
        "RRI": {
            "syntax": "RRI(Nper, Pv, Fv)",
            "description": "Returns an equivalent interest rate for the growth of an investment."
        },
        "SLN": {
            "syntax": "SLN(Cost, Salvage, Life)",
            "description": "Returns the depreciation of an asset for one period, based on a straight-line method."
        },
        "SYD": {
            "syntax": "SYD(Cost, Salvage, Life, Period)",
            "description": "Returns the \"sum-of-years\" depreciation for an asset in a period."
        },
        "TBILLEQ": {
            "syntax": "TBILLEQ(Settlement, Maturity, Discount)",
            "description": "Returns the bond-equivalent yield for a Treasury bill."
        },
        "TBILLPRICE": {
            "syntax": "TBILLPRICE(Settlement, Maturity, Discount)",
            "description": "Returns the price per $100 face value for a Treasury bill."
        },
        "TBILLYIELD": {
            "syntax": "TBILLYIELD(Settlement, Maturity, Price)",
            "description": "Returns the yield for a Treasury bill."
        },
        "XNPV": {
            "syntax": "XNPV(Rate, Payments, Dates)",
            "description": "Returns net present value."
        }
    },
    "Logical": {
        "AND": {
            "syntax": "AND(Logical_value1, Logical_value2, ...Logical_valueN)",
            "description": "Returns TRUE if all arguments are TRUE."
        },
        "FALSE": {
            "syntax": "FALSE()",
            "description": "Returns the logical value FALSE."
        },
        "IF": {
            "syntax": "IF(Test, Then_value, Otherwise_value)",
            "description": "Specifies a logical test to be performed."
        },
        "IFS": {
            "syntax": "IFS(Condition1, Value1 [, Condition2, Value2 [, ...ConditionN, ValueN]])",
            "description": "Evaluates multiple logical tests and returns a value that corresponds to the first true condition."
        },
        "IFNA": {
            "syntax": "IFNA(Value, Alternate_value)",
            "description": "Returns the value if the cell does not contains the #N/A (value not available) error value, or the alternative value if it does."
        },
        "IFERROR": {
            "syntax": "IFERROR(Value, Alternate_value)",
            "description": "Returns the value if the cell does not contains an error value, or the alternative value if it does."
        },
        "NOT": {
            "syntax": "NOT(Logicalvalue)",
            "description": "Complements (inverts) a logical value."
        },
        "SWITCH": {
            "syntax": "SWITCH(Expression1, Value1 [, Expression2, Value2 [, ...ExpressionN, ValueN]])",
            "description": "Evaluates a list of arguments, consisting of an expression followed by a value."
        },
        "OR": {
            "syntax": "OR(Logical_value1, Logical_value2, ...Logical_valueN)",
            "description": "Returns TRUE if at least one argument is TRUE."
        },
        "TRUE": {
            "syntax": "TRUE()",
            "description": "The logical value is set to TRUE."
        },
        "XOR": {
            "syntax": "XOR(Logical_value1, Logical_value2, ...Logical_valueN)",
            "description": "Returns true if an odd number of arguments evaluates to TRUE."
        }
    },
    "Lookup and reference": {
        "ADDRESS": {
            "syntax": "ADDRESS(Row, Column[, AbsoluteRelativeMode[, UseA1Notation[, Sheet]]])",
            "description": "Returns a cell reference as a string."
        },
        "CHOOSE": {
            "syntax": "CHOOSE(Index, Value1, Value2, ...ValueN)",
            "description": "Uses an index to return a value from a list of values."
        },
        // "COLUMN": {
        //     "syntax": "COLUMNS([Reference])",
        //     "description": "Returns column number of a given reference or formula reference if argument not provided."
        // },
        // "COLUMNS": {
        //     "syntax": "COLUMNS(Array)",
        //     "description": "Returns the number of columns in the given reference."
        // },
        "FORMULATEXT": {
            "syntax": "FORMULATEXT(Reference)",
            "description": "Returns a formula in a given cell as a string."
        },
        "HLOOKUP": {
            "syntax": "HLOOKUP(Search_Criterion, Array, Index, Sort_Order)",
            "description": "Searches horizontally with reference to adjacent cells to the bottom."
        },
        "HYPERLINK": {
            "syntax": "HYPERLINK(Url[, LinkLabel])",
            "description": "Stores the url in the cell's metadata. It can be read using methodgetCellHyperlink"
        },
        "INDEX": {
            "syntax": "INDEX(Range, Row [, Column])",
            "description": "Returns the contents of a cell specified by row and column number. The column number is optional and defaults to 1."
        },
        "MATCH": {
            "syntax": "MATCH(Searchcriterion, Lookuparray [, MatchType])",
            "description": "Returns the relative position of an item in an array that matches a specified value."
        },
        "OFFSET": {
            "syntax": "OFFSET(Reference, Rows, Columns, Height, Width)",
            "description": "Returns the value of a cell offset by a certain number of rows and columns from a given reference point."
        },
        "ROW": {
            "syntax": "ROW([Reference])",
            "description": "Returns row number of a given reference or formula reference if argument not provided."
        },
        "ROWS": {
            "syntax": "ROWS(Array)",
            "description": "Returns the number of rows in the given reference."
        },
        "VLOOKUP": {
            "syntax": "VLOOKUP(Search_Criterion, Array, Index, Sort_Order)",
            "description": "Searches vertically with reference to adjacent cells to the right."
        }
    },
    "Math and trigonometry": {
        "ABS": {
            "syntax": "ABS(Number)",
            "description": "Returns the absolute value of a number."
        },
        "ACOS": {
            "syntax": "ACOS(Number)",
            "description": "Returns the inverse trigonometric cosine of a number."
        },
        "ACOSH": {
            "syntax": "ACOSH(Number)",
            "description": "Returns the inverse hyperbolic cosine of a number."
        },
        "ACOT": {
            "syntax": "ACOT(Number)",
            "description": "Returns the inverse trigonometric cotangent of a number."
        },
        "ACOTH": {
            "syntax": "ACOTH(Number)",
            "description": "Returns the inverse hyperbolic cotangent of a number."
        },
        "ARABIC": {
            "syntax": "ARABIC(String)",
            "description": "Converts number from roman form."
        },
        "ASIN": {
            "syntax": "ASIN(Number)",
            "description": "Returns the inverse trigonometric sine of a number."
        },
        "ASINH": {
            "syntax": "ASINH(Number)",
            "description": "Returns the inverse hyperbolic sine of a number."
        },
        "ATAN": {
            "syntax": "ATAN(Number)",
            "description": "Returns the inverse trigonometric tangent of a number."
        },
        "ATAN2": {
            "syntax": "ATAN2(Numberx, Numbery)",
            "description": "Returns the inverse trigonometric tangent of the specified x and y coordinates."
        },
        "ATANH": {
            "syntax": "ATANH(Number)",
            "description": "Returns the inverse hyperbolic tangent of a number."
        },
        "BASE": {
            "syntax": "BASE(Number, Radix, [Minimumlength])",
            "description": "Converts a positive integer to a specified base into a text from the numbering system."
        },
        "CEILING": {
            "syntax": "CEILING(Number, Significance)",
            "description": "Rounds a number up to the nearest multiple of Significance."
        },
        "CEILING.MATH": {
            "syntax": "CEILING.MATH(Number[, Significance[, Mode]])",
            "description": "Rounds a number up to the nearest multiple of Significance."
        },
        "CEILING.PRECISE": {
            "syntax": "CEILING.PRECISE(Number[, Significance])",
            "description": "Rounds a number up to the nearest multiple of Significance."
        },
        "COMBIN": {
            "syntax": "COMBIN(Number, Number)",
            "description": "Returns number of combinations (without repetitions)."
        },
        "COMBINA": {
            "syntax": "COMBINA(Number, Number)",
            "description": "Returns number of combinations (with repetitions)."
        },
        "COS": {
            "syntax": "COS(Number)",
            "description": "Returns the cosine of the given angle (in radians)."
        },
        "COSH": {
            "syntax": "COSH(Number)",
            "description": "Returns the hyperbolic cosine of the given value."
        },
        "COT": {
            "syntax": "COT(Number)",
            "description": "Returns the cotangent of the given angle (in radians)."
        },
        "COTH": {
            "syntax": "COTH(Number)",
            "description": "Returns the hyperbolic cotangent of the given value."
        },
        "COUNTUNIQUE": {
            "syntax": "COUNTUNIQUE(Value1, Value2, ...ValueN)",
            "description": "Counts the number of unique values in a list of specified values and ranges."
        },
        "CSC": {
            "syntax": "CSC(Number)",
            "description": "Returns the cosecans of the given angle (in radians)."
        },
        "CSCH": {
            "syntax": "CSCH(Number)",
            "description": "Returns the hyperbolic cosecant of the given value."
        },
        "DECIMAL": {
            "syntax": "DECIMAL(\"Text\", Radix)",
            "description": "Converts text with characters from a number system to a positive integer in the base radix given."
        },
        "DEGREES": {
            "syntax": "DEGREES(Number)",
            "description": "Converts radians into degrees."
        },
        "EVEN": {
            "syntax": "EVEN(Number)",
            "description": "Rounds a positive number up to the next even integer and a negative number down to the next even integer."
        },
        "EXP": {
            "syntax": "EXP(Number)",
            "description": "Returns constant e raised to the power of a number."
        },
        "FACT": {
            "syntax": "FACT(Number)",
            "description": "Returns a factorial of a number."
        },
        "FACTDOUBLE": {
            "syntax": "FACTDOUBLE(Number)",
            "description": "Returns a double factorial of a number."
        },
        "FLOOR": {
            "syntax": "FLOOR(Number, Significance)",
            "description": "Rounds a number down to the nearest multiple of Significance."
        },
        "FLOOR.MATH": {
            "syntax": "FLOOR.MATH(Number[, Significance[, Mode]])",
            "description": "Rounds a number down to the nearest multiple of Significance."
        },
        "FLOOR.PRECISE": {
            "syntax": "FLOOR.PRECISE(Number[, Significance])",
            "description": "Rounds a number down to the nearest multiple of Significance."
        },
        "GCD": {
            "syntax": "GCD(Number1, Number2, ...NumberN)",
            "description": "Computes greatest common divisor of numbers."
        },
        "INT": {
            "syntax": "INT(Number)",
            "description": "Rounds a number down to the nearest integer."
        },
        "ISO.CEILING": {
            "syntax": "ISO.CEILING(Number[, Significance])",
            "description": "Rounds a number up to the nearest multiple of Significance."
        },
        "LCM": {
            "syntax": "LCM(Number1, Number2, ...NumberN)",
            "description": "Computes least common multiplicity of numbers."
        },
        "LN": {
            "syntax": "LN(Number)",
            "description": "Returns the natural logarithm based on the constant e of a number."
        },
        "LOG": {
            "syntax": "LOG(Number, Base)",
            "description": "Returns the logarithm of a number to the specified base."
        },
        "LOG10": {
            "syntax": "LOG10(Number)",
            "description": "Returns the base-10 logarithm of a number."
        },
        "MOD": {
            "syntax": "MOD(Dividend, Divisor)",
            "description": "Returns the remainder when one integer is divided by another."
        },
        "MROUND": {
            "syntax": "MROUND(Number, Base)",
            "description": "Rounds number to the neares multiplicity."
        },
        "MULTINOMIAL": {
            "syntax": "MULTINOMIAL(Number1, Number2, ...NumberN)",
            "description": "Returns number of multiset combinations."
        },
        "ODD": {
            "syntax": "ODD(Number)",
            "description": "Rounds a positive number up to the nearest odd integer and a negative number down to the nearest odd integer."
        },
        "PI": {
            "syntax": "PI()",
            "description": "Returns 3.14159265358979, the value of the mathematical constant PI to 14 decimal places."
        },
        "POWER": {
            "syntax": "POWER(Base, Exponent)",
            "description": "Returns a number raised to another number."
        },
        "PRODUCT": {
            "syntax": "PRODUCT(Number1, Number2, ...NumberN)",
            "description": "Returns product of numbers."
        },
        "QUOTIENT": {
            "syntax": "QUOTIENT(Dividend, Divisor)",
            "description": "Returns integer part of a division."
        },
        "RADIANS": {
            "syntax": "RADIANS(Number)",
            "description": "Converts degrees to radians."
        },
        "RAND": {
            "syntax": "RAND()",
            "description": "Returns a random number between 0 and 1."
        },
        "RANDBETWEEN": {
            "syntax": "RAND(Lowerbound, Upperbound)",
            "description": "Returns a random integer between two numbers."
        },
        "ROMAN": {
            "syntax": "ROMAN(Number[, Mode])",
            "description": "Converts number to roman form."
        },
        "ROUND": {
            "syntax": "ROUND(Number, Count)",
            "description": "Rounds a number to a certain number of decimal places."
        },
        "ROUNDDOWN": {
            "syntax": "ROUNDDOWN(Number, Count)",
            "description": "Rounds a number down, toward zero, to a certain precision."
        },
        "ROUNDUP": {
            "syntax": "ROUNDUP(Number, Count)",
            "description": "Rounds a number up, away from zero, to a certain precision."
        },
        "SEC": {
            "syntax": "SEC(Number)",
            "description": "Returns the secant of the given angle (in radians)."
        },
        "SECH": {
            "syntax": "SEC(Number)",
            "description": "Returns the hyperbolic secant of the given angle (in radians)."
        },
        "SERIESSUM": {
            "syntax": "SERIESSUM(Number, Number, Number, Coefficients)",
            "description": "Evaluates series at a point."
        },
        "SIN": {
            "syntax": "SIN(Number)",
            "description": "Returns the sine of the given angle (in radians)."
        },
        "SINH": {
            "syntax": "SINH(Number)",
            "description": "Returns the hyperbolic sine of the given value."
        },
        "SIGN": {
            "syntax": "SIGN(Number)",
            "description": "Returns sign of a number."
        },
        "SQRT": {
            "syntax": "SQRT(Number)",
            "description": "Returns the positive square root of a number."
        },
        "SQRTPI": {
            "syntax": "SQRTPI(Number)",
            "description": "Returns sqrt of number times pi."
        },
        "SUBTOTAL": {
            "syntax": "SUBTOTAL(Function, Number1, Number2, ...NumberN)",
            "description": "Computes aggregation using function specified by number."
        },
        "SUM": {
            "syntax": "SUM(Number1, Number2, ...NumberN)",
            "description": "Sums up the values of the specified cells."
        },
        "SUMIF": {
            "syntax": "SUMIF(Range, Criteria, Sumrange)",
            "description": "Sums up the values of cells that belong to the specified range and meet the specified condition."
        },
        "SUMIFS": {
            "syntax": "SUMIFS(Sum_Range, Criterion_range1, Criterion1 [, Criterion_range2, Criterion2 [, ...Criterion_rangeN, CriterionN]])",
            "description": "Sums up the values of cells that belong to the specified range and meet the specified sets of conditions."
        },
        "SUMPRODUCT": {
            "syntax": "SUMPRODUCT(Array1, Array2, ...ArrayN)",
            "description": "Multiplies corresponding elements in the given arrays, and returns the sum of those products."
        },
        "SUMSQ": {
            "syntax": "SUMSQ(Number1, Number2, ...NumberN)",
            "description": "Returns the sum of the squares of the arguments"
        },
        "SUMX2MY2": {
            "syntax": "SUMX2MY2(Range1, Range2)",
            "description": "Returns the sum of the square differences."
        },
        "SUMX2PY2": {
            "syntax": "SUMX2PY2(Range1, Range2)",
            "description": "Returns the sum of the square sums."
        },
        "SUMXMY2": {
            "syntax": "SUMXMY2(Range1, Range2)",
            "description": "Returns the sum of the square of differences."
        },
        "TAN": {
            "syntax": "TAN(Number)",
            "description": "Returns the tangent of the given angle (in radians)."
        },
        "TANH": {
            "syntax": "TANH(Number)",
            "description": "Returns the hyperbolic tangent of the given value."
        },
        "TRUNC": {
            "syntax": "TRUNC(Number, Count)",
            "description": "Truncates a number by removing decimal places."
        }
    },
    "Matrix functions": {
        "MMULT": {
            "syntax": "MMULT(Array, Array)",
            "description": "Calculates the array product of two arrays."
        },
        "MEDIANPOOL": {
            "syntax": "MEDIANPOOL(Range, Window_size, Stride)",
            "description": "Calculates a smaller range which is a median of a Window_size, in a given Range, for every Stride element."
        },
        "MAXPOOL": {
            "syntax": "MAXPOOL(Range, Window_size, Stride)",
            "description": "Calculates a smaller range which is a maximum of a Window_size, in a given Range, for every Stride element."
        },
        "TRANSPOSE": {
            "syntax": "TRANSPOSE(Array)",
            "description": "Transposes the rows and columns of an array."
        }
    },
    "Operator": {
        "HF.ADD": {
            "syntax": "HF.ADD(Number, Number)",
            "description": "Adds two values."
        },
        "HF.CONCAT": {
            "syntax": "HF.CONCAT(String, String)",
            "description": "Concatenates two strings."
        },
        "HF.DIVIDE": {
            "syntax": "HF.DIVIDE(Number, Number)",
            "description": "Divides two values."
        },
        "HF.EQ": {
            "syntax": "HF.EQ(Value, Value)",
            "description": "Tests two values for equality."
        },
        "HF.LTE": {
            "syntax": "HF.LEQ(Value, Value)",
            "description": "Tests two values for less-equal relation."
        },
        "HF.LT": {
            "syntax": "HF.LT(Value, Value)",
            "description": "Tests two values for less-than relation."
        },
        "HF.GTE": {
            "syntax": "HF.GEQ(Value, Value)",
            "description": "Tests two values for greater-equal relation."
        },
        "HF.GT": {
            "syntax": "HF.GT(Value, Value)",
            "description": "Tests two values for greater-than relation."
        },
        "HF.MINUS": {
            "syntax": "HF.MINUS(Number, Number)",
            "description": "Subtracts two values."
        },
        "HF.MULTIPLY": {
            "syntax": "HF.MULTIPLY(Number, Number)",
            "description": "Multiplies two values."
        },
        "HF.NE": {
            "syntax": "HF.NE(Value, Value)",
            "description": "Tests two values for inequality."
        },
        "HF.POW": {
            "syntax": "HF.POW(Number, Number)",
            "description": "Computes power of two values."
        },
        "HF.UMINUS": {
            "syntax": "HF.UMINUS(Number)",
            "description": "Negates the value."
        },
        "HF.UNARY_PERCENT": {
            "syntax": "HF.UNARY_PERCENT(Number)",
            "description": "Applies percent operator."
        },
        "HF.UPLUS": {
            "syntax": "HF.UPLUS(Number)",
            "description": "Applies unary plus."
        }
    },
    "Statistical": {
        "AVEDEV": {
            "syntax": "AVEDEV(Number1, Number2, ...NumberN)",
            "description": "Returns the average deviation of the arguments."
        },
        "AVERAGE": {
            "syntax": "AVERAGE(Number1, Number2, ...NumberN)",
            "description": "Returns the average of the arguments."
        },
        "AVERAGEA": {
            "syntax": "AVERAGEA(Value1, Value2, ...ValueN)",
            "description": "Returns the average of the arguments."
        },
        "AVERAGEIF": {
            "syntax": "AVERAGEIF(Range, Criterion [, Average_Range ])",
            "description": "Returns the arithmetic mean of all cells in a range that satisfy a given condition."
        },
        "BESSELI": {
            "syntax": "BESSELI(x, n)",
            "description": "Returns value of Bessel function."
        },
        "BESSELJ": {
            "syntax": "BESSELJ(x, n)",
            "description": "Returns value of Bessel function."
        },
        "BESSELK": {
            "syntax": "BESSELK(x, n)",
            "description": "Returns value of Bessel function."
        },
        "BESSELY": {
            "syntax": "BESSELY(x, n)",
            "description": "Returns value of Bessel function."
        },
        "BETA.DIST": {
            "syntax": "BETA.DIST(Number1, Number2, Number3, Boolean[, Number4[, Number5]])",
            "description": "Returns the denisty of Beta distribution."
        },
        "BETADIST": {
            "syntax": "BETADIST(Number1, Number2, Number3, Boolean[, Number4[, Number5]])",
            "description": "Returns the denisty of Beta distribution."
        },
        "BETA.INV": {
            "syntax": "BETA.INV(Number1, Number2, Number3[, Number4[, Number5]])",
            "description": "Returns the inverse Beta distribution value."
        },
        "BETAINV": {
            "syntax": "BETAINV(Number1, Number2, Number3[, Number4[, Number5]])",
            "description": "Returns the inverse of Beta distribution value."
        },
        "BINOM.DIST": {
            "syntax": "BINOM.DIST(Number1, Number2, Number3, Boolean)",
            "description": "Returns density of binomial distribution."
        },
        "BINOMDIST": {
            "syntax": "BINOMDIST(Number1, Number2, Number3, Boolean)",
            "description": "Returns density of binomial distribution."
        },
        "BINOM.INV": {
            "syntax": "BINOM.INV(Number1, Number2, Number3)",
            "description": "Returns inverse binomial distribution value."
        },
        "CHIDIST": {
            "syntax": "CHIDIST(X, Degrees)",
            "description": "Returns probability of chi-square right-side distribution."
        },
        "CHIINV": {
            "syntax": "CHIINV(P, Degrees)",
            "description": "Returns inverse of chi-square right-side distribution."
        },
        "CHIINVRT": {
            "syntax": "CHIINVRT(P, Degrees)",
            "description": "Returns inverse of chi-square right-side distribution."
        },
        "CHISQ.DIST": {
            "syntax": "CHISQ.DIST(X, Degrees, Mode)",
            "description": "Returns value of chi-square distribution."
        },
        "CHIDISTRT": {
            "syntax": "CHIDISTRT(X, Degrees)",
            "description": "Returns probability of chi-square right-side distribution."
        },
        "CHISQ.DIST.RT": {
            "syntax": "CHISQ.DIST.RT(X, Degrees)",
            "description": "Returns probability of chi-square right-side distribution."
        },
        "CHISQ.INV": {
            "syntax": "CHISQ.INV.RT(P, Degrees)",
            "description": "Returns inverse of chi-square distribution."
        },
        "CHISQ.INV.RT": {
            "syntax": "CHISQ.INV.RT(P, Degrees)",
            "description": "Returns inverse of chi-square right-side distribution."
        },
        "CHISQ.TEST": {
            "syntax": "CHISQ.TEST(Array1, Array2)",
            "description": "Returns chisquared-test value for a dataset."
        },
        "CHITEST": {
            "syntax": "CHITEST(Array1, Array2)",
            "description": "Returns chisquared-test value for a dataset."
        },
        "CONFIDENCE": {
            "syntax": "CONFIDENCE(Alpha, Stdev, Size)",
            "description": "Returns upper confidence bound for normal distribution."
        },
        "CONFIDENCE.NORM": {
            "syntax": "CONFIDENCE.NORM(Alpha, Stdev, Size)",
            "description": "Returns upper confidence bound for normal distribution."
        },
        "CONFIDENCE.T": {
            "syntax": "CONFIDENCE.T(Alpha, Stdev, Size)",
            "description": "Returns upper confidence bound for T distribution."
        },
        "CORREL": {
            "syntax": "CORREL(Data1, Data2)",
            "description": "Returns the correlation coefficient between two data sets."
        },
        "COUNT": {
            "syntax": "COUNT(Value1, Value2, ...ValueN)",
            "description": "Counts how many numbers are in the list of arguments."
        },
        "COUNTA": {
            "syntax": "COUNTA(Value1, Value2, ...ValueN)",
            "description": "Counts how many values are in the list of arguments."
        },
        "COUNTBLANK": {
            "syntax": "COUNTBLANK(Range)",
            "description": "Returns the number of empty cells."
        },
        "COUNTIF": {
            "syntax": "COUNTIF(Range, Criteria)",
            "description": "Returns the number of cells that meet with certain criteria within a cell range."
        },
        "COUNTIFS": {
            "syntax": "COUNTIFS(Range1, Criterion1 [, Range2, Criterion2 [, ...RangeN, CriterionN]])",
            "description": "Returns the count of rows or columns that meet criteria in multiple ranges."
        },
        "COVAR": {
            "syntax": "COVAR(Data1, Data2)",
            "description": "Returns the covariance between two data sets, population normalized."
        },
        "COVARIANCE.P": {
            "syntax": "COVARIANCE.P(Data1, Data2)",
            "description": "Returns the covariance between two data sets, population normalized."
        },
        "COVARIANCEP": {
            "syntax": "COVARIANCEP(Data1, Data2)",
            "description": "Returns the covariance between two data sets, population normalized."
        },
        "COVARIANCE.S": {
            "syntax": "COVARIANCE.S(Data1, Data2)",
            "description": "Returns the covariance between two data sets, sample normalized."
        },
        "COVARIANCES": {
            "syntax": "COVARIANCES(Data1, Data2)",
            "description": "Returns the covariance between two data sets, sample normalized."
        },
        "CRITBINOM": {
            "syntax": "CRITBINOM(Number1, Number2, Number3)",
            "description": "Returns inverse binomial distribution value."
        },
        "DEVSQ": {
            "syntax": "DEVSQ(Number1, Number2, ...NumberN)",
            "description": "Returns sum of squared deviations."
        },
        "EXPON.DIST": {
            "syntax": "EXPON.DIST(Number1, Number2, Boolean)",
            "description": "Returns density of a exponential distribution."
        },
        "EXPONDIST": {
            "syntax": "EXPONDIST(Number1, Number2, Boolean)",
            "description": "Returns density of a exponential distribution."
        },
        "FDIST": {
            "syntax": "FDIST(X, Degree1, Degree2)",
            "description": "Returns probability of F right-side distribution."
        },
        "FINV": {
            "syntax": "FINV(P, Degree1, Degree2)",
            "description": "Returns inverse of F right-side distribution."
        },
        "F.DIST": {
            "syntax": "F.DIST(X, Degree1, Degree2, Mode)",
            "description": "Returns value of F distribution."
        },
        "F.DIST.RT": {
            "syntax": "F.DIST.RT(X, Degree1, Degree2)",
            "description": "Returns probability of F right-side distribution."
        },
        "FDISTRT": {
            "syntax": "FDISTRT(X, Degree1, Degree2)",
            "description": "Returns probability of F right-side distribution."
        },
        "F.INV": {
            "syntax": "F.INV.RT(P, Degree1, Degree2)",
            "description": "Returns inverse of F distribution."
        },
        "F.INV.RT": {
            "syntax": "F.INV.RT(P, Degree1, Degree2)",
            "description": "Returns inverse of F right-side distribution."
        },
        "FINVRT": {
            "syntax": "FINVRT(P, Degree1, Degree2)",
            "description": "Returns inverse of F right-side distribution."
        },
        "FISHER": {
            "syntax": "FISHER(Number)",
            "description": "Returns Fisher transformation value."
        },
        "FISHERINV": {
            "syntax": "FISHERINV(Number)",
            "description": "Returns inverse Fischer transformation value."
        },
        "F.TEST": {
            "syntax": "Z.TEST(Array1, Array2)",
            "description": "Returns f-test value for a dataset."
        },
        "FTEST": {
            "syntax": "ZTEST(Array1, Array2)",
            "description": "Returns f-test value for a dataset."
        },
        "GAMMA": {
            "syntax": "GAMMA(Number)",
            "description": "Returns value of Gamma function."
        },
        "GAMMA.DIST": {
            "syntax": "GAMMA.DIST(Number1, Number2, Number3, Boolean)",
            "description": "Returns density of Gamma distribution."
        },
        "GAMMADIST": {
            "syntax": "GAMMADIST(Number1, Number2, Number3, Boolean)",
            "description": "Returns density of Gamma distribution."
        },
        "GAMMALN": {
            "syntax": "GAMMALN(Number)",
            "description": "Returns natural logarithm of Gamma function."
        },
        "GAMMALN.PRECISE": {
            "syntax": "GAMMALN.PRECISE(Number)",
            "description": "Returns natural logarithm of Gamma function."
        },
        "GAMMA.INV": {
            "syntax": "GAMMA.INV(Number1, Number2, Number3)",
            "description": "Returns inverse Gamma distribution value."
        },
        "GAMMAINV": {
            "syntax": "GAMMAINV(Number1, Number2, Number3)",
            "description": "Returns inverse Gamma distribution value."
        },
        "GAUSS": {
            "syntax": "GAUSS(Number)",
            "description": "Returns the probability of gaussian variable fall more than this many times standard deviation from mean."
        },
        "GEOMEAN": {
            "syntax": "GEOMEAN(Number1, Number2, ...NumberN)",
            "description": "Returns the geometric average."
        },
        "HARMEAN": {
            "syntax": "HARMEAN(Number1, Number2, ...NumberN)",
            "description": "Returns the harmonic average."
        },
        "HYPGEOMDIST": {
            "syntax": "HYPGEOMDIST(Number1, Number2, Number3, Number4, Boolean)",
            "description": "Returns density of hypergeometric distribution."
        },
        "HYPGEOM.DIST": {
            "syntax": "HYPGEOM.DIST(Number1, Number2, Number3, Number4, Boolean)",
            "description": "Returns density of hypergeometric distribution."
        },
        "LARGE": {
            "syntax": "LARGE(Range, K)",
            "description": "Returns k-th largest value in a range."
        },
        "LOGNORM.DIST": {
            "syntax": "LOGNORM.DIST(X, Mean, Stddev, Mode)",
            "description": "Returns density of lognormal distribution."
        },
        "LOGNORMDIST": {
            "syntax": "LOGNORMDIST(X, Mean, Stddev, Mode)",
            "description": "Returns density of lognormal distribution."
        },
        "LOGNORM.INV": {
            "syntax": "LOGNORM.INV(P, Mean, Stddev)",
            "description": "Returns value of inverse lognormal distribution."
        },
        "LOGNORMINV": {
            "syntax": "LOGNORMINV(P, Mean, Stddev)",
            "description": "Returns value of inverse lognormal distribution."
        },
        "LOGINV": {
            "syntax": "LOGINV(P, Mean, Stddev)",
            "description": "Returns value of inverse lognormal distribution."
        },
        "MAX": {
            "syntax": "MAX(Number1, Number2, ...NumberN)",
            "description": "Returns the maximum value in a list of arguments."
        },
        "MAXA": {
            "syntax": "MAXA(Value1, Value2, ...ValueN)",
            "description": "Returns the maximum value in a list of arguments."
        },
        "MAXIFS": {
            "syntax": "MAXIFS(Max_Range, Criterion_range1, Criterion1 [, Criterion_range2, Criterion2 [, ...Criterion_rangeN, CriterionN]])",
            "description": "Returns the maximum value of the cells in a range that meet a set of criteria."
        },
        "MEDIAN": {
            "syntax": "MEDIAN(Number1, Number2, ...NumberN)",
            "description": "Returns the median of a set of numbers."
        },
        "MIN": {
            "syntax": "MIN(Number1, Number2, ...NumberN)",
            "description": "Returns the minimum value in a list of arguments."
        },
        "MINA": {
            "syntax": "MINA(Value1, Value2, ...ValueN)",
            "description": "Returns the minimum value in a list of arguments."
        },
        "MINIFS": {
            "syntax": "MINIFS(Min_Range, Criterion_range1, Criterion1 [, Criterion_range2, Criterion2 [, ...Criterion_rangeN, CriterionN]])",
            "description": "Returns the minimum value of the cells in a range that meet a set of criteria."
        },
        "NEGBINOM.DIST": {
            "syntax": "NEGBINOM.DIST(Number1, Number2, Number3, Mode)",
            "description": "Returns density of negative binomial distribution."
        },
        "NEGBINOMDIST": {
            "syntax": "NEGBINOMDIST(Number1, Number2, Number3, Mode)",
            "description": "Returns density of negative binomial distribution."
        },
        "NORM.DIST": {
            "syntax": "NORM.DIST(X, Mean, Stddev, Mode)",
            "description": "Returns density of normal distribution."
        },
        "NORMDIST": {
            "syntax": "NORMSDIST(X, Mode)",
            "description": "Returns density of normal distribution."
        },
        "NORM.S.DIST": {
            "syntax": "NORM.S.DIST(X, Mode)",
            "description": "Returns density of normal distribution."
        },
        "NORM.INV": {
            "syntax": "NORM.INV(P, Mean, Stddev)",
            "description": "Returns value of inverse normal distribution."
        },
        "NORMINV": {
            "syntax": "NORMINV(P, Mean, Stddev)",
            "description": "Returns value of inverse normal distribution."
        },
        "NORM.S.INV": {
            "syntax": "NORM.S.INV(P)",
            "description": "Returns value of inverse normal distribution."
        },
        "NORMSINV": {
            "syntax": "NORMSINV(P)",
            "description": "Returns value of inverse normal distribution."
        },
        "PEARSON": {
            "syntax": "PEARSON(Data1, Data2)",
            "description": "Returns the correlation coefficient between two data sets."
        },
        "PHI": {
            "syntax": "PHI(X)",
            "description": "Returns probability densitity of normal distribution."
        },
        "POISSON": {
            "syntax": "POISSON(X, Mean, Mode)",
            "description": "Returns density of Poisson distribution."
        },
        "POISSON.DIST": {
            "syntax": "POISSON.DIST(X, Mean, Mode)",
            "description": "Returns density of Poisson distribution."
        },
        "POISSONDIST": {
            "syntax": "POISSONDIST(X, Mean, Mode)",
            "description": "Returns density of Poisson distribution."
        },
        "RSQ": {
            "syntax": "RSQ(Data1, Data2)",
            "description": "Returns the squared correlation coefficient between two data sets."
        },
        "SKEW": {
            "syntax": "SKEW(Number1, Number2, ...NumberN)",
            "description": "Returns skeweness of a sample."
        },
        "SKEW.P": {
            "syntax": "SKEW.P(Number1, Number2, ...NumberN)",
            "description": "Returns skeweness of a population."
        },
        "SKEWP": {
            "syntax": "SKEWP(Number1, Number2, ...NumberN)",
            "description": "Returns skeweness of a population."
        },
        "SLOPE": {
            "syntax": "SLOPE(Array1, Array2)",
            "description": "Returns the slope of a linear regression line."
        },
        "SMALL": {
            "syntax": "SMALL(Range, K)",
            "description": "Returns k-th smallest value in a range."
        },
        "STANDARDIZE": {
            "syntax": "STANDARDIZE(X, Mean, Stddev)",
            "description": "Returns normalized value wrt expected value and standard deviation."
        },
        "STDEV": {
            "syntax": "STDEV(Value1, Value2, ...ValueN)",
            "description": "Returns standard deviation of a sample."
        },
        "STDEVA": {
            "syntax": "STDEVA(Value1, Value2, ...ValueN)",
            "description": "Returns standard deviation of a sample."
        },
        "STDEVP": {
            "syntax": "STDEVP(Value1, Value2, ...ValueN)",
            "description": "Returns standard deviation of a population."
        },
        "STDEV.P": {
            "syntax": "STDEV.P(Value1, Value2, ...ValueN)",
            "description": "Returns standard deviation of a population."
        },
        "STDEVPA": {
            "syntax": "STDEVPA(Value1, Value2, ...ValueN)",
            "description": "Returns standard deviation of a population."
        },
        "STDEV.S": {
            "syntax": "STDEV.S(Value1, Value2, ...ValueN)",
            "description": "Returns standard deviation of a sample."
        },
        "STDEVS": {
            "syntax": "STDEVS(Value1, Value2, ...ValueN)",
            "description": "Returns standard deviation of a sample."
        },
        "STEYX": {
            "syntax": "STEYX(Array1, Array2)",
            "description": "Returns standard error for predicted of the predicted y value for each x value."
        },
        "TDIST": {
            "syntax": "TDIST(X, Degrees, Mode)",
            "description": "Returns density of Student-t distribution, both-sided or right-tailed."
        },
        "T.DIST": {
            "syntax": "T.DIST(X, Degrees, Mode)",
            "description": "Returns density of Student-t distribution."
        },
        "T.DIST.2T": {
            "syntax": "T.DIST.2T(X, Degrees)",
            "description": "Returns density of Student-t distribution, both-sided."
        },
        "TDIST2T": {
            "syntax": "TDIST2T(X, Degrees)",
            "description": "Returns density of Student-t distribution, both-sided."
        },
        "T.DIST.RT": {
            "syntax": "T.DIST.RT(X, Degrees)",
            "description": "Returns density of Student-t distribution, right-tailed."
        },
        "TDISTRT": {
            "syntax": "TDISTRT(X, Degrees)",
            "description": "Returns density of Student-t distribution, right-tailed."
        },
        "TINV": {
            "syntax": "TINV(P, Degrees)",
            "description": "Returns inverse Student-t distribution, both-sided."
        },
        "T.INV": {
            "syntax": "T.INV(P, Degrees)",
            "description": "Returns inverse Student-t distribution."
        },
        "T.INV.2T": {
            "syntax": "T.INV.2T(P, Degrees)",
            "description": "Returns inverse Student-t distribution, both-sided."
        },
        "TINV2T": {
            "syntax": "TINV2T(P, Degrees)",
            "description": "Returns inverse Student-t distribution, both-sided."
        },
        "TTEST": {
            "syntax": "TTEST(Array1, Array2)",
            "description": "Returns t-test value for a dataset."
        },
        "T.TEST": {
            "syntax": "T.TEST(Array1, Array2)",
            "description": "Returns t-test value for a dataset."
        },
        "VAR": {
            "syntax": "VAR(Value1, Value2, ...ValueN)",
            "description": "Returns variance of a sample."
        },
        "VARA": {
            "syntax": "VARA(Value1, Value2, ...ValueN)",
            "description": "Returns variance of a sample."
        },
        "VARP": {
            "syntax": "VARP(Value1, Value2, ...ValueN)",
            "description": "Returns variance of a population."
        },
        "VAR.P": {
            "syntax": "VAR.P(Value1, Value2, ...ValueN)",
            "description": "Returns variance of a population."
        },
        "VARPA": {
            "syntax": "VARPA(Value1, Value2, ...ValueN)",
            "description": "Returns variance of a population."
        },
        "VAR.S": {
            "syntax": "VAR.S(Value1, Value2, ...ValueN)",
            "description": "Returns variance of a sample."
        },
        "VARS": {
            "syntax": "VARS(Value1, Value2, ...ValueN)",
            "description": "Returns variance of a sample."
        },
        "WEIBULL": {
            "syntax": "WEIBULL(Number1, Number2, Number3, Boolean)",
            "description": "Returns density of Weibull distribution."
        },
        "WEIBULL.DIST": {
            "syntax": "WEIBULL.DIST(Number1, Number2, Number3, Boolean)",
            "description": "Returns density of Weibull distribution."
        },
        "WEIBULLDIST": {
            "syntax": "WEIBULLDIST(Number1, Number2, Number3, Boolean)",
            "description": "Returns density of Weibull distribution."
        },
        "Z.TEST": {
            "syntax": "Z.TEST(Array, X[, Sigma])",
            "description": "Returns z-test value for a dataset."
        },
        "ZTEST": {
            "syntax": "ZTEST(Array, X[, Sigma])",
            "description": "Returns z-test value for a dataset."
        }
    },
    "Text": {
        "CHAR": {
            "syntax": "CHAR(Number)",
            "description": "Converts a number into a character according to the current code table."
        },
        "CLEAN": {
            "syntax": "CLEAN(\"Text\")",
            "description": "Returns text that has been \"cleaned\" of line breaks and other non-printable characters."
        },
        "CODE": {
            "syntax": "CODE(\"Text\")",
            "description": "Returns a numeric code for the first character in a text string."
        },
        "CONCATENATE": {
            "syntax": "CONCATENATE(\"Text1\", \"Text2\", ...\"TextN\")",
            "description": "Combines several text strings into one string."
        },
        "EXACT": {
            "syntax": "EXACT(Text, Text)",
            "description": "Returns TRUE if both text strings are exactly the same."
        },
        "FIND": {
            "syntax": "FIND( \"Text1\", \"Text2\"[, Number])",
            "description": "Returns the location of one text string inside another."
        },
        "LEFT": {
            "syntax": "LEFT(\"Text\", Number)",
            "description": "Extracts a given number of characters from the left side of a text string."
        },
        "LEN": {
            "syntax": "LEN(\"Text\")",
            "description": "Returns length of a given text."
        },
        "LOWER": {
            "syntax": "LOWER(Text)",
            "description": "Returns text converted to lowercase."
        },
        "MID": {
            "syntax": "MID(Text, Start_position, Length)",
            "description": "Returns substring of a given length starting from Start_position."
        },
        "PROPER": {
            "syntax": "PROPER(\"Text\")",
            "description": "Capitalizes words given text string."
        },
        "REPLACE": {
            "syntax": "REPLACE(Text, Start_position, Length, New_text)",
            "description": "Replaces substring of a text of a given length that starts at given position."
        },
        "REPT": {
            "syntax": "REPT(\"Text\", Number)",
            "description": "Repeats text a given number of times."
        },
        "RIGHT": {
            "syntax": "RIGHT(\"Text\", Number)",
            "description": "Extracts a given number of characters from the right side of a text string."
        },
        "SEARCH": {
            "syntax": "SEARCH(Search_string, Text[, Start_position])",
            "description": "Returns the location of Search_string inside Text. Case-insensitive. Allows the use of wildcards."
        },
        "SPLIT": {
            "syntax": "SPLIT(Text, Index)",
            "description": "Divides the provided text using the space character as a separator and returns the substring at the zero-based position specified by the second argument.SPLIT(\"Lorem ipsum\", 0) -> \"Lorem\"SPLIT(\"Lorem ipsum\", 1) -> \"ipsum\""
        },
        "SUBSTITUTE": {
            "syntax": "SUBSTITUTE(Text, Old_text, New_text, [Occurrence])",
            "description": "Returns string where occurrences of Old_text are replaced by New_text. Replaces only specific occurrence if last parameter is provided."
        },
        "T": {
            "syntax": "T(Value)",
            "description": "Returns text if given value is text, empty string otherwise."
        },
        "TEXT": {
            "syntax": "TEXT(Number, Format)",
            "description": "Converts a number into text according to a given format.By default, accepts the same formats that can be passed to thedateFormatsoption, but can be further customized with thestringifyDateTimeoption."
        },
        "TRIM": {
            "syntax": "TRIM(\"Text\")",
            "description": "Strips extra spaces from text."
        },
        "UNICHAR": {
            "syntax": "UNICHAR(Number)",
            "description": "Returns the character created by using provided code point."
        },
        "UNICODE": {
            "syntax": "UNICODE(Text)",
            "description": "Returns the Unicode code point of a first character of a text."
        },
        "UPPER": {
            "syntax": "UPPER(Text)",
            "description": "Returns text converted to uppercase."
        }
    },
    'Custom': {
        'COLUMN': {
            'syntax': 'COLUMN(ColumnAlias)',
            'description': 'Odkáže se na správný sloupec v tabulce. Je možné zadat ColumnDataAlias, nebo celý model.'
        },
    }
};