<script setup>

import AlertData from "@/Components/Alerts/AlertData.vue";
import CloseIcon from "@/Icons/CloseIcon.vue";
import {nextTick, onMounted, ref, watch} from "vue";
import DeleteIcon from "../../Icons/DeleteIcon.vue";
import ErrorIcon from "../../Icons/ErrorIcon.vue";
import SuccessIcon from "../../Icons/SuccessIcon.vue";
import WarningIcon from "../../Icons/WarningIcon.vue";
import WaitingIcon from "../../Icons/WaitingIcon.vue";
import InfoIcon from "../../Icons/InfoIcon.vue";
import HelpIcon from "../../Icons/HelpIcon.vue";
import LoadingIcon from "../../Icons/LoadingIcon.vue";

const emit = defineEmits([
    'close'
]);

const props = defineProps({
    alert: {},
    isHistory: '',
})

const isExpanded = ref(false);

const alertText = ref(null);
const alertTextMessage = ref(null);
const alertTextOverflown = ref(false);

// čas skrytí alertu
// const duration = ref(5000);
const isVisible = ref(true);
const fade = ref('');
let timer;


const checkOverflow = (shownTextEl, fullTextEl) => {
    const containerHeight = alertText.value?.offsetHeight ?? 0;
    const messageHeight = alertTextMessage.value?.offsetHeight ?? 0;
    return messageHeight > containerHeight;
};

function closeAlert() {
    fade.value = 'fade';

    setTimeout(() => {
        isVisible.value = false;
    }, 500);


}

function hideAlert(duration = 5000) {
    if(!props.isHistory) {
        fade.value = 'startFade';
        timer =
            setTimeout(() => {
                closeAlert();
            }, duration)
    }
}

function preventClosing () {
    if(timer && fade.value !== 'fade') {
        fade.value = '';
        clearTimeout(timer);
    }
}

// Skrytí komponenty při jejím vytvoření v případě otevřené historie
onMounted(() => {
    if(props.alert.type !== 'waiting' && !props.isHistory) {
        hideAlert();
    } else {
        preventClosing();
    }
    nextTick(() => {
        alertTextOverflown.value = checkOverflow(alertText, alertTextMessage);
    });
});

watch(() => props.alert.type, () => {
    hideAlert();
});


function copyDataToClipboard(data) {
// Convert the data to a JSON string
    const jsonString = JSON.stringify(data);

    // Use the Clipboard API to write the text to the clipboard
    navigator.clipboard.writeText(jsonString).then(function() {
        console.log('JSON data copied to clipboard successfully!');
    }).catch(function(err) {
        console.error('Failed to copy text: ', err);
    });
}

</script>

<template>
    <div v-if="(!isHistory && isVisible) || (isHistory)" :class="['alert', 'alert-'+alert.type, fade]"
         @mouseenter="preventClosing()"
         @mouseleave="(isVisible && !isHistory && alert.type !== 'waiting' ? hideAlert(5000) : '')"
    >
        <div v-if="fade" :class="{'alert-timer': fade === 'startFade'}"></div>
        <div class="alert-title">
            <div :class="['alert-title-icon', 'icon-'+alert.type]">
                <ErrorIcon color="white" v-if="alert.type === 'danger'" width="8" height="8"/>
                <SuccessIcon color="white" v-if="alert.type === 'success'" width="8" height="8"/>
                <WarningIcon color="white" v-if="alert.type === 'warning'" width="8" height="8"/>
                <InfoIcon color="white" v-if="alert.type === 'info'" width="8" height="8"/>
                <LoadingIcon color="black" v-if="alert.type === 'waiting'" width="13" height="13"/>
            </div>
            <div :class="alert.type === 'waiting' ? 'loading':''">
                {{ alert.title }}
            </div>

            <div v-if="!isHistory" class="alert-close" @click="closeAlert()">
                <CloseIcon height="12" width="12" color="#8898A7"/>
            </div>
            <div v-if="isHistory" class="alert-close" @click="emit('close', alert)">
                <DeleteIcon height="17" width="17" color="#8898A7"/>
            </div>


        </div>
        <p v-if="alert.message" :class="['alert-text', isExpanded ? 'expanded' : '', alertTextOverflown ? 'alert-text-show-more' : '']" ref="alertText">
            <span ref="alertTextMessage" class="alert-text-message">{{alert.message}}</span>
        </p>
        <div :class="['alert-text-expand', 'text-'+alert.type]" v-if="alertTextOverflown" @click="isExpanded = !isExpanded">
            {{ isExpanded ? 'Skrýt' : 'Zobrazit více' }}
        </div>
        <div class="alert-data" v-if="alert.areDataShown">
            <div v-if="alert.areDataShown" class="alert-data-block">
                <div class="alert-data-header">
                    <div class="alert-data-title">
                        <h4 class="alert-data-title">Data</h4>
                    </div>
                    <div class="alert-data-copy" @click="copyDataToClipboard(alert.data)">
                        <!-- předělat na cdb icon -->
                        <img class="copy-image" alt="Close alert" src="../../../../front/img/cdb-icons/copy.svg">
                    </div>
                    <div class="alert-data-remove" @click="alert.toggleData">
                        <CloseIcon class="copy-image" color="black"/>
                    </div>
                </div>

                <div class="alert-data-content">
                    <div class="json-tree">
                        <AlertData :data="alert.data"/>
                    </div>
                </div>

            </div>
        </div>

        <div class="alert-footer">
            <div class="alert-timestamp">
                {{ alert.createdAt }}
            </div>
            <div v-if="!alert.areDataShown && alert.data" class="tooltip" @click="alert.toggleData">
                <HelpIcon/>
                <span class="tooltiptext">Zobrazit data</span>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .alert {
        display: flex;
        flex-direction: column;
        position: relative;
        background: deepskyblue;
        border-radius: 10px;
        padding: 9px 10px;
        margin: 5px;
        direction: ltr;
        pointer-events: all;
        min-height: 70px;
        border: 2px solid white;
        width: 75%;
        flex-wrap: wrap!important;
        justify-content: space-between;


        box-shadow: 0 4px 31px -3px rgba(53, 70, 97, 0.25);

        .alert-title {
            margin-bottom: 2px;
            display: flex;
            align-items: center;
            flex-direction: row;
            width: 100%;
            font-weight: bold;
            font-size: 16px;
            padding-bottom: 0;
            color: #091219;

            .alert-title-icon {
                display: flex;
                padding: 4px;
                margin-right: 5px;
                border-radius: 100px;
                border: 2px solid white;

                background: green;

                &.icon-success {
                    background: #24C586;
                }

                &.icon-danger {
                    background: red;
                }

                &.icon-warning {
                    background: #FF9900;
                }

                &.icon-info {
                    background: #8898A7;
                }

                &.icon-waiting {
                    background: white;
                    padding: 2px;
                }
            }

            .loading:after {
                display: inline-block;
                animation: dotty steps(1,end) 1s infinite;
                content: '';
            }
        }

        .alert-text {
            color: #091219;
            font-size: 13px;
            font-weight: 300;
            margin-top: 3px;
            margin-bottom: 0;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
        }

        .alert-text-expand {
            font-weight: 500;
            cursor: pointer;
            padding-top: 2px;
            font-size: 14px;

            &:hover {
                color: #0a36e9;
            }

            &.text-success {
                color: #24C586;
            }

            &.text-warning {
                color: #FF9900;
            }

            &.text-waiting {
                color: #52FF00;
            }

            &.text-danger {
                color: #F00;
            }

            &.text-info {
                color: #8898A7;
            }

            &:hover {
                text-decoration: underline;
                text-underline: black;
            }
        }

        .expanded {
            -webkit-line-clamp: unset; /* Remove the line clamping */
            max-height: none; /* Remove the height restriction */
            overflow: visible;
        }

        .alert-data {
            padding-top: 8px;
            margin-top: auto;
            text-align: right;

            .alert-data-block {
                display: block;
                margin-top: 5px;
                text-align: left;
                background: white;
                padding: 5px 0;
                border-radius: 5px;
                overflow: hidden; /* Prevents overflow */
                word-wrap: normal; /* Ensures long words break */
                word-break: normal;

                .alert-data-content {
                    max-height: 235px;
                    overflow: auto;

                    .json-tree {
                        font-family: monospace;
                         /* Breaks long words at any character */
                    }
                }
            }

            .alert-data-header {
                display: flex;
                flex-direction: row;
                .alert-data-title {
                    margin: 5px 0;
                    padding: 0 5px;
                }

                .alert-data-copy {
                    margin-left: auto;
                    fill: #FFFFFF;
                    cursor: pointer;
                    padding-right: 5px;

                    .copy-image {
                        padding-top: 10px;
                        padding-right: 8px;
                        vertical-align: middle;
                        height: 15px;
                    }
                }

                .alert-data-remove {
                    fill: #FFFFFF;
                    cursor: pointer;
                    padding-right: 5px;

                    .copy-image {
                        padding-top: 10px;
                        padding-right: 8px;
                        vertical-align: middle;
                        height: 15px;
                    }
                }
            }


        }

        .alert-footer {
            padding-top: 8px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;
            align-content: end;
        }

        .alert-timestamp {
            font-weight: 500;
            padding-top: 3px;
            font-size: 13px;
            text-align: right;
            color: black;
        }

        &.alert-success {
            background: radial-gradient(166.99% 167.11% at 2.04% 6.3%, rgba(36, 197, 134, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%), #FFF;
        }

        &.alert-warning {
            background: radial-gradient(185.44% 185.69% at 2.04% 6.3%, rgba(255, 153, 0, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%), #FFF;
        }

        &.alert-danger {
            background: radial-gradient(162.52% 162.52% at 2.04% 6.3%, rgba(255, 0, 0, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%), #FFF;
        }

        &.alert-waiting {
            background: radial-gradient(172.72% 172.78% at 2.04% 6.3%, rgba(82, 255, 0, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%), #FFF;
        }

        &.alert-info {
            background: radial-gradient(167.18% 167.19% at 2.04% 6.3%, rgba(136, 152, 167, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%), #FFF;

        }

        .alert-close {
            margin-left: auto;
            fill: #FFFFFF;
            cursor: pointer;
        }

        &.fade {
            visibility: hidden;
            opacity: 0;
            transition: visibility 0s 0.5s, opacity 0.5s linear;
        }

        .alert-timer {
            border-radius: 5px 5px 0 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 6px;
            background-color: darkgray; /* Change the color as needed */
            animation: timer 5s linear forwards;
        }

        /* Tooltip container */
        .tooltip {
            position: relative;
            display: inline-block;
            cursor: pointer;
            padding-right: 1px;
        }

        /* Tooltip text */
        .tooltip .tooltiptext {
            visibility: hidden;
            width: 120px;
            background-color: black;
            color: #fff;
            text-align: center;
            padding: 5px 0;
            border-radius: 6px;
            font-size: 14px;

            /* Position the tooltip text - see examples below! */
            position: absolute;
            z-index: 1;
            bottom: 100%;
            left: 50%;
            margin-left: -100px; /* Use half of the width (120/2 = 60), to center the tooltip */
            margin-bottom: 5px;
        }

        /* Show the tooltip text when you mouse over the tooltip container */
        .tooltip:hover .tooltiptext {
            visibility: visible;
        }
    }



    @keyframes timer {
        from { width: 100%; }
        to { width: 0px }
    }

    @keyframes ellipsis {
        to {
            width: 40px;
        }
    }

    @-webkit-keyframes ellipsis {
        to {
            width: 40px;
        }
    }

    @keyframes dotty {
        0%   { content: ''; }
        25%  { content: '.'; }
        50%  { content: '..'; }
        75%  { content: '...'; }
        100% { content: ''; }
    }

</style>