<script setup>
    const props = defineProps({
        column: String,
    });

    const column = JSON.parse(props.column);
</script>

<template>
    <div class="context-menu-col-info-wrapper">
        <div class="header">
            Data model info
        </div>
        <div>
            <div>
                <b>Data:</b> {{ column.joinData }}
            </div>
            <div>
                <b>Data alias:</b> {{ column.alias }}
            </div>
            <div>
                <b>Model:</b> {{ column.model }}
            </div>
        </div>
    </div>
</template>

<style scoped>
    .context-menu-col-info-wrapper{
        background-color: #f7fafc;
        overflow: auto;
        padding: 0.5rem;
        font-size: 10px;
        line-height: 0.75rem;
        overflow-x: auto;
        user-select: all !important;
        pointer-events: all !important;

        .header{
            font-size: 12px;
            line-height: 1rem;
        }
    }
</style>