<script setup>
import {ref, watch} from "vue";

const props = defineProps({
    modelValue: String,
    enumValues: Object,
})

const emit = defineEmits(['update:modelValue']);

const value = ref(props.modelValue);

watch(() => props.modelValue, (newValue) => {
    value.value = newValue;
})

watch(() => value.value, (newValue) => {
    emit('update:modelValue', newValue);
})

// input = `<select name="${column.name}" data-id="${row.data()['ID']}">`;
// let values = column.select_values;
// for (let k in values) {
//     input += `<option value="${k}" ${value == k ? 'selected' : ''}>${values[k]}</option>`;
// }
// input += `</select>`;
</script>

<template>
    <div class="enum-editor">
        <select v-model="value">
            <option v-for="[value, text] in Object.entries(props.enumValues)" :value="value" :key="value">
                {{text}}
            </option>
        </select>
    </div>
</template>

<style scoped>

</style>