<script setup>
import NewSelect from "@/Components/Inputs/NewSelect.vue";
import Thead from "@/Components/Table/Thead.vue";
import TheadTh from "@/Components/Table/TheadTh.vue";
import TbodyTr from "@/Components/Table/TbodyTr.vue";
import Table from "@/Components/Table/Table.vue";
import Tbody from "@/Components/Table/Tbody.vue";
import TheadTr from "@/Components/Table/TheadTr.vue";
import Button from "@/Components/Inputs/Button.vue";
import TbodyTd from "@/Components/Table/TbodyTd.vue";
import {ref, watch} from "vue";
import InputField from "@/Components/Inputs/InputField.vue";
import TinyMCE from "@/Components/Inputs/TinyMCE.vue";
import TextArea from "@/Components/Inputs/TextArea.vue";
import Checkbox from "@/Components/Inputs/Checkbox.vue";

const props = defineProps({
    modelValue: Array,
})

const emit = defineEmits(['update:modelValue']);
const translationItemGroups = ref(props.modelValue);

watch(props.modelValue, (newVal) => {
    if (JSON.stringify(newVal) !== JSON.stringify(translationItemGroups.value)) {
        translationItemGroups.value = newVal;
    }
});

watch(translationItemGroups, (newVal) => {
    if (JSON.stringify(newVal) !== JSON.stringify(props.modelValue)) {
        emit('update:modelValue', newVal);
    }
});

const addFieldGroup = () => {
    translationItemGroups.value.push({
        ID: false,
        Type: '',
        translationItems: [],
        ToBeDeleted: false
    });
};
const removeFieldGroup = (groupIndex) => {
    if (translationItemGroups.value[groupIndex].ID) {
        translationItemGroups.value[groupIndex].ToBeDeleted = true;
        translationItemGroups.value[groupIndex].translationItems.forEach((item, index) => {
            translationItemGroups.value[groupIndex].translationItems[index].ToBeDeleted = true;
        });
    } else {
        translationItemGroups.value.splice(groupIndex, 1);
    }
};

const addField = (groupIndex) => {
    translationItemGroups.value[groupIndex].translationItems.push({
        ID: false,
        Item: '',
        Note: '',
        MinLenght: 0,
        MaxLenght: 0,
        Type: 'input',
        ToBeDeleted: false,
        ShowNote: false,
    });
};
const removeField = (groupIndex, fieldIndex) => {
    if (translationItemGroups.value[groupIndex].translationItems[fieldIndex].ID) {
        translationItemGroups.value[groupIndex].translationItems[fieldIndex].ToBeDeleted = true;
    } else {
        translationItemGroups.value[groupIndex].translationItems.splice(fieldIndex, 1);
    }
};


</script>

<template>
    <div>
        <Table v-for="(fieldGroup, groupIndex) in translationItemGroups" :key="groupIndex" class="mb-3 translation-group-wrapper">
            <template v-if="!fieldGroup.ToBeDeleted" >
                <Thead>
                <TheadTr>
                    <TheadTh :colspan="3" class="translation-group-name-wrapper">
                        <InputField size="small" v-model="fieldGroup.Type" label="Název skupiny" />
                    </TheadTh>
                    <TheadTh :colspan="2" class="text-right">
                        <Button size="small" @click="removeFieldGroup(groupIndex)" variant="red" class="translation-group-remove-button">Odebrat skupinu</Button>
                    </TheadTh>
                </TheadTr>
                <TheadTr>
                    <TheadTh>
                        Typ políčka
                    </TheadTh>
                    <TheadTh>
                        Text k překladu
                    </TheadTh>
                    <TheadTh>
                        Min znaků
                    </TheadTh>
                    <TheadTh>
                        Max znaků
                    </TheadTh>
                    <TheadTh>
                        Akce
                    </TheadTh>
                </TheadTr>
                </Thead>
                <Tbody>
                <TbodyTr v-for="(item, index) in fieldGroup.translationItems" :key="index">
                    <template v-if="!item.ToBeDeleted">
                        <TbodyTd class="w-[250px] input-type-wrapper">
                            <NewSelect size="small" v-model="item.Type" :options="[{text: 'Input', value: 'input'},{text: 'Textarea', value: 'textarea'},{text: 'WYSIWYG Editor', value: 'wysiwyg'}]">
                            </NewSelect>
                        </TbodyTd>
                        <TbodyTd class="input-type-input">
                            <InputField size="small" v-if="item.Type === 'input'" v-model="item.Item" />
                            <TextArea v-else-if="item.Type === 'textarea'" v-model="item.Item"></TextArea>
                            <TinyMCE v-else-if="item.Type === 'wysiwyg'" v-model="item.Item"></TinyMCE>
                            <div class="input-note-wrapper">
                                <Checkbox v-model="item.ShowNote" label="Přidat poznámku" class="mt-2 !mb-1" />
                            </div>
                            <div class="input-note-textarea-wrapper">
                                <TextArea v-if="item.ShowNote"  v-model="item.Note" label="Poznámka k překladu" class="w-[60%]"></TextArea>
                            </div>
                        </TbodyTd>
                        <TbodyTd class="w-[150px] input-type-min-chars-wrapper">
                            <InputField size="small" type="number" v-model.number="item.MinLenght"  />
                        </TbodyTd>
                        <TbodyTd class="w-[150px] input-type-max-chars-wrapper">
                            <InputField size="small" type="number" v-model.number="item.MaxLenght" />
                        </TbodyTd>
                        <TbodyTd class="w-[150px] input-type-remove-wrapper">
                            <Button size="small" @click="removeField(groupIndex, index)" variant="red" class="m-3">Odebrat</Button>
                        </TbodyTd>
                    </template>
                </TbodyTr>
                <TbodyTr>
                    <TbodyTd :colspan=5>
                        <Button size="small" @click="addField(groupIndex)" variant="green" class="m-3 translation-group-input-add-button">Přidat pole</Button>
                    </TbodyTd>
                </TbodyTr>
                </Tbody>
            </template>
        </Table>

        <div><Button size="small" @click="addFieldGroup" variant="green" class="translation-group-add-button">Přidat skupinu polí</Button></div>
    </div>
</template>

<style scoped>

</style>