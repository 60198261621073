<script setup>
    import Grid from "@/DataGrid/Components/Table/Grid.vue";
    import {DataGridState} from "@/DataGrid/js/DataGridState";
    import {reactive} from "vue";

    const props = defineProps({
        dataGrid: {
            type: Object,
        },
        id: {
            type: String
        }
    });

    // const settings = {
    //   columnDefs: [
    //       {
    //           "targets":0,
    //           "width":"100px",
    //           "sWidth":"100px",
    //           "visible":true,
    //           "mData":"ID",
    //           "name":"ID",
    //           "label":"ID",
    //           "defaultContent":"",
    //           "editable":false,
    //           "editType":"default",
    //           "editParams":null,
    //           "columnInfo":{
    //               "name":"InsertDateTime",
    //               "format":65,
    //               "format_function":null,
    //               "editable":false,
    //               "editType":"default",
    //               "editParams":null,
    //               "minwidth":"100px",
    //               "label":"Datum",
    //               "entity_values":"null",
    //               "visibility":true
    //           },
    //           "resizable":true,
    //           "format":65,
    //           "orderSequence":[
    //               "desc",
    //               "asc"
    //           ],
    //           "enum_values":null,
    //           "select_values":null
    //       },
    //       {
    //           "targets":1,
    //           "width":"100px",
    //           "sWidth":"100px",
    //           "visible":true,
    //           "mData":"InsertDateTime",
    //           "name":"InsertDateTime",
    //           "label":"Datum",
    //           "defaultContent":"",
    //           "editable":false,
    //           "editType":"default",
    //           "editParams":null,
    //           "columnInfo":{
    //               "name":"InsertDateTime",
    //               "format":65,
    //               "format_function":null,
    //               "editable":false,
    //               "editType":"default",
    //               "editParams":null,
    //               "minwidth":"100px",
    //               "label":"Datum",
    //               "entity_values":"null",
    //               "visibility":true
    //           },
    //           "resizable":true,
    //           "format":65,
    //           "orderSequence":[
    //               "desc",
    //               "asc"
    //           ],
    //           "enum_values":null,
    //           "select_values":null
    //       },
    //       {
    //           "targets":2,
    //           "width":"150px",
    //           "sWidth":"150px",
    //           "visible":true,
    //           "mData":"Sender",
    //           "name":"Sender",
    //           "label":"Odesílatel",
    //           "defaultContent":"",
    //           "editable":false,
    //           "editType":"default",
    //           "editParams":null,
    //           "columnInfo":{
    //               "name":"Sender",
    //               "format":2,
    //               "format_function":null,
    //               "editable":false,
    //               "editType":"default",
    //               "editParams":null,
    //               "minwidth":"150px",
    //               "label":"Odesílatel",
    //               "entity_values":"null",
    //               "visibility":true
    //           },
    //           "resizable":true,
    //           "format":2,
    //           "orderSequence":[
    //               "desc",
    //               "asc"
    //           ],
    //           "enum_values":null,
    //           "select_values":null
    //       },
    //       {
    //           "targets":3,
    //           "width":"230px",
    //           "sWidth":"230px",
    //           "visible":true,
    //           "mData":"Recepient",
    //           "name":"Recepient",
    //           "label":"Příjemce",
    //           "defaultContent":"",
    //           "editable":false,
    //           "editType":"default",
    //           "editParams":null,
    //           "columnInfo":{
    //               "name":"Recepient",
    //               "format":2,
    //               "format_function":null,
    //               "editable":false,
    //               "editType":"default",
    //               "editParams":null,
    //               "minwidth":"230px",
    //               "label":"Příjemce",
    //               "entity_values":"null",
    //               "visibility":true
    //           },
    //           "resizable":true,
    //           "format":2,
    //           "orderSequence":[
    //               "desc",
    //               "asc"
    //           ],
    //           "enum_values":null,
    //           "select_values":null
    //       },
    //       {
    //           "targets":4,
    //           "width":"280px",
    //           "sWidth":"280px",
    //           "visible":true,
    //           "mData":"Subject",
    //           "name":"Subject",
    //           "label":"Hlavička emailu",
    //           "defaultContent":"",
    //           "editable":false,
    //           "editType":"default",
    //           "editParams":null,
    //           "columnInfo":{
    //               "name":"Subject",
    //               "format":2,
    //               "format_function":null,
    //               "editable":false,
    //               "editType":"default",
    //               "editParams":null,
    //               "minwidth":"280px",
    //               "label":"Hlavička emailu",
    //               "entity_values":"null",
    //               "visibility":true
    //           },
    //           "resizable":true,
    //           "format":2,
    //           "orderSequence":[
    //               "desc",
    //               "asc"
    //           ],
    //           "enum_values":null,
    //           "select_values":null
    //       },
    //       {
    //           "targets":5,
    //           "width":"110px",
    //           "sWidth":"110px",
    //           "visible":true,
    //           "mData":"Body",
    //           "name":"Body",
    //           "label":"Obsah",
    //           "defaultContent":"",
    //           "editable":false,
    //           "editType":"default",
    //           "editParams":null,
    //           "columnInfo":{
    //               "name":"Body",
    //               "format":4194304,
    //               "format_function":null,
    //               "editable":false,
    //               "editType":"default",
    //               "editParams":null,
    //               "minwidth":"110px",
    //               "label":"Obsah",
    //               "entity_values":"null",
    //               "visibility":true
    //           },
    //           "resizable":true,
    //           "format":4194304,
    //           "orderSequence":[
    //               "desc",
    //               "asc"
    //           ],
    //           "enum_values":null,
    //           "select_values":null
    //       },
    //       {
    //           "targets":6,
    //           "width":"110px",
    //           "visible":true,
    //           "data":"Action",
    //           "name":"actions",
    //           "sWidth":null
    //       }
    //   ],
    //     ajax: {
    //         url: 'http://ecu.localhost/admin/email-log/data-list?',
    //         beforeSend: (xhr, settings) => {
    //             let cancel = this.cancelAjax;
    //             this.cancelAjax = false;
    //             if(cancel){
    //                 xhr.abort();
    //                 console.log(this._xhr);
    //             }else{
    //                 if(this._xhr && this._xhr.readyState !== 4){
    //                     this._xhr.abort();
    //                     console.log(this._xhr);
    //                 }
    //
    //                 this._xhr = xhr;
    //             }
    //
    //         },
    //         data: (d, datatable, settings) => {
    //             // let sort = ((d.order[0].dir === 'asc') ? '' : '-') + this.getColumnList(true)[d.order[0].column].name;
    //             //
    //             // if (this.params.order.reordered){
    //             //     try{
    //             //         sort = ((d.order[0].dir === 'asc') ? '' : '-') + this.getColumnList()[d.order[0].column].name;
    //             //     }catch (e) {
    //             //
    //             //     }
    //             // }
    //             // else {
    //             //     if(d.draw === 1){
    //             //         if(this.grid.state.loaded()){
    //             //             //this.grid.colReorder.order(this.grid.state.loaded().ColReorder);
    //             //             // this.grid.state.loaded().order[0][0] = Object.keys(this.grid.state.loaded().ColReorder).find(key => this.grid.state.loaded().ColReorder[key] === this.grid.state.loaded().order[0][0]);
    //             //             if(typeof this.grid.state.loaded().ColReorder !== 'undefined'){
    //             //                 this.grid.state.loaded().order[0][0] = this.grid.state.loaded().ColReorder[this.grid.state.loaded().order[0][0]];
    //             //                 this.grid.order(this.grid.state.loaded().order);
    //             //                 d.order[0].column = this.grid.state.loaded().order[0][0];
    //             //             }
    //             //         }
    //             //     }
    //             //     try{
    //             //         sort = ((d.order[0].dir === 'asc') ? '' : '-') + this.getColumnList()[d.order[0].column].name;
    //             //     }catch (e) {
    //             //
    //             //     }
    //             // }
    //             let length = (d.length > 0) ? d.length : 0;
    //
    //             let currentFrom = 0;
    //             let currentTo = 0;
    //
    //             let rangeFrom = d.start;
    //             let rangeTo = d.start + length;
    //             let direction;
    //
    //             let start;
    //             let draw = d.draw;
    //
    //             let count = rangeTo - currentTo;
    //             if(count > length || count === 0)
    //                 count = length;
    //
    //             if(currentTo < rangeTo) {
    //                 direction = 'down';
    //                 if(count < 0)
    //                     count = 0;
    //                 if(rangeFrom > currentTo)
    //                     start = rangeFrom;
    //                 else
    //                     start = currentTo;
    //             }else if(currentTo > rangeTo){
    //                 direction = 'up';
    //
    //                 if(count < 0)
    //                     count = Math.abs(count);
    //
    //                 if(rangeFrom < currentFrom-length) {
    //                     count = length;
    //                     start = rangeTo;
    //                 }else
    //                     start = currentFrom-count;
    //             }else{
    //                 direction = 'refresh';
    //                 start = rangeFrom;
    //                 count = length;
    //             }
    //
    //             if(rangeFrom === 0){
    //                 direction = 'refresh';
    //                 start = 0;
    //                 count = length;
    //             }
    //             // this.setUrlParameter('sort', sort);
    //             // this.setUrlParameter('count', count);
    //             // this.setUrlParameter('start', start);
    //
    //             return d;
    //         },
    //         dataFilter: (data,a,b) => {
    //             let json;
    //             if (typeof data === 'string')
    //                 json = JSON.parse(data);
    //             else
    //                 json = data;
    //             json.recordsTotal = json.numRows;
    //             json.recordsFiltered = json.numRows;
    //
    //             if(json.statuses !== undefined){
    //                 Helpers.alertAuthStatuses(json.statuses);
    //
    //                 let hasAuthError = Helpers.getStatusesByType(json.statuses, 'auth_error');
    //                 if(hasAuthError.length > 0){
    //                     let OAuthServer = JSON.parse(hasAuthError[0].msg).OAuthServer;
    //                     AuthWindow.open(OAuthServer);
    //                 }
    //             }
    //
    //             delete json.numRows;
    //
    //             return JSON.stringify(json);
    //         },
    //         error: function (err) {
    //             console.log(err);
    //         }
    //     },
    // };
    //
    // const _grid = reactive(new DataGrid(settings));

    // const _grid = reactive(window[props.gridVarName]);
    // window._grid = _grid.grid;
    // window[props.gridVarName] = _grid;

    // let _data = [];
    // for (let i = 0; i < 1000; i++) {
    //     _data.push({
    //         InsertDateTime: i,
    //         Sender: 'Ludva',
    //         Recepient: 'Karel',
    //         Subject: 'Ahoj',
    //         Body: 'mame se?',
    //     });
    // }
    // _grid.setData(_data);

    // const gridState = new DataGridState(_grid.grid).state;
    // const grid = gridState.dataGrid;

    const grid = props.dataGrid;
    window._grid = grid;
</script>

<template>
    <div>
        <Grid v-if="grid" :data-grid="grid" class="new-data-grid" :data-id="props.id"></Grid>
    </div>
</template>

<style scoped>

</style>