<script setup>
import {reactive, ref, watch} from "vue";
import DataGrid from "@/DataGrid/Components/DataGrid.vue";

const props = defineProps({
   id: String,
   settings: [String, Object]
});

const settings = ref(typeof props.settings === 'string' ? JSON.parse(props.settings) : props.settings);

window[props.id] = reactive(new GridVue(props.id, settings.value));
const grid = window[props.id];

const date = ref(null);
</script>

<template>
    <DataGrid v-if="grid.grid" :data-grid="grid.grid" :id="grid.id"></DataGrid>
</template>

<style scoped>

</style>