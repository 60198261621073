<script setup>

</script>

<template>
    <tr>
        <slot></slot>
    </tr>
</template>

<style scoped>
    tr:nth-child(odd){
        background: #FFF;
    }

    tr:nth-child(even){
        background: #ECF2FF;
    }
</style>