<script setup>
import {onMounted, onUnmounted, ref, watch} from "vue";
import Datepicker from 'flowbite-datepicker/Datepicker';
import DateRangePicker from 'flowbite-datepicker/DateRangePicker';
import moment from "moment";

const props = defineProps({
    modelValue: {
        type: [String, Object],
        default: ''
    },
    returnObject: {
        type: Boolean,
        default: false
    },
    returnFormat: {
        type: String,
        default: 'YYYY-MM-DD'
    },
    inputFormat: {
        type: String,
        default: 'YYYY-MM-DD'
    },
    range: {
        type: Boolean,
        default: false
    },
    label: String,
});

function getDateObject(date){
    if(!date){
        return null;
    }

    if(typeof date === 'object'){
        return date;
    }else{
        return moment(date, props.inputFormat).toDate(); // Parsování řetězce na objekt Date pomocí moment.js
    }
}

function initObject(obj){
    if(!props.range){
        return getDateObject(obj);
    }else{
        let newObj = {
            from: null,
            to: null,
        };

        if(obj){
            Object.entries(obj).forEach(([key, value]) => {
                newObj[key] = getDateObject(value);
            });

            return newObj;
        }else{
            return newObj;
        }
    }
}

const emit = defineEmits(['update:modelValue']);

const elInput = ref(null);
const selectedDate = ref(initObject(props.modelValue));
function setDateToDatePicker(newDate) {
    if(!newDate){
        return;
    }

    if(props.range){
        elInput.value.rangepicker.inputs.forEach((el, index) => {
            if(index === 0){
                el.datepicker.setDate(getDateObject(newDate['from'] ?? null));
            }else{
                el.datepicker.setDate(getDateObject(newDate['to'] ?? null));
            }
        });
    }else{
        elInput.value.datepicker.setDate(getDateObject(newDate));
    }
}
const fromDateChange = (event) => {
    onDateChange(event, 'from');
}
const toDateChange = (event) => {
    onDateChange(event, 'to');
}
const onDateChange = (event, range = null) => {
    // Tato událost by měla mít přístup k aktualizovanému datu
    if (event.detail) {
        if(!props.range) {
            selectedDate.value = event.detail.date;
        }

        if(props.returnObject){
            if(props.range){
                if(range === 'from'){
                    selectedDate.value = {
                      ...selectedDate.value ?? {},
                      from: event.detail.date,
                    };
                }else if(range === 'to'){
                    selectedDate.value = {
                        ...selectedDate.value ?? {},
                        to: event.detail.date,
                    };
                }
            }
            emit('update:modelValue', selectedDate.value);
        }else{
            const rawDate = event.detail.date;
            const formattedDate = moment(rawDate).format(props.returnFormat);

            if(props.range){
                if(range === 'from'){
                    selectedDate.value = {
                        ...selectedDate.value ?? {},
                        from: formattedDate,
                    };
                }else if(range === 'to'){
                    selectedDate.value = {
                        ...selectedDate.value ?? {},
                        to: formattedDate,
                    };
                }
                emit('update:modelValue', selectedDate.value);
            }else{
                emit('update:modelValue', formattedDate);
            }
        }
    };
}
onMounted(() => {
    if(props.range){
        const pickers = new DateRangePicker(elInput.value, {
        });

        // //kvůli tawilindu
        // pickers.datepickers.forEach((picker) => {
        //     const wrapper = document.createElement('div');
        //     wrapper.classList.add('tailwind');
        //     picker.picker.element.parentNode.insertBefore(wrapper, picker.picker.element);
        //     wrapper.appendChild(picker.picker.element);
        // });
        // //kvůli tawilindu
    }else{
        const picker = new Datepicker(elInput.value, {
        });

        // //kvůli tawilindu
        // const wrapper = document.createElement('div');
        // wrapper.classList.add('tailwind');
        // picker.picker.element.parentNode.insertBefore(wrapper, picker.picker.element);
        // wrapper.appendChild(picker.picker.element);
        // //kvůli tawilindu
    }

    setDateToDatePicker(selectedDate.value);

    if(props.range){
        elInput.value.rangepicker.inputs.forEach((el, index) => {
            if(index === 0){
                el.addEventListener('changeDate', fromDateChange);
            }else{
                el.addEventListener('changeDate', toDateChange);
            }
        });
    }else{
        elInput.value.addEventListener('changeDate', onDateChange);
    }

});

onUnmounted(() => {
    if(!elInput.value){
        return;
    }
    if(props.range){
        elInput.value.rangepicker.inputs.forEach((el, index) => {
            if(index === 0){
                el.removeEventListener('changeDate', fromDateChange);
            }else{
                el.removeEventListener('changeDate', toDateChange);
            }
        });
    }else{
        elInput.value.removeEventListener('changeDate', onDateChange);
    }
});

watch(elInput, (value, oldValue) => {
    if(!value && oldValue){
        if(props.range){
            oldValue.rangepicker.inputs.forEach((el, index) => {
                if(index === 0){
                    el.removeEventListener('changeDate', fromDateChange);
                }else{
                    el.removeEventListener('changeDate', toDateChange);
                }
            });
        }else{
            oldValue.removeEventListener('changeDate', onDateChange);
        }
    }
})

watch(props.modelValue, (newValue) => {
    selectedDate.value = initObject(newValue);

    setDateToDatePicker(newValue);
});
</script>

<template>
    <div>
        <div v-if="!props.range" class="date-time-wrapper">
            <label
                v-if="typeof props.label !== 'undefined'"
                @click="elInput.focus()"
            >
                {{ label }}
            </label>
            <div class="input-wrapper">
                <div class="icon-wrapper">
                    <svg class="icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z"/>
                    </svg>
                </div>
                <input ref="elInput" datepicker type="text" placeholder="Select date">
            </div>
        </div>
        <div v-else class="date-time-wrapper">
            <label
                v-if="typeof props.label !== 'undefined'"
            >
                {{ label }}
            </label>
            <div ref="elInput" class="date-range-wrapper">
                <div class="input-wrapper date-from">
                    <div class="icon-wrapper">
                        <svg class="icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z"/>
                        </svg>
                    </div>
                    <input datepicker type="text" placeholder="Select date from">
                </div>
                <div class="separator">
                    -
                </div>
                <div class="input-wrapper date-to">
                    <div class="icon-wrapper">
                        <svg class="icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z"/>
                        </svg>
                    </div>
                    <input datepicker type="text" placeholder="Select date to">
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    input{
        border-radius: 6px !important;
        border: 1px solid #CDD8ED !important;
        background: #FFF !important;
        font-size: 14px !important;
        color: #091219 !important;

        &:focus{
            outline: none !important;
        }
    }

    label{
        font-family: Inter;
        font-size: 13px;
        font-weight: 600;
        margin-bottom: 8px;
        display: block;
    }

    .separator{
        font-size: 16px;
        font-weight: 600;
    }

    .date-time-wrapper{
        .date-range-wrapper{
            display: flex;
            align-items: center;

            .input-wrapper{
                &.date-from{
                    margin-right: 13px;
                }

                &.date-to{
                    margin-left: 13px;
                }
            }
        }

        .input-wrapper{
            position: relative;

            .icon-wrapper{
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                display: flex;
                align-items: center;
                padding-left: 14px;
                pointer-events: none;

                .icon{
                    width: 16px;
                    height: 16px;
                    color: #506069;
                }
            }

            input{
                min-height: 40px;
                padding-left: 40px;
                padding-right: 10px;
            }
        }
    }
</style>
