<script setup>
    import {ref, watch} from "vue";
    import {defineComponent} from "vue";
    import Modal from "@/Components/Modal/Modal.vue";
    import Button from "@/Components/Inputs/Button.vue"
    import NewSelect from "@/Components/Inputs/NewSelect.vue";
    import InputField from "@/Components/Inputs/InputField.vue";
    import {useAlertStore} from "@/Components/Alerts/stores/alertStore.js";

    const props = defineProps({
      show: Boolean,
      columns: Array,
      dataTable: Object
    });

    //alerts
    const alerts = useAlertStore(window.pinia);
    //end alerts

    const emit = defineEmits(['close', 'save']);

    const show = ref(props.show);

    const save = () => {
        emit('save', formula.value);

        if(props.dataTable){
            if(columnForFormula.value === null){
                alerts.error('Vyberte prosím sloupec pro vložení vzorce');
            }
            
            if(operation.value === null || columnInFormula.value === null){
                alerts.error('Vyberte prosím operaci a sloupec pro vytvoření vzorce');
            }



            const col = props.columns[columnForFormula.value];


            const data = props.dataTable.sheet.data.map((row) => {
                row[col.data ? col.joinData : col.alias] = formula.value;
                return row;
            });

            props.dataTable.updateData(data);
        }

        close();
    }

    const close = () => {
      show.value = false;
      emit('close');
    }

    watch(() => props.show, (val) => {
      show.value = props.show;
    });

    console.log(props.columns);

    const columnForFormula = ref(null);
    const columnInFormula = ref(null);
    const operation = ref(null);

    const operations = [
        {text: 'Zvětšit o %', value: 1},
        {text: 'Zmenšit o %', value: 2},
        {text: 'Hodnota sloupce', value: 3},
    ];

    const value = ref(null);
    const formula = ref(null);

    const createFormula = () => {
      if(operation.value === null || columnInFormula.value === null){
          return;
      }

      const col = props.columns[columnInFormula.value];

        console.log('asd', columnInFormula.value, col);

      if(operation.value === 1) {
          formula.value = '=COLUMN(' + (col.alias ? col.alias : col.joinData) + ')*' + (1+(value.value/100)) + '';
      }else if(operation.value === 2) {
          formula.value = '=COLUMN(' + (col.alias ? col.alias : col.joinData) + ')*' + (value.value/100) + '';
      }else if(operation.value === 3) {
          formula.value = '=COLUMN(' + (col.alias ? col.alias : col.joinData) + ')';
      }
    };

    watch(() => operation.value, (newVal) => {
        createFormula();
    });

    watch(() => value.value, (newVal) => {
        createFormula();
    });

    watch(() => columnInFormula.value, (newVal) => {
        createFormula();
    });

    const toClipboard = () => {
        navigator.clipboard.writeText(formula.value);
    };
</script>

<template>
    <Modal :isVisible="show" @update:isVisible="close" :size="'extraSmall'">
        <template #header>
            Vytvoření vzorce
        </template>
        <template #body>
            <NewSelect
                v-model="columnForFormula"
                label="Do sloupce"
                :options="props.columns.map((column, index) => {return {text: column.title, value: index}})"
                :filterable="true"
            ></NewSelect>
            <NewSelect
                v-model="operation"
                label="Operace"
                :options="operations"
            ></NewSelect>
            <NewSelect
                v-model="columnInFormula"
                label="Ze sloupce"
                :options="props.columns.map((column, index) => {return {text: column.title, value: index}})"
                :filterable="true"
            ></NewSelect>
            <InputField
                v-model="value"
                label="Hodnota (%)"
                class="input"
                v-if="operation !== 3"
            />
            <div class="tw-flex tw-items-end">
                <InputField
                    v-model="formula"
                    label="Výsledný vzorec"
                    class="input tw-grow"
                />
                <Button variant="green" @click="toClipboard" size="small" icon="copy" icon-color="white"></Button>
            </div>
        </template>
        <template #footer>
            <Button variant="green" @click="save">Aplikovat do sloupce</Button>
            <Button variant="red" @click="close">Zavřít</Button>
        </template>
    </Modal>
</template>

<style scoped>

</style>