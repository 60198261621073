<script setup>
import {IconProps, useIcon} from './useIcon.js'

const props = defineProps(IconProps(15,15, true));

const { color } = useIcon(props);
</script>

<template>
        <svg xmlns="http://www.w3.org/2000/svg" :class="props.loading ? 'loading' : ''" :width="props.width" :height="props.height" viewBox="0 0 15 15" fill="none" :style="{color: color}">
            <g clip-path="url(#clip0_187_5969)">
                <path d="M7.85145 3.50892C8.82045 3.50892 9.60598 2.72342 9.60598 1.75446C9.60598 0.785498 8.82045 0 7.85145 0C6.88245 0 6.09692 0.785498 6.09692 1.75446C6.09692 2.72342 6.88245 3.50892 7.85145 3.50892Z" fill="#506069"/>
                <path d="M7.85143 15C8.43277 15 8.90403 14.5288 8.90403 13.9474C8.90403 13.3661 8.43277 12.8949 7.85143 12.8949C7.27009 12.8949 6.79883 13.3661 6.79883 13.9474C6.79883 14.5288 7.27009 15 7.85143 15Z" fill="#506069"/>
                <path d="M3.54037 5.11879C4.41253 5.11879 5.11956 4.41179 5.11956 3.53966C5.11956 2.66753 4.41253 1.96053 3.54037 1.96053C2.66821 1.96053 1.96118 2.66753 1.96118 3.53966C1.96118 4.41179 2.66821 5.11879 3.54037 5.11879Z" fill="#506069"/>
                <path d="M12.1627 13.0383C12.6472 13.0383 13.0399 12.6456 13.0399 12.1611C13.0399 11.6766 12.6472 11.2839 12.1627 11.2839C11.6782 11.2839 11.2854 11.6766 11.2854 12.1611C11.2854 12.6456 11.6782 13.0383 12.1627 13.0383Z" fill="#506069"/>
                <path d="M1.75448 9.25446C2.52948 9.25446 3.15775 8.62621 3.15775 7.85123C3.15775 7.07625 2.52948 6.44801 1.75448 6.44801C0.979466 6.44801 0.351196 7.07625 0.351196 7.85123C0.351196 8.62621 0.979466 9.25446 1.75448 9.25446Z" fill="#506069"/>
                <path d="M13.9479 8.55199C14.3353 8.55199 14.6493 8.238 14.6493 7.85066C14.6493 7.46333 14.3353 7.14934 13.9479 7.14934C13.5606 7.14934 13.2466 7.46333 13.2466 7.85066C13.2466 8.238 13.5606 8.55199 13.9479 8.55199Z" fill="#506069"/>
                <path d="M2.67218 11.2941C2.19228 11.774 2.19228 12.5505 2.67218 13.0304C3.15152 13.5102 3.92916 13.5102 4.40849 13.0304C4.8884 12.5505 4.8884 11.774 4.40849 11.2941C3.92916 10.8137 3.15209 10.8091 2.67218 11.2941Z" fill="#506069"/>
                <path d="M12.1621 4.06565C12.4526 4.06565 12.6881 3.83016 12.6881 3.53966C12.6881 3.24916 12.4526 3.01366 12.1621 3.01366C11.8716 3.01366 11.6361 3.24916 11.6361 3.53966C11.6361 3.83016 11.8716 4.06565 12.1621 4.06565Z" fill="#506069"/>
            </g>
            <defs>
                <clipPath id="clip0_187_5969">
                    <rect width="15" height="15" fill="white"/>
                </clipPath>
            </defs>
        </svg>
</template>

<style scoped>
    .loading {
        padding: 0;
        animation: spin 2s linear infinite;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
</style>