import type { ISheet, SheetState } from "@project/Excel/Handsontable/js/Sheet";
import { plainToInstance, instanceToPlain } from "class-transformer";
import { Sheet } from "@project/Excel/Handsontable/js/Sheet";
import axios from "axios";
import {useAlertStore} from "@/Components/Alerts/stores/alertStore.js";
import {useAjax} from "@/js/Ajax/useAjax.js";
import {Filter} from "@/Filter/js/Filter";
import {createInstanceWithConstructor} from "./ClassTransformer/CustomTransformer.js"

export interface ISheetManager extends SheetManagerState {
    addSheet(instance: any): void;
    removeSheetByID(id: number): void;
    getSheetByID(id: number | null): ISheet | undefined;
    isExistWithID(id: number): boolean;
    updateSheet(sheet: ISheet): void;
    getLastSheetID(): number | null;
    load(): void;
}

export interface SheetManagerState {
    sheets: ISheet[],
    url: string,
}

export class SheetManager implements ISheetManager {
    public sheets: ISheet[];
    public url: string;
    public numRows: number | null;
    public rowsOnPage: number;
    public currentPage: number;
    static DATA_URL = '/admin/sheet/';
    public alerts: any;
    public ajax: any;

    constructor() {
        this.sheets = [];

        Object.assign(this, {
            sheets: [],
        });

        this.url = window.remoteUrl + SheetManager.DATA_URL;
        this.numRows = null;
        this.rowsOnPage = 10;
        this.currentPage = 1;
        this.alerts = window.pinia ? useAlertStore(window.pinia) : null;
        this.ajax = useAjax();
    }

    public addSheet(instance: any) {
        this.sheets = [...this.sheets, instance];
    }

    public removeSheetByID(id: number) {
        const index = this.sheets.findIndex((item: ISheet) => item.id === id);
        if (index !== -1) {
            this.sheets.splice(index, 1);
        }
    }

    public getSheetByID(id: number | null): ISheet {
        return this.sheets.filter((sheet: ISheet) => sheet.id === id)[0] ?? undefined;
    }

    public isExistWithID(id: number) {
        return this.sheets.filter((sheet: ISheet) => sheet.id === id).length > 0;
    }

    public updateSheet(sheet: ISheet) {
        const index = this.sheets.findIndex((item: ISheet) => item.id === sheet.id);
        if (index !== -1) {
            this.sheets[index] = sheet;
        }
    }

    public getLastSheetID(): number | null {
        return this.sheets.length ? this.sheets[this.sheets.length - 1].id : -1;
    }

    public async load(page = 1) {
        // debugger
        // let sheets = localStorage.getItem("sheets");
        let error = false;
        this.sheets = [];
        const {response} = await this.ajax.get(this.url + 'data-list', {
            params: {
                start: (page - 1) * this.rowsOnPage,
                count: this.rowsOnPage,
                sort: '-DateTime',
                withSettings: true, //TODO smazat
            }
        }).catch(function ({e}) {
            error = e;
        });

        if(error){
            if(this.alerts){
                this.alerts.error('Chyba při načtení seznamu listů', error.message ? error.message : '', error);
            }

            return false;
        }

        if (response.data.items) {
            this.numRows = !isNaN(Number.parseInt(response.data.numRows)) ? Number.parseInt(response.data.numRows) : null;
            let sheetStates: SheetState[] = response.data.items as SheetState[];

            if (sheetStates) {
                sheetStates.forEach((row: any) => {
                    row.Date = new Date(row.Date);
                    const settings = JSON.parse(row.SheetSettings); //TODO smazat
                    row.Name = settings.name; //TODO smazat

                    const newSheet = plainToInstance(Sheet, row);
                    this.addSheet(newSheet);
                });
            }
        }else{
            if(this.alerts) {
                this.alerts.error('Chyba při načtení seznamu listů', '', response);
            }
        }
    }

    public async loadByID(id: any) {
        // debugger
        // let sheets = localStorage.getItem("sheets");
        let error = false;
        const {response} = await this.ajax.get(this.url + 'data-list', {
            params: {
                count: 1,
                id: id,
                withSettings: true,
            }

        }).catch(function ({e}) {
            error = e;
        });

        if(error){
            return false;
        }

        if (response.data.items) {
            let sheetStates: SheetState[] = response.data.items as SheetState[];

            const row = sheetStates[0];
            row.Date = new Date(row.Date);
            row.settings = JSON.parse(row.SheetSettings);
            row.Name = row.settings.name;
            const newSheet = plainToInstance(Sheet, row, {excludeExtraneousValues: true});
            // const newSheet = createInstanceWithConstructor(Sheet, row);

            return newSheet;
        }

        return false;
    }

    public async createOnServer(sheet: ISheet){
        // debugger;
        // const sheetCopy = instanceToPlain(sheet)
        // // const sheetCopy = { ...sheet } as { [key: string]: any };
        // delete sheetCopy.id;
        // delete sheetCopy.data;
        // delete sheetCopy.type;

        sheet.settings.name = sheet.name;

        const data= {
            data: JSON.stringify({
                ...instanceToPlain(sheet),
                SheetSettings: JSON.stringify(instanceToPlain(sheet.settings)),
            })
        };

        let hasError = false;

        await this.ajax.postForm(this.url + 'data-create', data, null, {
            waitingAlert: {
                title: 'Ukládám list',
            }
        }).then(({response, alert}) => {
            if(!response.data.items){
                alert.changeToError('Chyba při ukládání listu', '', response);
                hasError = true;
                return;
            }

            const responseData = response.data.items;
            sheet.id = parseInt(responseData[0]?.ID);

            if(!sheet.id){
                if(this.alerts) {
                    alert.changeToError('Chyba při ukládání listu', '', response);
                }
                hasError = true;
                return;
            }

            alert.changeToSuccess('List úspěšně vytvořen', 'ID: ' + response.data.items[0]?.ID);
        }).catch(function ({error, alert}) {
            alert.changeToError('Chyba při ukládání listu', error.message ? error.message : '', error);
            hasError = true;
        });

        return !hasError;
    }

    public async updateOnServer(sheet: ISheet){
        // const sheetCopy = { ...sheet } as { [key: string]: any };
        // delete sheetCopy.id;
        // delete sheetCopy.data;
        // delete sheetCopy.type;

        // const data= {
        //     // data: JSON.stringify({
        //     //     ID: sheet.id,
        //     //     SheetSettings: JSON.stringify(sheetCopy),
        //     //     Type: sheet.type
        //     // })
        //     data: JSON.stringify(sheet)
        // };

        sheet.settings.name = sheet.name;
        const data= {
            data: JSON.stringify({
                ...instanceToPlain(sheet),
                SheetSettings: JSON.stringify(instanceToPlain(sheet.settings)),
            })
        };

        let hasError = false;

        await this.ajax.postForm(this.url + 'data-update', data, null, {
            waitingAlert: {
                title: 'Ukládám list',
            }
        }).then(({response, alert}) => {
            if(!response.data.items){
                alert.changeToError('Chyba při ukládání listu', '', response);
                hasError = true;
                return;
            }

            alert.changeToSuccess('List úspěšně aktualizován', 'ID: ' + sheet.id);
        }).catch(function ({error, alert}) {
            alert.changeToError('Chyba při ukládání listu', error.message ? error.message : '', error);
            hasError = true;
        });

        return !hasError;
    }

    public async deleteFromServer(sheet: ISheet){
        const data= {
            data: JSON.stringify({
                ID: sheet.id,
            })
        };

        let hasError = false;

        await this.ajax.postForm(this.url + 'data-delete', data, null, {
            waitingAlert: {
                title: 'Mazání listu',
            }
        }).then(({response, alert}) => {
            alert.changeToSuccess('List úspěšně smazán', 'ID: ' + sheet.id);
        }).catch(function ({error, alert}) {
            alert.changeToError('Chyba při smazání listu', error.message ? error.message : '', error);
            hasError = true;
        });

        return !hasError;
    }

    public async copySheetOnServer(sheet: ISheet){
        const data= {
            sheetID: sheet.id,
        };

        let hasError = false;

        await this.ajax.postForm(window.remoteUrl + '/default/handsontable/copy-sheet', data, null, {
           waitingAlert: {
               title: 'Kopírování listu',
           }
        }).then(({response, alert}) => {
            alert.changeToSuccess('List úspěšné zkopírován', 'ID: ' + sheet.id);
        }).catch(function ({error, alert}) {
            alert.changeToError('Chyba při kopírování listu', error.message ? error.message : '', error);
            hasError = true;
        });

        return !hasError;
    }
}
