<script setup>
import { computed } from 'vue';
import ContextMenuSubMenu from './ContextMenuSubMenu.vue';

const props = defineProps({
    item: {
        type: Object,
        required: true
    }
});

const hasSubMenu = computed(() => {
    return props.item.subItems && props.item.subItems.length > 0;
});

const handleClick = (event) => {
    if (typeof props.item.onClick === 'string') {
        eval(props.item.onClick);
    }else if(typeof props.item.onClick === 'function'){
        props.item.onClick();
    }
};
</script>

<template>
    <li @click="handleClick" v-if="item.visibility" :class="{submenu: hasSubMenu}">
        {{ item.label }}
        <ContextMenuSubMenu v-if="hasSubMenu" :items="item.subItems" />
    </li>
</template>

<style scoped>
.submenu{
    position: relative;
    &:after{
        position: absolute;
        content: "";
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 5px solid #000;
    }
}

li {
    padding: 3px;
    padding-left: 10px;
    cursor: pointer;
    border-radius: 0;
    font-size: 12px;
    position: relative;

    &:not(:first-of-type) {
        border-top: 1px solid rgba(178, 178, 178, 0.7);
    }

    &:hover {
        color: white;
        background: #1ab156;
    }

}

li:hover :deep(.dropdown) {
    display: block;
}
</style>
