<script setup>
    const props = defineProps({
        id: String,
        value: [String, Number, Object],
        modelValue: [String, Number, Object],
        name: String,
        label: String,
    });

    const emit = defineEmits(['update:modelValue']);
</script>

<template>
    <div class="radio-wrapper" @click="$refs.radioInput.click()">
        <input
            ref="radioInput"
            :id="id"
            type="radio"
            :value="value"
            :checked="modelValue === value"
            @change="$emit('update:modelValue', value)"
            :name="name"
        >
        <label :for="id" v-if="props.label">{{ label }}</label>
    </div>
</template>

<style scoped>
    input{
        width: 18px;
        height: 18px;
        min-width: 18px;
        min-height: 18px;
        color: #24C586;
        cursor: pointer;

        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        border: 1px solid #CDD8ED;
        border-radius: 50%;
        display: inline-block;
        position: relative;
        background-color: #fff;

        &:checked{
            border-color: #10B981;

            &:after{
                content: '';
                display: block;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: #10B981;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    label{
        font-size: 14px;
        font-weight: 500;
        margin-left: 8px;
        margin-bottom: 0;
        cursor: pointer;
    }

    .radio-wrapper{
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        cursor: pointer;
    }
</style>